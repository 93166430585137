import BaseModel from '../models/baseModel.js';
import CreateView from '../../EntityView/views/createView';
import CreateXmlView from '../../EntityType/views/xmlViews/createXmlView';
import CreateDocView from '../../EntityType/views/docViews/createDocView';
import CreateSpreadsheetView from '../../EntityType/views/spreadsheetViews/createSpreadsheetView';
import CreateField from '../../EntityField/views/createView';
import CreateFillFunctionView from '../../EntityType/views/createFillFunctionView';
import CreateBlock from '../../blocks/views/createView';
import utils from '../components/utils';
import ViewKind from '../enums/viewKind';




export function addEntityView (event, op) {
	// hide dropdown-menu
	if (!$(event.currentTarget).hasClass('inline-add-icon')) {
		$(event.currentTarget.parentElement.parentElement.parentElement).find('.dropdown-toggle').dropdown('toggle');
	}
	let viewName;
	const kind =$(event.currentTarget).data('kind');
	if(app.model && app.model.get('views').filter((view) => {
		return app.views.get(view).kind() == kind
	}).length == 0) {
		viewName = app.model.get('name').toJSON();
	} else {
		viewName = {translations:{}};
	}
	let model = new BaseModel({ name: viewName });
	let createView = CreateView;
	if (kind == ViewKind.SPREADSHEET) {
		model.set('spreadsheetProperties', {});
		createView = CreateSpreadsheetView;
	} else if (kind == ViewKind.XML) {
		model.set('xmlProperties', {});
		createView = CreateXmlView;
	} else if (kind == ViewKind.DOC) {
		model.set('docProperties', {});
		createView = CreateDocView;
	}
	let onSuccessFunc = op && op.afterCreateOnSuccess;
	let typeId = op && op.typeId;
	if (!typeId) {
		typeId = app.model.get('id');
	}
	if (!onSuccessFunc) {
		onSuccessFunc = (viewId) => {
			if (kind == ViewKind.SPREADSHEET || kind == ViewKind.XML) {
				utils.reload();
			} else {
				utils.redirectTo(app.urls.entityView + '/' + viewId);
			}
		};
	}
	model.set('viewKind', kind);
	app.createModal.show({
		url: app.urls.addView(typeId),
		headerResource: `add.${kind.toLowerCase()}.view`,
		model: model,
		afterCreateOnSuccess: onSuccessFunc,
		createView: createView
	});
}

export function addEntityBlock (event, op) {
  event.stopPropagation();
  // hide dropdown-menu
  if (!$(event.currentTarget).hasClass('inline-add-icon') && !$(event.currentTarget).hasClass('name')) {
    $(event.currentTarget.parentElement.parentElement.parentElement).find('.dropdown-toggle').dropdown('toggle');
  }
  let model = new BaseModel();
  let kind = $(event.currentTarget).data('kind');
  let trigger = $(event.currentTarget).data('trigger');
  let typeId = (op && op.typeId) || app.model.get('id');
  let initOptions = {};
  let createView = CreateBlock;
  let headerResource = `add.${kind.toLowerCase()}`;
  let url = app.urls.addBlock(typeId);
  let onSuccessFunc = op && op.afterCreateOnSuccess;
  if (!onSuccessFunc) {
    onSuccessFunc = (data) => { utils.redirectTo(app.urls.blockConstruct(data.id || data)); };
  }
  if (trigger) {
    initOptions.blockTrigger = trigger;
    if (trigger == 'CREATE_FROM') {
      createView = CreateFillFunctionView;
      headerResource = 'create.fill.function';
      url = app.urls.addFieldFunction(typeId);
    }
  }
  model.set('blockKind', kind);
  app.createModal.show({
    url: url,
    headerResource: headerResource,
    model: model,
    afterCreateOnSuccess: onSuccessFunc,
    createView: createView,
    initOptions: initOptions
  });
}


export function addEntityField (event, op) {
	$(event.currentTarget).parent().popover('hide')
	let that = this
	let model = new BaseModel({ name: { translations: {} } });
	let kind = $(event.currentTarget).data('kind');
  let typeId = op && op.typeId;
  if (!typeId) {
    typeId = app.model && app.model.get('id');
  }
  let headerResource = 'add.field';
  if (kind) {
    headerResource = `add.${kind.toLowerCase()}.field`;
  }
  let onSuccessFunc = op && op.afterCreateOnSuccess;
	model.set('fieldKind', kind);
	app.createModal.show({
		url: app.urls.addField(typeId),
    addShared: op && op.addShared,
		headerResource: headerResource,
		model: model,
		afterCreateOnSuccess: onSuccessFunc,
		createView: CreateField
	});
	$(event.currentTarget).parents('.dropdown').removeClass('open')
	event.stopPropagation()
}
