import utils from '../../common/components/utils.js';
import BaseForm from '../../common/views/baseForm.js';
import MultilingualString from '../../common/models/multilingualString.js';
import CreateNamedEntityView from '../../common/views/createNamedEntityView';
import BaseUpdateView from '../../common/views/baseUpdateView';
import Constants from '../../common/models/constants'
import {initializeSortable} from './reorderInit'

app.modules = new Backbone.Collection(app.raw && app.raw.modules)

var ModuleView = BaseForm.extend({

	el: '.modulesPanel',

	events: {
		'click .module': 'select',
		'click #addModule': 'add',
		'click .editModule': 'edit',
		'click .removeModule': 'remove'
	},

	initialize: function () {
		ModuleView.__super__.initialize.apply(this, arguments);

		var that = this;
		$('#removeSelectedModules').confirm({
			confirm: that.remove
		});

		let orderedModules = _.sortBy(app.raw.modules, 'relativeOrder')
		_.each(orderedModules, module => this.addModule(module));

		initializeSortable($('#modulePanelHead'), $('#modulesBox'), 'modules', app.modules, 'data-moduleid', 'module', 'moduleTooltip')

	},

	select: function (event) {
		if ( $('#modulesBox').hasClass('ui-sortable-disabled')
					&& ($(event.target).hasClass('module') || $(event.target).hasClass('moduleName'))) {
			$(event.currentTarget).toggleClass('active');
		}
	},

	add: function () {
		addNewModule((module) => {
			this.addModule(module);
			app.modules.add(new Backbone.Model(module));
		})
	},

	addModule: function (module, notCategorizedPosition) {
		$('.module-box-body').append(`<div class="module ui-sortable-handle" data-moduleid="${module.id}">
				<span class="name moduleName">
					${MultilingualString.getCurrentValue(module.name)}
				</span>
				<span class="pull-right moduleTooltip">
					${app.formatters.moduleTooltip(module)}
				</span>
			</div>`);
		$(`.module[data-moduleid=${module.id}]`).find('.editModule').on('click', e => this.edit(e));
		$(`.module[data-moduleid=${module.id}]`).find('.removeModule').on('click', e => this.remove(e));
	},

	remove: function (event) {
		let id = $(event.currentTarget).data('id');
		this.removedId = id;
		utils.confirmDelete([id], app.urls.dependencies(Constants.ID_TYPE_MODULE), app.urls.delete(Constants.ID_TYPE_MODULE),
			{}, () => { this.removeModuleFromPanel(); });
	},

	edit: function (event) {
		let id = $(event.currentTarget).data('id');
		let model = app.modules.get(id).clone();
		model.set('name', new MultilingualString(model.get('name')));
		app.editModal.show({
			url: `${app.urls.home}module/update`,
			headerResource: `edit.module`,
			model: model,
			view: BaseUpdateView,
			afterUpdateOnSuccess: (module) => { this.updateModule(module); }
		});
	},

	updateModule: function (module) {
		this.$el.find(`.module[data-moduleid=${module.id}]`).find('span.name')
			.text(MultilingualString.getCurrentValue(module.name));
		let model = app.modules.get(module.id);
		for (let key of Object.keys(module)) {
			model.set(key, module[key]);
		}
	},

	removeModuleFromPanel: function () {
		this.$el.find(`.module[data-moduleid=${this.removedId}]`).remove();
	}
});

export function addNewModule(onSuccess) {
	let model = new Backbone.Model();
	model.set('name', new MultilingualString({}));
	model.set('relativeOrder', app.raw.modules.length)
	app.createModal3.show({
		url: `${app.urls.home}module/create`,
		headerResource: `add.module`,
		model: model,
		afterCreateOnSuccess: onSuccess,
		createView: CreateNamedEntityView
	});
}

export default ModuleView;
