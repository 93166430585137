/* @flow */
import  ViewKind from '../../common/enums/viewKind';
import  ViewModifier from '../../common/enums/viewModifier';
import type Block from '../../common/models/block'

export default class View extends Backbone.Model {
	kind(): string {
		return this.get('viewKind');
	}

	owner(): string {
		return this.get('ownerId');
	}

	name(): any {
		return this.get('name');
	}

	parent(): View {
		return app.views.get(this.get('parentViewId'));
	}

	isSpreadsheetView(): boolean {
		return this.kind() == ViewKind.SPREADSHEET;
	}

	isDocView(): boolean {
		return this.kind() == ViewKind.DOC;
	}

	isXmlView(): boolean {
		return this.kind() == ViewKind.XML;
	}

	isFormView(): boolean {
		return this.kind() == ViewKind.FORM;
	}

	isStringView(): boolean {
		return this.kind() == ViewKind.STRING;
	}

	isRowView(): boolean {
		return this.kind() == ViewKind.ROW;
	}

	isPrimitiveView(): boolean {
		return this.kind() == ViewKind.PRIMITIVE;
	}

	isClientView(): boolean {
		return this.kind() == ViewKind.CLIENT
	}

	isWidgetView(): boolean {
		return this.kind() == ViewKind.WIDGET
	}

	viewModifier(): string {
		return this.get('viewModifier');
	}

	isDefault(): boolean {
		return this.viewModifier() == ViewModifier.DEFAULT;
	}

	isGenerated(): boolean {
		return this.get('isGenerated');
	}

	hasBlock(blockTrigger: string): boolean {
		return !!this.get('viewEvents')[blockTrigger];
	}

	getBlock(blockTrigger: string): Block {
		return app.blocks.get(this.get('viewEvents')[blockTrigger]);
	}

	primitiveFormat() {
		return this.get('primitiveFormat');
	}
}
