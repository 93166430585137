import utils, {showInstance} from '../../common/components/utils.js';
import MultilingualString from '../../common/models/multilingualString.js';
import {translate} from '../../common/service/stringResourceService'
import {buildHelpPopover} from '../../help/docsResource'
import States from '../../common/enums/states';
var Toolbar = Backbone.View.extend({
	initialize: function (o) {
		this.context = o.context;
		this.$('.link-back').click(this.context.onClose);
		if (!this.$el.find('.hide-fill-functions').length){
			this.drawFillFunctions()
			$(this.context.el).find('.fill-function').click((e) => {
				let link = $(e.target)
				while (link.prop('tagName').toLowerCase() !== 'button') {
					link = link.parent();
				}
				let action = this.context.updating ? this.context.wizardView.save(false, true) : Promise.resolve();
				action.then((data) => {
					showInstance({
						fillFunctionId: link.attr('fillfunctionid'),
						fillInstanceId: this.context.updating ? data.id : this.context.objectId,
						typeId: link.attr('fillresulttypeid'),
						openMode: 'new.cj.tab',
						callback: () => {}
					})
				})

			})
		}

		const restoreFromHistory = $(`<span>
			<button class="toolbar-btn btn btn-danger restore" style="display:none">${translate('restore')}</button>
		</span>`);

		this.$('.inline-column').last().prepend(restoreFromHistory);
		if (!this.context.type.isReport()) {
			this.$('.reportForm').remove()
			this.$('.customizeReportForm').remove()
		}

		this.toggleButtons()
	},

	//WARNING :: fill functions are not content-independent yet
	drawFillFunctions: function() {
		if (this.context.objectId && this.context.fillFunctions && this.context.fillFunctions.length !== 0) {
			let fillFunctionDom = this.$(".dropdown.fill-functions")
			if (fillFunctionDom.length == 0) {
				fillFunctionDom = $(`<div class="dropdown fill-functions body-container put-down">
					<button class="btn btn-default btn-xs dropdown-toggle"
						type="button" data-toggle="dropdown">
						<span class="glyphicon glyphicon-plus"></span>
					</button>
					<ul class="dropdown-menu"></ul>
				</div>`)
				this.$('.inline-column').first().append(fillFunctionDom)
				_.each(this.context.fillFunctions, (fillFunction) => {
					if (fillFunction.owner.id == app.typeId) {
						const $li = $('<li>').append(
							$('<button class="btn-link ref fill-function">').attr('fillfunctionid', fillFunction.id)
								.attr('fillresulttypeid', fillFunction.fillResultType.id)
								.attr('target','_blank')
								.html(`<span>${fillFunction.name}</span>`)
							);
						fillFunctionDom.find('.dropdown-menu').append($li);
					}
				})
			}
		}
	},
	toggleButtons: function() {
		if (this.context.canViewHistory != null && !this.context.canViewHistory) {
			this.$('.show-history').hide();
		}else{
			this.$('.show-history').show();
		}
		if (!this.context.type.isDocument() || !this.context.objectId) {
			this.$('.apply, .relieve, .show-registers').hide();
		}else{
			this.$('.apply, .relieve, .show-registers').show();
		}
		if (!this.context.objectId) {
			this.$('.delete-entity').hide();
		}else{
			this.$('.delete-entity').show();
		}
		if (!this.context.model.id) {
			this.$('.btn-link.dropdown-toggle').hide();
		}else{
			this.$('.btn-link.dropdown-toggle').show();
		}
		if (!(this.context.canUpdate == null || this.context.canUpdate) && this.context.isSystem) {
				var elem = $('<a help-popover="system-instance-readonly"><i class="fa fa-lock"></i></a>');
				this.$('.column.inline-column.inline-column-right').append(elem);
				buildHelpPopover(elem.get(0));
		}
		if(this.context.model.get('metaObjectState') === States.DELETED) {
				var elem = $(`<span class="label table-label label-default toolbar-system-label">${app.getResource('deleted')}</span>`);
				this.$('.column.inline-column:not(.inline-column-right)').append(elem);
		}
	}
});

export default Toolbar;
