import MetaObject from '../../common/models/metaObject';
import MultilingualString from '../../common/models/multilingualString';
import TypeKind from '../../common/enums/typeKind';
import Constants from '../models/constants';
import utils from '../../common/components/utils.js';
import FieldSelect from './fieldSelect.js';
import Entities from '../collections/entities';

var FieldDynamic = FieldSelect.extend({

	events: {
		'select2:select': 'select',
		'select2:unselecting': 'unselecting',
		'select2:close': 'close'
	},

	initialize: function (options) {
		options.allowClear = true
		FieldDynamic.__super__.initialize.apply(this, arguments);
		this.typeId = null;
		this.types = app.types.filter(type => {
			return TypeKind.isWithMetaObject(type.kind()) && !type.get('isSystem')
			}).sort((t1, t2) => {
				let mls1 = MultilingualString.fromJSON(t1.get('name')).getCurrentValue() || ''
				let mls2 = MultilingualString.fromJSON(t2.get('name')).getCurrentValue() || ''
				return mls1.localeCompare(mls2)
			});
	},

	async getTypesForSelect() {
		let types = this.types;
		const key = (this.parentField && this.parentField + '.' + this.modelAttr) || this.modelAttr;
		let filters = await (app.userObservers && this.context &&
			app.userObservers.getTypeFilters(this.context.type.id + ',' + key).call(this.context, this.context.model, this.model));
		if (filters) {
			filters = Entities.fromJSON(filters, Constants.ID_TYPE_FIELD_FILTER).toJSON();
			types = utils.filterCollection(types, filters);
		}
		const selectData = types.map(this._typeViewModel);
		return selectData;
	},

	_typeViewModel(type) {
		return {
			text: MultilingualString.getCurrentValue(type.name()),
			currentName: MultilingualString.getCurrentValue(type.name()),
			id: type.id
		};
	},

	loadOptions: function (params, callback) {
		if (this.typeId !== null) {
			FieldDynamic.__super__.loadOptions.apply(this, arguments);
		} else {
			this.getTypesForSelect().then(types => {
				const res = {};
				res.results = params.term ? types.filter((f) => {
					return f.currentName.toLowerCase().indexOf(params.term.toLowerCase()) !== -1;
				}) : types;
				res.pagination = {};
				res.pagination.more = false;
				callback(res);
			});
		}
	},

	close: function () {
		if (this.typeId !== null && this.$el.val() === this.typeId) {
			this.setValue(null);
		}
	},

	onOpen: function () {
		if (this.typeId !== null) {
			FieldDynamic.__super__.onOpen.apply(this, arguments);
		} else {
			$('.select2-results .create-new').remove();
		}
	},

	select: function () {
		if (this.typeId === null) {
			this.typeId = this.$el.val();
			this.setDataWithTypeOnlyToModel();
			this.$el.select2('open');
		}
	},

	unselecting: function () {
		this.setValue(null);
	},

	updateTextEl: function (val, state) {
		if (this.readOnlyText) {
			if (state && !state.id) {
				this.$textEl.html('-');
			} else if (this.hasViewLink && state){
				let updateEntityUrl = app.urls.update(this.typeId, state.id);
				this.$textEl.html(`<a href="${updateEntityUrl}" target="_blank">${val}</a>`);
			} else {
				this.$textEl.html(`<span>${val}</span>`)
			}
		}
	},
	templateSelection: function (state) {
		if (this.typeId !== null) {
			const $span = $(`<span style="opacity: 0.7; margin-right: 5px; display: inline-block; overflow: hidden; text-overflow: ellipsis;">`);
			const type = app.types.get(this.typeId);
			const typeViewModel = this._typeViewModel(type);
			if (this.typeId !== state.id) {
				const instanceHtml = FieldDynamic.__super__.templateSelection.apply(this, arguments);
				state.title = typeViewModel.currentName;
				function templateSpan (el) {
					const $sp = $(`<span style="display: inline-block; overflow: hidden; text-overflow: ellipsis;" />`);
					$sp.append(el);
					return $sp;
				}
				$span.append(templateSpan(typeViewModel.text));
				$span.append(' ');
				$span.append(templateSpan(instanceHtml));
			} else {
				$span.html(typeViewModel.text);
			}
			return $span;
		} else {
			this.updateTextEl(state.text, state)
			return state.text;
		}
	},

	changed: function () {
		if (this.typeId !== null) {
			FieldDynamic.__super__.changed.apply(this, arguments);
		}
	},

	setValue: function (value) {
		if (value && value.id) {
			this.typeId = value.get ? value.get('objectType').id : value.objectType.id;
			FieldDynamic.__super__.setValue.apply(this, arguments);
		} else {
			this.typeId = null;
			this.$el.val(null).trigger('change')
			FieldDynamic.__super__.changed.apply(this, []);
		}
	},

	getValue: function () {
		if (this.typeId !== null && this.$el.val())  {
			return {
				id: this.$el.val(),
				objectType: {
					id: this.typeId
				}
			};
		} else {
			return null;
		}
	},

	setDataWithTypeOnlyToModel() {
		this.model.set(this.modelAttr, MetaObject.of(null, this.typeId), {silent: true});
	},

	setDataToModel: function (data) {
		if (this.model) {
			var newValue = data ? data.id : null;
			var oldValue = this.getDataFromModel() ? this.getDataFromModel().id : null;

			if (newValue != oldValue || data == null) {
				this.model.set(this.modelAttr, data ? MetaObject.of(data.id, data.objectType.id) : null);
			}
			if (newValue != oldValue){
				this.model.trigger('manualChange:' +  this.modelAttr, this.model);
			}
		}
	}
});

export default FieldDynamic;
