/* @flow */

import BlockKind from '../../common/enums/blockKind';
import BlockType from '../../common/enums/blockType';
import BlockTrigger from '../../common/enums/blockTrigger';
import TypeKind from '../../common/enums/typeKind';
import MultilingualString from '../../common/models/multilingualString';
import BaseCreateView from '../../common/views/baseCreateView';

var CreateView = BaseCreateView.extend({

	initialize: function (options) {
		CreateView.__super__.initialize.apply(this, arguments);
		this.rebuildSelects(options.initOptions);

		if (this.model.get('blockKind') == BlockKind.ACTION) {
			if (_.isEmpty(options.initOptions)) {
				this.hide('blockNameDiv');
				this.hide('blockTypeDiv');
			}
			else if (options.initOptions.blockTrigger && options.initOptions.blockType){
				this.hide('blockTriggerDiv');
				this.hide('blockTypeDiv');
			}
		} else {
			this.hide('blockTriggerDiv');
		}

		this.listenTo(this.model, 'change:trigger', this.onTriggerChange);
	},

	rebuildSelects: function (initOptions) {
		let triggers = this.getAvailableTriggers().map(app.enumToResourceForSelect);
		triggers.forEach((t) => { return t.text = t.html })
		this.selects.trigger.rebuildSelect(triggers,
			initOptions && initOptions.blockTrigger || (triggers.length && triggers[0].id));
		let types = this.getAvailableTypes(
			initOptions && initOptions.blockType).map(app.enumToResourceForSelect);
		types.forEach((t) => { return t.text = t.html })
		this.selects.blockType.rebuildSelect(types, types.length && types[0].id);
	},

	getAvailableTriggers: function () {
		let result = [BlockTrigger.SAVE, BlockTrigger.LOAD,
			BlockTrigger.CUSTOM, BlockTrigger.VALIDATE, BlockTrigger.LOADED,
			BlockTrigger.TREE_CHANGE, BlockTrigger.AFTER_IMPORT, BlockTrigger.DELETE, BlockTrigger.TEST];
		let typeKind = this._getTypeKind();
		if (typeKind === TypeKind.DOCUMENT) {
			result.unshift(BlockTrigger.DOCUMENT_POST);
		}
		if (typeKind == TypeKind.REPORT) {
			result.unshift(BlockTrigger.GET_DATA_LEAVES, BlockTrigger.BUILD);
		}
		return result;
	},

	_getTypeKind: function() {
		let typeKind = null;
		if (app.model) {
			typeKind = app.model.get('typeKind');
		} else if (app.owner) {
			typeKind = app.owner.get('typeKind')
		}
		return typeKind;
	},

	getAvailableTypes: function (blockType) {
		if (blockType) {
			return [blockType];
		} else {
			return [BlockType.SERVER, BlockType.CLIENT];
		}
	},

	saveConfiguration: function () {
		let data = this.model.toJSON();

		if (data.blockKind == BlockKind.ACTION) {
			if (data.trigger == BlockTrigger.LOADED) {
				data.blockType = BlockType.CLIENT;
			} else if (!this.isTypeEditable(data.trigger)) {
				data.blockType = BlockType.SERVER;
			}
			if (!this.isNameEditable(data.trigger)) {
				data.name = data.trigger.toLowerCase();
			}
			if (data.trigger == BlockTrigger.DOCUMENT_POST) {
				data.name = 'post';
			}
		} else if (data.blockKind == BlockKind.FUNCTION) {
			data.trigger = null;
		}
		return data;
	},

	isNameEditable (blockTrigger) {
		return blockTrigger == BlockTrigger.CUSTOM || blockTrigger == BlockTrigger.TEST;
	},

	isTypeEditable (blockTrigger) {
		return blockTrigger == BlockTrigger.CUSTOM || blockTrigger == BlockTrigger.TEST;
	},

	onTriggerChange () {
		const trigger = this.model.get('trigger');
		if (this.isNameEditable(trigger)) {
			this.show('blockNameDiv');
		} else {
			this.hide('blockNameDiv');
		}
		if (this.isTypeEditable(trigger)) {
			this.show('blockTypeDiv');
		} else {
			this.hide('blockTypeDiv');
		}
	},

	hide: function (id) {
		this.$el.find(`#${id}`).hide();
	},

	show: function (id) {
		this.$el.find(`#${id}`).show();
	},

	getHTML: function () {
		return `<div id="blockNameDiv">
							<div class="grid-1x-padding">
								<label for="name">${app.getResource('name')}</label>
							</div>
							<div class="grid-2x-padding">
								<input class="form-control" type="text" data-field="name"/>
							</div>
						</div>
						<div id="blockTriggerDiv">
							<div class="grid-1x-padding">
								<label for="trigger">${app.getResource('trigger')}</label>
							</div>
							<div class="grid-2x-padding">
								<select id="trigger" data-field="trigger"></select>
							</div>
						</div>
						<div id="blockTypeDiv">
							<div class="grid-1x-padding">
								<label for="blockType">${app.getResource('type')}</label>
							</div>
							<div class="grid-2x-padding">
								<select id="blockType" data-field="blockType"></select>
							</div>
						</div>`;
	}
});

export default CreateView;
