import { urlByDocsResource, articleByDocsResource, userPilotByDocsResource } from '../help/docsResource'
export { initHelpPopovers } from './docsResource'
import CheckboxInput from '../common/components/checkboxInput'
import stateRecovery from '../common/components/stateRecovery'
import {translate} from '../common/service/stringResourceService'
import LocalStorageKeysService from '../common/service/localStorageKeysService'

export function addHrefToDocs() {
  document.querySelectorAll('[docs-resource]').forEach(function(elem) {
    elem.setAttribute('href', urlByDocsResource(elem.getAttribute('docs-resource')))
  })
}

function showDocsModalIcon() {
  $('.modal-docs-icon').css('visibility','visible')
}

function hideQuickTipIcon() {
  $('.modal-docs-icon').css('visibility','hidden')
}

function loadDocsModal(attr, headerResource) {
  return articleByDocsResource(attr).then((article)=>{
    document.body.insertAdjacentHTML('beforeend',
    `<div id="help-modal" class="modal fade" tabindex="-1" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
        <div style="background-color:#3c8dbc;color:white;height:45px">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="padding:10px">
        <span aria-hidden="true" style="color:white">×</span>
        </button>
        <h4 class="box-title" style="padding:12px">
          ${!headerResource ? translate('quick.tips') : translate(headerResource)}
        </h4>
        </div>
          <div class="modal-body" style="max-height:70vh; padding:35px; padding-top:15px; padding-bottom:15px; overflow-y:auto">
            ${article.content}
          </div>
        <div class="modal-footer">
        <div class="pull-left" style="text-align:left;${headerResource ? 'display:none':''}">
          <div class="checkbox">
            <label>
              <input type="checkbox" id="donotshowthis">
              <span>Don't show this again</span>
            </label>
          </div>
          <div class="checkbox">
            <label>
              <input type="checkbox" id="donotshowall">
              <span>Don't show any tips</span>
            </label>
          </div>
        </div>
        <div class="clearfix"></div>
        <button type="button" class="btn btn-primary" data-dismiss="modal" aria-label="Close">
        Close
        </button>
        </div>
        </div>
      </div>
    </div>`)
    $("#help-modal").modal('hide').css('display','none')
    var model = new Backbone.Model({
      showThis: stateRecovery.get(LocalStorageKeysService.buildKeyForNotShow(attr)),
      showAll: stateRecovery.get(LocalStorageKeysService.buildKeyForNotShow('Tips'))
    })
    new CheckboxInput({
          el: $('#donotshowthis')[0],
          model: model,
          modelAttr: 'showThis'
        })
    new CheckboxInput({
          el: $('#donotshowall')[0],
          model: model,
          modelAttr: 'showAll'
        })
    model.on('change:showThis', (a,b)=>{
      stateRecovery.put(LocalStorageKeysService.buildKeyForNotShow(attr), a.get('showThis'))
    })
    model.on('change:showAll', (a,b)=>{
      stateRecovery.put(LocalStorageKeysService.buildKeyForNotShow('Tips'), a.get('showAll'))
    })
    const quickTip =
    $('.modal-docs-icon').on('click', () => {
      $('#help-modal').addClass('to-right-top-corner')
      showDocsModal(attr)
      hideQuickTipIcon()
    })
  }
  )
}

function showDocsModal(attr) {
  $("#help-modal").modal('show').css('display','block')
  $("#help-modal").one('hidden.bs.modal', () => {
    showDocsModalIcon()
    $("#help-modal").html($("#help-modal").html())
  })
}

export function addUserPilots() {
  document.querySelectorAll('[modal-docs-resource]').forEach(function(elem) {
    var attr = elem.getAttribute('modal-docs-resource')
    const headerResource = elem.getAttribute('modal-docs-header-resource')
    const userPilot = userPilotByDocsResource(attr)
    if (!userPilot){
      const headerResource = elem.getAttribute('modal-docs-header-resource')
      doLoadDocsModal(attr, headerResource)
    } else {
      $('.modal-docs-icon').on('click', () => {
        userpilot.trigger(userPilot)
      })
    }
    showDocsModalIcon()
  })
}

function doLoadDocsModal(attr, headerResource){
  loadDocsModal(attr, headerResource).then(()=>{
    if (!(stateRecovery.get(LocalStorageKeysService.buildKeyForNotShow(attr)) || stateRecovery.get(LocalStorageKeysService.buildKeyForNotShow('Tips')))){
      $("#help-modal").one('hidden.bs.modal', () => {
        stateRecovery.put(LocalStorageKeysService.buildKeyForNotShow(attr), true)
      })
      $("#help-modal").one('shown.bs.modal', () => {
        $('#help-modal').addClass('to-right-top-corner')
      })
      showDocsModal(attr)
    }else{
      showDocsModalIcon()
    }
  })
}

export function addDocsModal() {
  document.querySelectorAll('[modal-docs-resource]').forEach(function(elem) {
    var attr = elem.getAttribute('modal-docs-resource')
    const headerResource = elem.getAttribute('modal-docs-header-resource')
    doLoadDocsModal(attr, headerResource)
  })
}
