/* @flow */

import utils from '../../common/components/utils.js';
import FieldInput from '../../common/components/fieldInput.js';
import ChronoUnit from '../../time/enums/chronoUnit';
import Duration from '../../time/models/duration';
import Period from '../../time/models/period';
import ZoneOffset from '../../time/models/zoneOffset';
import type {
	TemporalAmount
} from '../../time/declarations';
import PrimitiveEntityType from '../../common/enums/primitiveEntityType';
import DateTimeAmountFormatter from '../../time/formatters/dateTimeAmountFormatter';
import Formatter from '../../common/components/formatter';
import BaseControl from '../../common/components/baseControl';
import * as time from '../../time/models/index';

var FieldTemporalAmountInput = BaseControl.extend({

	initialize: function (options) {

		FieldTemporalAmountInput.__super__.initialize.apply(this, arguments);

		const primitiveType = utils.getPrimitiveType(this.$el);
		const formatterId = this.$el.attr('data-formatter-id');
		const typeId = this.$el.attr('data-entity-type-id');

		this.editingFormat = Formatter.getEditingFormat({
			formatterId: formatterId,
			typeId: typeId,
			primitiveType: primitiveType
		});

		this.readOnlyText = this.$el.attr('data-read-only-text') == 'true';
		this.$textEl = this.$el.closest('.form-group').find('.read-only-text');
		this.context = options.context;
		this.disabled = this.$el.closest('.form-group').hasClass('disable-all');

		this.initializeInputs(primitiveType);
	},

	initializeInputs: function(primitiveType) {
		const format = this.editingFormat;
		const zeroDuration = Duration.ofSeconds(0);
		const zeroPeriod = Period.of(0, 0, 0);
		const formatter = (format, amount) => {
			return DateTimeAmountFormatter.ofPattern(format, app.currentLanguage)
				.format(amount).replace('0', '').replace(' ', '');
		}
		this.inputs = [];

		switch(primitiveType) {
			case PrimitiveEntityType.DURATION:
			case PrimitiveEntityType.ZONE_OFFSET:
			case PrimitiveEntityType.PERIOD:
				if (format.includes('y')) {
					this.initializeInput(ChronoUnit.YEARS,
						formatter('y', zeroPeriod), formatter('yy', zeroPeriod));
				}
				if (format.includes('M')) {
					this.initializeInput(ChronoUnit.MONTHS,
						formatter('M', zeroPeriod), formatter('MM', zeroPeriod));
				}
				if (format.includes('d')) {
					this.initializeInput(ChronoUnit.DAYS,
						formatter('d', zeroPeriod), formatter('dd', zeroPeriod));
				}
				if (format.includes('H') || format.includes('h')) {
					this.initializeInput(ChronoUnit.HOURS,
						formatter('H', zeroDuration), formatter('HH', zeroDuration));
				}
				if(format.includes('m') || format.includes('i')) {
					this.initializeInput(ChronoUnit.MINUTES,
						formatter('m', zeroDuration), formatter('mm', zeroDuration));
				}
				if(format.includes('s') || format.includes('S')) {
					this.initializeInput(ChronoUnit.SECONDS,
						formatter('s', zeroDuration), formatter('ss', zeroDuration));
				}
				break;
			default:
				throw new Error('Wrong primitive entitytype!');
		}
		_.each(this.inputs, (input, i) => {
			if(i === 0) {
				input.$el.addClass('no-border');
			} else {
				input.$el.addClass('border-gradient');
			}
			input.$el.parent().width((100 / Object.keys(this.inputs).length) + '%');
			$(window).resize(() => {
				if (input.$el.width() > 120) {
					input.$el.parent().find('.placeholder-short').hide();
					input.$el.parent().find('.placeholder-long').show();
				} else {
					input.$el.parent().find('.placeholder-short').show();
					input.$el.parent().find('.placeholder-long').hide();
				}
				this.hasValue(input.$el)
			}).resize();
			this.hasFocus = false;
			input.$el.on('change', (e) => {
				this.hasValue($(e.target));
			}).on('focus',(e) => {
				this.hasFocus = true;
			}).on('input',(e) => {
				this.hasValue($(e.target));
			}).on('focus', (e) => {
				$(e.target).parent().parent().addClass('focused');
			}).on('blur', (e) => {
				$(e.target).parent().parent().removeClass('focused');
			});
		});
	},

	hasValue($el) {
		if($el.val()){
			if($el.width() < 90) {
				$el.parent().find('.placeholder-short').hide();
				$el.removeClass('has-value');
			} else {
				$el.addClass('has-value');
			}
		} else {
			if($el.width() < 90) {
				$el.parent().find('.placeholder-short').show();
			}
			$el.removeClass('has-value');
		}
	},

	initializeInput: function (unit, shortText, longText) {
		const $div = $('<div class="input-part"></div>');
		const $input = $(`<input class="form-control part" title="${longText}" type="text" maxlength="8"/>`);
		$div.append($input);
		$div.append(`<span class="placeholder-long">${longText}</span>`);
		$div.append(`<span class="placeholder-short">${shortText}</span>`);
		this.$el.append($div);
		this.inputs.push({key: unit, $el: $input});
	},

	setValue: function(value: ? TemporalAmount | time.ZoneOffset) {

		if (!value) {
			_.each(this.inputs, (input, i) => {
				input.$el.val(null).trigger('change');
			});
			this.$textEl.html('-');
			return;
		}

		const primitiveType = utils.getPrimitiveType(this.$el);
		const formatterId = this.$el.attr('data-formatter-id');

		const typeId = this.$el.attr('data-entity-type-id');
		switch (primitiveType) {
			case PrimitiveEntityType.ZONE_OFFSET:
			case PrimitiveEntityType.DURATION:
			case PrimitiveEntityType.PERIOD:
				const text =  Formatter.formatToHTML(value, {
					formatterId: formatterId,
					typeId: typeId,
					primitiveType: primitiveType
				});
				if (value instanceof time.ZoneOffset) {
					value = time.Duration.ofSeconds(value.getTotalSeconds());
				}
				this.$textEl.html(text || '-');
				_.each(this.inputs, (input, i) => {
					input.$el.val(DateTimeAmountFormatter.getChronoUnit(value, input.key, i !== 0));
					this.hasValue(input.$el);
				});
				break;
			default:
				throw new Error('Wrong primitive entitytype!');
		}
	},

	getValue: function(): ? TemporalAmount | time.ZoneOffset {

		const primitiveType: string = utils.getPrimitiveType(this.$el);

		switch (primitiveType) {
			case PrimitiveEntityType.ZONE_OFFSET:
				var seconds = null;
				_.each(this.inputs, (input, i) => {
					if(input.$el.val() !== null && input.$el.val() !== '') {
						seconds = seconds || 0;
						const value = parseInt(input.$el.val());
						switch(input.key) {
							case ChronoUnit.HOURS:
								seconds += 3600 * value;
								break;
							case ChronoUnit.MINUTES:
								seconds += 60 * value;
								break;
							case ChronoUnit.SECONDS:
								seconds += value;
								break;
							default:
								throw new Error('Wrong chrono unit!');
						}
					}
				});
				return ZoneOffset.ofTotalSeconds(seconds);
			case PrimitiveEntityType.DURATION:
				var duration = null;
				_.each(this.inputs, (input, i) => {
					if(input.$el.val() !== null && input.$el.val() !== '') {
						duration = duration || Duration.ofSeconds(0);
						const value = parseInt(input.$el.val());
						switch(input.key) {
							case ChronoUnit.DAYS:
								duration = duration.plusDays(value);
								break;
							case ChronoUnit.HOURS:
								duration = duration.plusHours(value);
								break;
							case ChronoUnit.MINUTES:
								duration = duration.plusMinutes(value);
								break;
							case ChronoUnit.SECONDS:
								duration = duration.plusSeconds(value);
								break;
							default:
								throw new Error('Wrong chrono unit!');
						}
					}
				});
				return duration;
			case PrimitiveEntityType.PERIOD:
				var period = null;
				_.each(this.inputs, (input, i) => {
					if(input.$el.val() !== null && input.$el.val() !== '') {
						period = period || Period.of(0, 0, 0);
						const value = parseInt(input.$el.val());
						switch(input.key) {
							case ChronoUnit.YEARS:
								period = period.plusYears(value);
								break;
							case ChronoUnit.MONTHS:
								period = period.plusMonths(value);
								break;
							case ChronoUnit.DAYS:
								period = period.plusDays(value);
								break;
							default:
								throw new Error('Wrong chrono unit!');
						}
					}
				});
				return period;
			default:
				throw new Error('Wrong primitive entitytype!');
		}
	},

	getDataFromModel: function () {
		return this.model.get(this.modelAttr);
	},

	setDataToModel: function (obj : ? TemporalAmount | time.ZoneOffset) {
		if (this.model &&
				!_.isEqual(this.model.get(this.modelAttr) && this.model.get(this.modelAttr).toJSON(),
					obj && obj.toJSON())) {
			this.model.set(this.modelAttr, obj);
			this.model.trigger('manualChange:' +  this.modelAttr, this.model);
		}
	},

	isDisabledInFormBuilder: function () {
		return this.disabled
	},

	enable: function () {
		this.$el.find('input').removeAttr('disabled');
		if (this.readOnlyText && !this.isDisabledInFormBuilder()) {
			this.$textEl.addClass('hidden');
			this.$el.removeClass('hidden');
		}
	},

	disable: function () {
		this.$el.find('input').attr('disabled', 'disabled');
		if (this.readOnlyText) {
			this.$textEl.removeClass('hidden');
			this.$el.addClass('hidden');
		}
	},
	changed: function () {
		this.hasFocus = false;
		setTimeout(() => {
			if(!this.hasFocus) {
				this.setDataToModel(this.getValue());
			}
		});
	},
});

export default FieldTemporalAmountInput;
