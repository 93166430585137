/* @flow */

import utils from '../../common/components/utils';
import BaseModel from '../../common/models/baseModel';
import MultilingualString from '../../common/models/multilingualString';
import AppUpdateMode from '../../common/enums/appUpdateMode';
import SystemInstancesMergeModal from '../../deploy/views/systemInstancesMergeModal';
import { translate } from '../../common/service/stringResourceService.js';
import RadioGroupInputs from '../../common/components/radioGroupInputs';
import { buildHelpPopover } from '../../help/docsResource'
import CheckboxInput from '../../common/components/checkboxInput';
import Formatter from '../../common/components/formatter';
import PrimitiveEntityType from '../../common/enums/primitiveEntityType'
import Instant from '../../time/models/instant';

export default class DeploymentOptionsModal extends Backbone.View {

	constructor() {
			super({
					events: {
							// 'click .custom-mode-opts-link': '_onCustomMergeModeOpts',
							// 'click #loadMoreVersionsButton': '_onUpdateVersions'
					}
			});
	}

	initialize () {
		let $el = $('#deploy-opts-modal');
		if ($el.length == 0) {
			var el = document.createElement('div')
			el.id = 'deploy-opts-modal'
			el.classList.add('modal')
			el.classList.add('fade')
			document.body.appendChild(el)
		}
		$el = $('#deploy-opts-modal')
		$el.html(this.getHTML())
		this.setElement($el);
		this.$el.find('.custom-mode-opts-link').off().click((e)=>{this._onCustomMergeModeOpts.call(this,e)})
		this.$el.find('#loadMoreVersionsButton').off().click((ev)=>{this._onUpdateVersions.call(this, ev)})
	}

	getHTML () {
		return `
				<div class="modal-dialog modal-md">
					<div class="modal-content">
						<div class="modal-header create-modal-header">
							<h4>
							<span id="headerText"></span>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">×</span>
							</button>
							</h4>
						</div>
						<div class="modal-body">

							<div class="grid-2x-padding text-warning">
								<label>${app.getResource('application.will.be.forced.to.stop')}</label>
							</div>

							<div class="grid-2x-padding modal-component" style="padding-left: 20px;">
								<div id="radioGroup"></div>
							</div>

							<div id="forcedUpdateWarningDiv" class="text-warning" style="display:none; padding-bottom: 16px;">
								<label>${app.getResource('all.system.objects.will.be.updated.on.app')}</label>
							</div>

							<div id="customListDiv" class="grid-2x-padding"  style="display:none;">
								<a class="custom-mode-opts-link" href="#">${app.getResource('system.instances.to.merge')}</a>
							</div>

							<div id="selectApplicationVersionLabelDiv" class="grid-2x-padding text-warning">
								<label>${app.getResource('select.application.version.for.deploy')}</label>
								<a style="margin-left: 5px;" help-popover="select.application.version.for.deploy.help">
									<span class="glyphicon glyphicon-question-sign"></span>
								</a>
							</div>

							<div style="padding-left: 20px;">
								<div id="versionRadioGroupDiv" style="padding-bottom: 16px; max-height: 300px; overflow-y: auto; overflow-x:hidden;">
									<div id="versionRadioGroupWrapper">
									</div>

									<div id="noVersionsAvailable" style="display: none;">${app.getResource('no.application.versions.available.for.deploy')}</div>
								</div>
							</div>

							<div class="grid-2x-padding">
								<a id="loadMoreVersionsButton" href="#" style="display: block;">${app.getResource('load.more.application.versions')}</a>
							</div>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-default pull-left" data-action="cancel"
									data-dismiss="modal">${app.getResource('cancel')}
							</button>
							<button type="button" class="btn btn-danger pull-right okButton" data-action="create"
									data-dismiss="modal">${app.getResource('ok')}
							</button>
						</div>
					</div>
				</div>`;
	}

	show (options: Object) {
		const that = this;

		this.model = options.model || new BaseModel({
				applicationUpdateMode: AppUpdateMode.READ_ONLY
			});

		this.headerResource = options.headerResource;
		this.isDev = options.isDev;

		this.$el.find('.modal-header h4 #headerText').html(options.headerResource.toHTML());

		this.$el.find('.okButton').off('click').on('click', e => {
				if (options.onSubmit) {
					options.onSubmit(that.model);
				}
				that.$el.modal('hide');
				e.stopPropagation();
			});

		if (options.okResource) {
			this.$el.find('.okButton').text(translate(options.okResource));
		}

		new RadioGroupInputs({
			el: that.$el.find('#radioGroup')[0],
			options: [{
					text: translate('update.readonly.system.instances'),
					value: AppUpdateMode.READ_ONLY
				}, {
					text: translate('update.all.system.instances'),
					value: AppUpdateMode.ALL
				}, {
					text: translate('update.specific.system.instances'),
					value: AppUpdateMode.CUSTOM
				}],
			value: that.model.get('applicationUpdateMode'),
			mixin: {
				mounted: function () {
					$(this.$el).find('label').each((i, elem) => {
						let val = $(elem).find('input').val()
						$(elem).append(`
							<a style="margin-left: 5px;" help-popover="deployment.${val}.options">
								<span class="glyphicon glyphicon-question-sign"></span>
							</a>`)
					})

					that.$el.find('[help-popover]').each(function (a, elem) {
						buildHelpPopover((elem))
					});
				}
			},
			selectionChanged: (value) => {
				that.model.set('applicationUpdateMode', value);
				if (value === AppUpdateMode.CUSTOM) {
					$('#forcedUpdateWarningDiv').slideUp()
					$('#customListDiv').slideDown()
				} else if (value === AppUpdateMode.ALL) {
					$('#forcedUpdateWarningDiv').slideDown()
					$('#customListDiv').slideUp()
				} else {
					$('#forcedUpdateWarningDiv').slideUp()
					$('#customListDiv').slideUp()
				}
			}
		})

		const lastSuccessfulVersion = this.getLastSuccessfulVersion();

		if (lastSuccessfulVersion.length == 0) {
			this._onUpdateVersions();
		}
		else {
			this.renderVersionList(lastSuccessfulVersion, lastSuccessfulVersion[0]);
		}

		this.$el.modal('show')
	}

	_onCustomMergeModeOpts(el) {
		const that = this;
	 	const systemInstancesMergeModal = new SystemInstancesMergeModal();
		systemInstancesMergeModal.show({
				 headerResource: that.headerResource,
				 onSubmit: (instancesToMerge) => {
					 that.model.set('instancesToMerge', instancesToMerge);
				 }
		 });
		 el.preventDefault();
	}

	renderVersionList(versions, selected) {
		const wrapper = this.$el.find('#versionRadioGroupWrapper');
		wrapper.empty();
		wrapper.append('<div></div>');

		const options = [];

		let selectedValue = null;

		versions
			.sort((a, b) => b.buildNumber - a.buildNumber)
			.forEach(projectJar => {
				if (projectJar.deployEnabled) {

					let versionLabel = projectJar.buildNumber;
					if (projectJar.isDevMode) {
						versionLabel += ` (${app.getResource('dev.build')})`;
					}
					versionLabel += ': ' +
						Formatter.formatWithPredefinedFormat(Instant.fromJSON(projectJar.jarTimestamp), {
							primitiveType: PrimitiveEntityType.TIMESTAMP
						});

					if (projectJar.currentVersion) {
						versionLabel = versionLabel + ' ' + app.getResource('current.application.version');
					}

					const option = {
						value: projectJar.id,
						text: versionLabel
					}

					if (projectJar == selected) {
						selectedValue = option.value;
					}

					options.push(option);
				}
			});

		this.model.set('jarVersion', selectedValue);

		new RadioGroupInputs({
			el: wrapper.find('div')[0],
			options: options,
			value: selectedValue,
			selectionChanged: (value) => {
				this.model.set('jarVersion', value);
			}
		});
	}

	getLastSuccessfulVersion() {
		const lastSuccessfulBuild = this.isDev
			? app.buildView.build.lastSuccessful
			: app.buildView.build.lastSuccessfulFull;

		const initVersions = [];

		if (lastSuccessfulBuild != null) {
			initVersions.push({
				id: -1,
				buildNumber: lastSuccessfulBuild.buildNumber,
				jarTimestamp: lastSuccessfulBuild.buildTimestamp,
				deployEnabled: true,
				currentVersion: false,
				isDevMode: lastSuccessfulBuild.isDevMode
			});
		}

		return initVersions;
	}

	_onUpdateVersions(ev) {
		utils.getRequest(app.urls.getApplicationVersionList(this.model.get('appId')))
			.then(data => this.updateVersionsImpl(data))
			.catch((xhr, textStatus, errorThrown) => {
				console.log('Failed to fetch getApplicationVersionList.');

				this.updateVersionsImpl([]);
			});

		if (ev != null) {
			ev.preventDefault();
		}
	}

	updateVersionsImpl(data) {
		let lastSuccessfulBuildInServerList = false;
		const lastSuccessfulBuild = this.getLastSuccessfulVersion();
		const lastSuccessfulBuildExist = lastSuccessfulBuild.length > 0;
		let selected = null;

		this.$el.find('#loadMoreVersionsButton').hide(0);

		data.forEach(pj => {
			if (lastSuccessfulBuildExist) {
				if (pj.buildNumber == lastSuccessfulBuild[0].buildNumber) {
					lastSuccessfulBuildInServerList = true;

					selected = pj;
				}
			}
			else {
				if (pj.currentVersion) {
					selected = pj;
				}
			}
		});

		if (lastSuccessfulBuildExist && !lastSuccessfulBuildInServerList) {
			selected = lastSuccessfulBuild[0];

			data.push(selected);
		}

		if (data.length == 0) {
			this.$el.find('#noVersionsAvailable').show(0);

			this.$el.find('.okButton').prop('disabled', true);
		}
		else {
			if (selected == null) {
				selected = data[0];
			}

			this.renderVersionList(data, selected);
		}
	}

}
