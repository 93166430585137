import PrimitiveEntityType from '../enums/primitiveEntityType';
import MetaObjectState from '../enums/metaObjectState';
import * as blockUtils from '../components/blockUtils';
import ModelFactory from './modelFactory.js';
import ViewContext from './viewContext';
import utils from '../components/utils';
import Entities from '../collections/entities.js';
import States from '../enums/states';
import MultilingualString from './multilingualString.js'
import EmbeddedEntity from './embeddedEntity.js'
import Binary from './binary.js'

const Entity = Backbone.Model.extend({
	defaults() {
		return {
			clientState: States.NEW,
			metaObjectState: MetaObjectState.ACTIVE
		};
	},
	initialize(json, options) {
		this.filters = {};
		if (!this.entityTypeId) {
			if (options && options.entityTypeId) {
				this.entityTypeId = options.entityTypeId;
			} else {
				throw new Error('entityTypeId is missing');
			}
		}
		if (this.get('entityTypeId')) {
			this.set('entityTypeId', this.get('entityTypeId').toString());
		}
		if (this.get('entityViewId')) {
			this.set('entityViewId', this.get('entityViewId').toString());
		}
		if (this.get('version')) {
			this.set('version', this.get('version').toString());
		}
		if (app.types.get(this.entityTypeId).hasMetaObject()) {
			if (json && json.hasOwnProperty('viewContext')) {
				this.setViewContext(new ViewContext(json.viewContext));
			} else {
				this.setViewContext(new ViewContext());
			}
		}
		this.on('change', this.onChange, this);
		for (var prop in json) {
			if (json.hasOwnProperty(prop)) {
				var ref = json[prop];
				this._transformComplexProp(prop, ref);
			}
		}
		this.set('clientId', this.cid.substring(1))
	},

	_transformComplexProp(prop, jsonValue, isMerge) {
		if (jsonValue == null) {
			this.set(prop, null);
			return;
		}
		const type = app.types.get(this.entityTypeId);
		const field = type.fieldByName(prop);
		const Model = field &&  ModelFactory.getModelTypeByField(field);
		if (Model) {
			const value = Model.fromJSON(jsonValue, field.type() && field.type().id);
			this.set(prop, value);
		} else { // hack for setting primitives
			if (isMerge && this.get(prop) != jsonValue) {
				this.set(prop, jsonValue);
			}
		}
	},

	onChange() {
		var changedKeys = _.keys(this.changedAttributes());
		_.each(changedKeys, function (attr) {
			var oldValue = this.previous(attr);
			if (oldValue instanceof Backbone.Model ||
					oldValue instanceof Backbone.Collection) {
				oldValue.off('all');
			}
			var newValue = this.get(attr);
			// if plain js object - crete Backbone model
			if (newValue == null ||
				typeof newValue === 'string' ||
				_.isObject(newValue) &&
				(Object.getPrototypeOf( newValue ) === Object.prototype ||
				Object.getPrototypeOf( newValue ) === Array.prototype)) {
				this._transformComplexProp(attr, newValue);
			}

			if (newValue instanceof Backbone.Model ||
					newValue instanceof Backbone.Collection) {
				newValue.on('all', function (eventName, model, options) {
					const prefix = 'manualChange:';
					if (eventName.indexOf(prefix) >= 0) {
						if (eventName.endsWith('clientState')) {
							if (model.get('clientState') === States.DELETED) {
								this.trigger(
									'remove:' + attr,
									new Entities([model], {entityTypeId: newValue.entityTypeId}));
							}
						}
						var subAttr = eventName.substring(prefix.length);
						this.trigger(prefix + attr + '.' + subAttr, model);
					}
					const field = app.types.get(this.entityTypeId).fieldByName(attr);
					if (field && field.type() &&
								(field.type().isEmbedded() ||
								field.type().primitive() === PrimitiveEntityType.STRING ||
								field.type().primitive() === PrimitiveEntityType.BINARY)) {
						this.trigger("change" , this, model);
					}
					if (eventName === 'update') {
						if (options && options.changes) {
							if (options.changes.added.length) {
								this.trigger(
									'add:' + attr,
									new Entities(options.changes.added, {entityTypeId: newValue.entityTypeId}));
							}
							if (options.changes.removed.length) {
								this.trigger(
									'remove:' + attr,
									new Entities(options.changes.removed, {entityTypeId: newValue.entityTypeId}));
							}
						}
					}
				}, this);
			}
		}, this);
	},

	equals(json) {
		return this.id === json.id;
	},

	deepEquals(json){
		var propertiesToSkip = {
			clientState: true,
			metaObject: true,
			entityTypeId: true,
			entityViewId: true
		};
		for (var prop in json) {
			if (propertiesToSkip.hasOwnProperty(prop)) {
				continue;
			}
			if (this.attributes.hasOwnProperty(prop) === false) {
				return false;
			}
			if (_.isObject(this.get(prop))) {
				if (this.get(prop) == null && json[prop] == null) {
					continue;
				}
				else if (this.get(prop) != null && json[prop] == null) {
					return false;
				}
				else if (this.get(prop) == null && json[prop] != null) {
					return false;
				}
				else if (this.get(prop) instanceof app.classes.MultilingualString
						&& !this.get(prop).equals(app.classes.MultilingualString.fromJSON(json[prop]))) {
					return false;
				}
				else if (this.get(prop).id !== json[prop].id) {
					return false;
				}
			} else {
				if (this.get(prop) != json[prop]) {
					return false;
				}
			}
		}
		return true;
	},

	merge(json, original) {
		var propertiesToSkip = ['metaObject', 'version', 'viewContext'];
		for (var prop in json) {
			if (json.hasOwnProperty(prop) && propertiesToSkip.indexOf(prop) === -1) {
				var ref = json[prop];
				if (_.isArray(ref)) {
					//Applying differenece for original item
					if (this.get(prop) instanceof Entities) {
						let differencesMap = _.object(ref.filter(item => {
							return item.id;
						}).map(item => {
							return [item.id, item];
						}));
						const originalProp = original[prop] || [];
						let originalsMap = _.object(originalProp.map(item => {
							return [item.id, item];
						}));
						let result = _.filter(originalProp, item => {
								return !differencesMap.hasOwnProperty(item.id);
							}).concat(ref.filter(function (item) {
								return item.clientState != States.DELETED;
							}).map(item => {
								if (item.clientState == States.NEW) {
									return item;
								}
								return this.applyDifference(originalsMap[item.id], item);
							})
						);
						let collection = this.get(prop)
						let present = {}
						result = _.map(result, item => {
							if (!item.id && !item.clientId && item.entityTypeId) {
								const typeId = item.entityTypeId
								const Model = ModelFactory.getModelType(app.types.get(typeId))
								return Model.fromJSON(item, typeId).toJSON()
							}
							return item;
						})
						_.each(result, item => {
							let id = utils.getIdOrCid(item)
							present[id] = item
						})
						const silent = {silent: true}
						let toBeRemoved = []
						collection.each(row => {
							let id = row.getIdOrCid()
							if (!present[id]) {
								toBeRemoved.push(id)
							}
						})
						collection.remove(toBeRemoved, silent)
						let added = 0
						result.forEach(row => {
							let id = utils.getIdOrCid(row)
							if (collection.get(id)) {
								collection.get(id).merge(row, silent)
								collection.get(id).trigger('change', collection.get(id))
							} else {
								added++
								collection.add(row, silent)
							}
						})
						this.setRelativeOrders(collection)
						if (added || toBeRemoved.length) {
							collection.trigger('reset')
						}
					} else {
						this._transformComplexProp(prop, ref, true);
					}
				} else {
					this._mergeNonArrayProp(prop, ref);
				}
			}
		}
		for (var prop in original) {
			if (!json.hasOwnProperty(prop) && propertiesToSkip.indexOf(prop) === -1) {
				let value = original[prop];
				if (_.isArray(value)) {
					if (this.get(prop) instanceof Entities) {
						this.get(prop).reset(value.slice(0));
					} else {
						this._transformComplexProp(prop, value, true);
					}
				} else {
					this._mergeNonArrayProp(prop, value);
				}
			}
		}
		if (json.hasOwnProperty('viewContext')) {
			if (!this.hasAttribute('viewContext')) {
				this.setViewContext(new ViewContext());
			}
			this.getViewContext().merge(json.viewContext);
		}
		this.trigger('merged');
	},

	_mergeNonArrayProp(prop, value) {
		const field = app.types.get(this.entityTypeId).fieldByName(prop);
		if (field && field.type() &&
					(field.type().isEmbedded() ||
					field.type().primitive() === PrimitiveEntityType.STRING ||
					field.type().primitive() === PrimitiveEntityType.BINARY)) {
			if (this.get(prop)) {
				if (value === null) {
					this.set(prop, null);
				} else {
					this.get(prop).merge(value);
				}
			} else {
				this._transformComplexProp(prop, value, true);
			}
		} else {
			this._transformComplexProp(prop, value, true);
		}
	},

	applyDifference(original, difference) {
		let result = _.clone(original) || {};
		for(let prop in difference) {
			if (this.isObject(difference[prop])) {
				result[prop] = this.applyDifference((original && original[prop]) || {},
				    difference[prop]);
			} else {
				result[prop] = difference[prop];
			}
		}
		return result;
	},

	isObject(obj) {
		return obj === Object(obj);
	},

	get(fieldName) {
		if (!this.hasAttribute(fieldName)) {
			if (this.shouldHave(fieldName)) {
				this._initializeFields();
				this.get = Entity.__super__.get;
			}
		}
		return Entity.__super__.get.apply(this, arguments);
	},

	async asyncGet(fieldName) {
		if (!this.hasAttribute(fieldName)) {
			if (this.shouldHave(fieldName)) {
				await this._fetch();
			}
		}
		return this.get(fieldName);
	},

	hasAttribute(attr) {
		return this.attributes.hasOwnProperty(attr);
	},

	shouldHave(fieldName) {
		const fieldType = app.types.get(this.entityTypeId);
		const typeHasField = fieldType.fieldByName(fieldName) != null;
		return typeHasField;
	},

	getIdOrCid() {
		return utils.getIdOrCid({
			id: this.id,
			clientId: this.get('clientId')
		})
	},

	async _fetch() {
		this._initializeFields();
	},

	_initializeFields() {
		const changes = {};
		const initializeField = field => {
			const fieldName = field.fieldName();
			if (!this.hasAttribute(fieldName)) {
				changes[fieldName] = field.defaultValue();
			}
		};
		const type = app.types.get(this.entityTypeId);
		_.each(type.fields(), initializeField);
		_.each(type.sharedFields(), initializeField);
		this.set(changes, {silent: true});
	},

	toJSON() {
		return _.chain(this.attributes)
			.clone()
			.mapObject(function (val, key) {
				if (moment.isMoment(val)) {
					return val.toISOString();
				} else if (_.isObject(val)) {
					if (val.toJSON) {
						return val.toJSON();
					}
				}
				return val;
			})
			.value();
	},

	toServerJSON(original) {
		//calc difference with original
		const requiredFields = ['id', 'clientState', 'entityTypeId', 'entityViewId',
			'version', 'clientId', 'viewContext'];
		const type = app.types.get(this.get('entityTypeId'));
		return _.chain(this.attributes)
			.clone()
			.mapObject(function (val, prop) {
				let result;
				if (_.isObject(val)) {
					if (val.toServerJSON) {
						let oldValue = original && original[prop];
						let field = type && type.fieldByName(prop);
						if (field && field.get('isTransient')) {
							oldValue = null;
						}
						result = val.toServerJSON(oldValue);
						if (result.id && isNaN(result.id)) {
							result.id = null;
						}
					} else if (val.toJSON) {
						result = val.toJSON();
						if (result.id && isNaN(result.id)) {
							result.id = null;
						}
					} else {
						result = val;
					}
				} else {
					result = val;
				}
				return result;
			})
			.pick(function (val, key) {
				let field = type && type.fieldByName(key);
				return requiredFields.indexOf(key) > -1 ||
								(field && field.get('isTransient')) ||
								!utils.equals(val, original && original[key], true);
			})
			.value();
	},

	setFilters(fieldName, filters) {
		this.filters[fieldName] = filters;
	},

	getFilters(fieldName) {
		return this.filters[fieldName] || [];
	},

	getViewContext() {
		return this.get('viewContext');
	},

	setViewContext(context) {
		this.set('viewContext', context);
	},

	clone() {
		return Entity.fromJSON(this.toJSON(), { entityTypeId: this.entityTypeId });
	},

	toReference() {
		return {
			id: this.id
		}
	},

	setRelativeOrders(collection) {
		let maxOrder = _.max(collection.models.map(m => m.get('relativeOrder')))
		collection.models.filter(m => m.get('relativeOrder') === null).forEach(m => m.set('relativeOrder', ++maxOrder))
	}
});

Entity.fromJSON = function (obj, entityTypeId): Entity {
	return new this(obj, {entityTypeId: entityTypeId});
};

Entity.fromMetaObject = function (metaObject): Entity {
	if (metaObject == null) {
		return null;
	}
	const metaObjectId = blockUtils.getId(metaObject);
	const typeId = blockUtils.getId(blockUtils.getObjectType(metaObject));
	if (metaObjectId == null || typeId == null) {
		throw new Error('Invalid dynamic object');
	}
	return Entity.reference(metaObjectId, typeId);
};

Entity.reference = function (instanceId, entityTypeId) {
	return new (require('./entityReference').default)({id: instanceId, isReference: true}, {entityTypeId});
};

export default Entity;
