/* @flow */

import utils from '../../common/components/utils.js';
import type {
	TemporalAccessor
} from '../../time/declarations';
import PrimitiveEntityType from '../../common/enums/primitiveEntityType';
import DateTimeFormatter from '../../time/formatters/dateTimeFormatter';
import Formatter from '../../common/components/formatter';
import {translate} from '../../common/service/stringResourceService'
import * as time from '../../time/models/index';
import DatepickerInput from './datepickerInput';

var FieldTemporalAccesorInput = DatepickerInput.extend({

	events: {
		'click': 'click'
	},

	initialize: function(options) {
		FieldTemporalAccesorInput.__super__.initialize.apply(this, arguments);
		this._buildDOM();
		this.minutesStep = this.$el.attr('data-minutes-step') || 1
		this._initializeWidgetEvents();
		this._initializeDatepicker();
		this._addOkButton()
		this.timeFormat = this.$el.attr('data-time-format')
		if (this.disabled){
			this.button.attr('disabled', 'disabled');
		}
		this.clientViewId = this.$el.attr('data-client-view')
		this.dropdownOpened = false
		this.context = options.context;
	},

	_initializeDatepicker: function () {
		const that = this;
		const primitiveType = utils.getPrimitiveType(this.$el);

		let opts = {
			language: app.currentLanguage,
			inline: true,
			minutesStep: this.minutesStep,
			onSelect: (formattedDate, date, inst) => {
				that._onSelect(formattedDate, date, inst);
			}
		}

		switch (primitiveType) {
			case PrimitiveEntityType.MONTH:
				opts = _.extend(opts, {
					view: 'months',
					minView: 'months',
					navTitles: {
						days: 'MM',
						months: '-',
						years: '-'
					}
				});
				this.datepicker.datepicker(opts);
				this.datepicker.data('datepicker').$nav.hide();
				break;
			case PrimitiveEntityType.TIMESTAMP:
				opts = _.extend(opts, {
					timepicker: true,
					timeFormat:'@',
					dateFormat:' '
				});
				this.datepicker.datepicker(opts);
				break;
			case PrimitiveEntityType.LOCAL_DATE:
				this.datepicker.datepicker(opts);
				break;
			case PrimitiveEntityType.LOCAL_TIME:
				opts = _.extend(opts, {
					timepicker: true,
					onlyTimepicker: true,
					timeFormat:'hh:ii'
				});
				this.datepicker.datepicker(opts);
				break;
			case PrimitiveEntityType.LOCAL_DATE_TIME:
				opts = _.extend(opts, {
					timepicker: true,
					timeFormat:'hh:ii'
				});
				this.datepicker.datepicker(opts);
				break;
			case PrimitiveEntityType.MONTH_DAY:
				opts = _.extend(opts, {
					onChangeView: function(view) {
						if (view == 'months') {
							that.datepicker.data('datepicker').$nav.hide();
						} else {
							that.datepicker.data('datepicker').$nav.show();
						}
					},
					navTitles: {
						days: 'MM',
						months: '-',
						years: '-'
					}
				});
				this.datepicker.datepicker(opts);
				this.datepicker.find('.datepicker--days-names').hide();
				break;
			case PrimitiveEntityType.YEAR:
				opts = _.extend(opts, {
					minView: 'years',
					view: 'years'
				});
				this.datepicker.datepicker(opts);
				break;
			case PrimitiveEntityType.YEAR_MONTH:
				opts = _.extend(opts, {
					minView: 'months',
					view: 'months'
				});
				this.datepicker.datepicker(opts);
				break;
		}

		this.datepickerInstance = this.datepicker.datepicker().data('datepicker');
		this.datepicker.on('clickCell', () => this._hideDropdown());

		this.datepicker.find('.datepicker')
				.children()
				.wrapAll('<div class="datepicker--container1"><div class="datepicker--container2">');
	},

	_onSelect: function(formattedDate, date, inst) {
		if(!date) {
			return;
		}

		const that = this;
		const primitiveType = utils.getPrimitiveType(this.$el);

		inst.lastSelectedDate && date.setSeconds(inst.lastSelectedDate.getSeconds());

		const momentObject: moment$Moment = global.moment(date);
		const momentObjectWithoutTimeZone: moment$Moment = global.moment(momentObject).utc()
			.add(momentObject.utcOffset(), 'm');

		let obj: TemporalAccessor;

		switch (primitiveType) {
			case PrimitiveEntityType.MONTH:
				obj = time.Month.fromMoment(momentObjectWithoutTimeZone);
				break;
			case PrimitiveEntityType.TIMESTAMP:
				obj = time.Instant.ofEpochMilli(formattedDate);
				break;
			case PrimitiveEntityType.LOCAL_DATE:
				obj = time.LocalDate.fromMoment(momentObjectWithoutTimeZone);
				break;
			case PrimitiveEntityType.LOCAL_TIME:
				obj = time.LocalTime.fromMoment(momentObjectWithoutTimeZone);
				break;
			case PrimitiveEntityType.LOCAL_DATE_TIME:
				obj = time.LocalDateTime.fromMoment(momentObjectWithoutTimeZone);
				break;
			case PrimitiveEntityType.MONTH_DAY:
				obj = time.MonthDay.fromMoment(momentObjectWithoutTimeZone);
				break;
			case PrimitiveEntityType.YEAR:
				obj = time.Year.fromMoment(momentObjectWithoutTimeZone);
				break;
			case PrimitiveEntityType.YEAR_MONTH:
				obj = time.YearMonth.fromMoment(momentObjectWithoutTimeZone);
				break;
			default:
				throw new Error('Wrong primitive entitytype!');
		}
		this.setValue(obj,true)
	},

	_onShownDropDown: function() {
		FieldTemporalAccesorInput.__super__._onShownDropDown.apply(this, arguments);
		this.dropdownOpened = true
		if (!this.model.get(this.modelAttr)) {
			this._onSelect(Date.now(),new Date(),{})
		}
	},

	_onHidenDropDown(e) {
		FieldTemporalAccesorInput.__super__._onHidenDropDown.apply(this, arguments);
		if(this.validate()) {
			this.setNormalView()
			this.setDataToModel(this.getObject());
		} else {
			this.setErrorView()
		}
		this.dropdownOpened = false
	},

	setValue: function(value: ? TemporalAccessor, silent) {

		if (!value) {
			this.$el.val(null);
			this.datepickerInstance.clear();
			this.$textEl.html('-');
			return;
		}

		const primitiveType = utils.getPrimitiveType(this.$el);
		const formatterId = this.$el.attr('data-formatter-id');
		const typeId = this.$el.attr('data-entity-type-id');

		this.fullValue = value

		switch (primitiveType) {
			case PrimitiveEntityType.MONTH:
			case PrimitiveEntityType.TIMESTAMP:
			case PrimitiveEntityType.LOCAL_DATE:
			case PrimitiveEntityType.LOCAL_TIME:
			case PrimitiveEntityType.LOCAL_DATE_TIME:
			case PrimitiveEntityType.MONTH_DAY:
			case PrimitiveEntityType.YEAR:
			case PrimitiveEntityType.YEAR_MONTH:
				const text =  Formatter.format(value, {
					formatterId: formatterId,
					typeId: typeId,
					primitiveType: primitiveType
				});
				this.$el.val(text)
				Formatter.formatToHTMLWithClientView(value.toJSON(), {
					formatterId: formatterId,
					typeId: typeId,
					clientViewId: this.clientViewId,
					primitiveType: primitiveType,
					el: this.$textEl
				})
				if (!silent) {
					let date = value.toMoment().utcOffset(
					global.moment(value.toMoment().format()).utcOffset(), true).toDate();
					if(primitiveType === PrimitiveEntityType.TIMESTAMP) {
						date = value.toMoment().toDate();
					}
					this.datepickerInstance.selectDate(date);
					this.datepickerInstance.date = date;
				}
				break;
			default:
				throw new Error('Wrong primitive entitytype!');
		}
	},

	getObject: function(): ?TemporalAccessor {
		const primitiveType = utils.getPrimitiveType(this.$el);
		const formatterId = this.$el.attr('data-formatter-id');
		const typeId = this.$el.attr('data-entity-type-id');

		const format: string = DateTimeFormatter
			.javaPatternToMomentPattern(Formatter.getEditingFormat({
				formatterId: formatterId,
				typeId: typeId,
				primitiveType: primitiveType
			}));
		let fullFormat = format
		if (!this.changesFromInput && Formatter.FULL_FORMATS[primitiveType]) {
			fullFormat = Formatter.FULL_FORMATS[primitiveType]
		}
		let value: ?string = this.$el.val()

		if (!this.changesFromInput && Formatter.FULL_FORMATS[primitiveType]) {
			value = Formatter.formatWithPredefinedFormat(this.fullValue, { primitiveType: primitiveType })
		}
		this.changesFromInput = false

		if (!value) {
			return null;
		}
		switch (primitiveType) {
			case PrimitiveEntityType.TIMESTAMP:
				return time.Instant.fromMoment(global.moment(value, fullFormat,
					app.currentLanguage, true));
			case PrimitiveEntityType.MONTH:
				return time.Month.fromMoment(global.moment.utc(value, fullFormat,
					app.currentLanguage, true));
			case PrimitiveEntityType.LOCAL_DATE:
				return time.LocalDate.fromMoment(global.moment.utc(value, fullFormat,
					app.currentLanguage, true));
			case PrimitiveEntityType.LOCAL_TIME:
				return time.LocalTime.fromMoment(global.moment.utc(value, fullFormat,
					app.currentLanguage, true));
			case PrimitiveEntityType.LOCAL_DATE_TIME:
				return time.LocalDateTime.fromMoment(global.moment.utc(value, fullFormat,
					app.currentLanguage, true));
			case PrimitiveEntityType.MONTH_DAY:
				return time.MonthDay.fromMoment(global.moment.utc(value, fullFormat,
					app.currentLanguage, true));
			case PrimitiveEntityType.YEAR:
				return time.Year.fromMoment(global.moment.utc(value, fullFormat,
					app.currentLanguage, true));
			case PrimitiveEntityType.YEAR_MONTH:
				return time.YearMonth.fromMoment(global.moment.utc(value, fullFormat,
					app.currentLanguage, true));
			default:
				throw new Error('Wrong primitive entitytype!')
		}
	},

	validate: function (): boolean {
		const primitiveType = utils.getPrimitiveType(this.$el);
		const formatterId = this.$el.attr('data-formatter-id');
		const typeId = this.$el.attr('data-entity-type-id');

		const format: string = DateTimeFormatter
			.javaPatternToMomentPattern(Formatter.getEditingFormat({
				formatterId: formatterId,
				typeId: typeId,
				primitiveType: primitiveType
			}));
		const value: ?string = this.$el.val();
		let momentObject;
		switch (primitiveType) {
			case PrimitiveEntityType.TIMESTAMP:
				momentObject = global.moment(value, format, app.currentLanguage, true);
				break;
			case PrimitiveEntityType.MONTH:
			case PrimitiveEntityType.LOCAL_DATE:
			case PrimitiveEntityType.LOCAL_TIME:
			case PrimitiveEntityType.LOCAL_DATE_TIME:
			case PrimitiveEntityType.MONTH_DAY:
			case PrimitiveEntityType.YEAR:
			case PrimitiveEntityType.YEAR_MONTH:
				momentObject = global.moment.utc(value, format, app.currentLanguage, true);
				break;
			default:
				throw new Error('Wrong primitive entitytype!')
		}
		return value ? momentObject.isValid() : true;
	},

	//triggered in fieldInput onChange
	changed: function(){},

	//triggered in fieldInput onBlur
	focusLost: function (e) {
		if (!this.dropdownOpened){
			if (this.validate()) {
				this.setDataToModel(this.getObject());
			} else {
				this.setValue(this.getDataFromModel(), false);
			}
			this.setNormalView()
		}
	},

	keyTyped: function(e) {
		FieldTemporalAccesorInput.__super__.keyTyped.apply(this, arguments);
		this.changesFromInput = true
	}

});

export default FieldTemporalAccesorInput;
