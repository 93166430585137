import TypeKinds from '../components/entityTypeKinds.js';
import EntityCategoryView from './entityCategoryView.js';
import ModuleView from './moduleView.js';
import BaseModel from '../../common/models/baseModel';
import utils, { buildDefaultPopover } from '../../common/components/utils';
import BaseUpdateView from '../../common/views/baseUpdateView';
import {onCheckAndUncheck, convertSelectionsToIds} from '../../utils/tableUtils';
import {filterTable} from '../../utils/entitiesTableFilter';
import {translate} from '../../common/service/stringResourceService'
import Constants from '../../common/models/constants'
import CreateModal from '../../common/components/createModal';
import CreateType from './createView'
import StateRecovery from '../../common/components/stateRecovery';
import TypeKind from '../../common/enums/typeKind';

var MainView = Backbone.View.extend({

	events: {
		'click .addType': 'addType',
		'click .editTypeName': 'editTypeName',
		'click .editTypeSystemName': 'editTypeSystemName',
		'click .copy-to-clipboard': 'copyToClipBoard',
		'click .removeType': 'removeType',
		'click .typeKind':'updateShowData',
		'click .categoriesPanel':'updateShowData',
		'click .modulesPanel':'updateShowData',
		'click #entitiesTable_clear-filters':'clearFilters',
		'input .tableSearch':'tableSearch'
	},

	el: 'body',

	initialize: function () {
		$('#new-type-dropdown').replaceWith(newDataTypeDropdown())
		this.filterObject=null
		app.showData=[];
		app.data.forEach((type) => {
			if (type.permission) {
				type.permission.name={}
			}
		})
		utils.getRequest(app.urls.home+'permission/findAll').then( (permissions) => {
			app.data.forEach( (type) => {
				if (type.permission) {
					let permission = permissions.filter((permission) => {return permission.id == type.permission.id})[0]
					if (permission&&permission.name) {
						type.permission.name = permission.name
					}
				}
			})
		})
		this.typeKinds = new TypeKinds();
		this.typeKinds.setVisibleTypeKinds(this.getPresentTypeKinds());
		this.entityCategoryView = new EntityCategoryView();
		this.moduleView = new ModuleView();
		$('#create-modal').on('shown.bs.modal', () => {
			$('#create-modal').find('input[data-field]').first().focus();
		});
		$('#edit-modal').on('shown.bs.modal', () => {
			$('#edit-modal').find('input[data-field]').first().focus();
		});
		app.createModal3 = new CreateModal({
			id: 'create-modal3'
		});
	},

	getPresentTypeKinds: function () {
		return _.uniq(_.pluck(app.data,"typeKind"))
	},

	render: function () {
		let kinds = this.typeKinds
		let button=$("#entitiesTable_removeWithDependencies");
		button.wrap(`<div class="wrapper-button" style="display: inline-block" height=\"` + button[0].offsetHeight +`\" width=\"` + button[0].offsetWidth+ `\"></div>`);
		let wrapper = button[0].parentElement;
		kinds.render()
		this.entityCategoryView.render();
		this.moduleView.render();
		let op = {
			rows: app.showData,
			columns: [{
				type:'checkbox'
			},{
				field: 'typeKind',
				title: translate('type.kind'),
				formatter: typeKind => `<span class="label table-label block-label label-${kinds.kindToColor[typeKind]}">${translate(typeKind.toLowerCase())}</span>`,
				sorter: app.formatters.predicate,
				width: 100
			}, {
				field: 'name',
				title: translate('type.name'),
				formatter: (name, { id }) => app.formatters.typeLink({ name, id }),
			  sorter: app.formatters.multilingualstringPredicate
			}, {
				field: 'systemName',
				title: translate('system.name'),
				sorter: app.formatters.predicate
			}, {
				field: 'permission',
				title: translate('permission'),
				formatter: (permission,row,index) => permission&&app.formatters.name(permission.name,row,index),
				sorter: (lhs, rhs) => {
						if(!lhs || !rhs) {
							return !lhs - !rhs;
						}
						return app.formatters.multilingualstringPredicate(lhs.name, rhs.name);
					}
				}, {
				field: 'categories',
				title: translate('categories'),
				formatter: (categories, row, index) => categories && this.formatCategories(categories, row, index)
				}, {
				title: '',
				formatter: app.formatters.typeActions,
				width: 42
			}],
			events: {
				 selectionChanged: function(s){
				 	onCheckAndUncheck(s.length,button);
					$("#entitiesTable_selectedEntities").text(s.length);
					if (s.length !== 0 && button[0].parentElement.classList.contains('wrapper-button')) {
						button[0].addEventListener('click', (e) => { e.stopPropagation() })
					} else if (s.length == 0) {
						buildPopoverForDisabledDelete(wrapper);
					}
				 },
				 rowClicked (row, e) {
					let node = e.target.nodeName
					if (node != 'A' && node != 'I') {
						utils.redirectTo(app.urls.open(Constants.ID_TYPE_TYPE, row.id))
					}
				}
			},
			table: {
				isRowSelectable:function(row,num){
					return !row.isPredefined;
				},
				rowClasses : (item, index) => {
					return {
						clickable: 'clickable'
					}
				}
			}
		}
		this.filterObject=filterTable(this.filterObject,this.connector);
    this.updateClearFilters()
		this.connector =TableConnector($('#table2')[0], op).connect();
		button.on('click',a=>{
			utils.confirmDelete(convertSelectionsToIds(this.connector.getSelection()), app.urls.dependencies(Constants.ID_TYPE_TYPE), app.urls.delete(Constants.ID_TYPE_TYPE) + "?clearReferences=true");
		})
		if (button[0].classList.contains('disabled')) {
			buildPopoverForDisabledDelete(wrapper);
		}
		buildPopoversForAddType($('body'))

		let key = 'addCategory'
		if (location.href.includes(key)) {
			let url = new URL(location.href)
			url.searchParams.delete(key)
			window.history.replaceState({url: url.href}, null, url.href)
			this.entityCategoryView.add()
		}
	},

	updateShowData: function(){
		this.filterObject=filterTable(this.filterObject,this.connector)
    this.updateClearFilters()
	},

  updateClearFilters: function() {
    if (this.filterObject.filters.length || this.filterObject.search) {
      $('#entitiesTable_clear-filters').removeClass('hidden')
    }else {
      $('#entitiesTable_clear-filters').addClass('hidden')
    }
  },

	tableSearch: function(event){
		var text=event.originalEvent.target.value;
			this.filterObject.search=text;
			this.filterObject=filterTable(this.filterObject,this.connector);
      this.updateClearFilters()
	},

	addType: function (event) {
    $(event.currentTarget).parent().popover('hide')
		addEntityType(event)
	},

	editTypeName: function (event) {
		let typeId = $(event.currentTarget).data('id');
		var appDataIndex=_.findIndex(app.data,(d)=>{return d.id==typeId});
		let model = new BaseModel(app.data[appDataIndex]);
		app.editModal.show({
			url: app.urls.update(Constants.ID_TYPE_TYPE, typeId),
			headerResource: `edit.type`,
			model: model,
			view: BaseUpdateView,
			afterUpdateOnSuccess: (clientData) => {
				app.data[appDataIndex].name=clientData.name
			}
		});
	},

	editTypeSystemName: function (event) {
		let typeId = $(event.currentTarget).data('id');
		var appDataIndex =_.findIndex(app.data, (type) => { return type.id == typeId});
		let model = new BaseModel(app.data[appDataIndex]);
		app.editModal.show({
			url: app.urls.update(Constants.ID_TYPE_TYPE, typeId),
			headerResource: `edit.system.name`,
			model: model,
			view: BaseUpdateView,
			afterUpdateOnSuccess: (clientData) => {
				app.data[appDataIndex].systemName = clientData.systemName
			},
			html: `<div class="modal-component">
								<label for="name">${app.getResource('system.name')}</label>
								<input class="form-control" type="text" data-field="systemName"/>
							</div>`
		});
	},

	copyToClipBoard: function (event) {
		let id = $(event.currentTarget).data('copy');
		utils.setClipboard(id);
		app.notificationManager.addInfo(`${id} has been copied to clipboard`);
	},

	removeType: function (event) {
		const typeId = $(event.currentTarget).data('id');
		utils.confirmDelete([typeId], app.urls.dependencies(Constants.ID_TYPE_TYPE), app.urls.delete(Constants.ID_TYPE_TYPE) + "?clearReferences=true");
	},

	formatCategories: function (categories, row, index) {
		let formattedCategories = "";
		categories.forEach((category, ind) => {
			formattedCategories += '<span style="margin-left: 3px;">' + app.formatters.name(category.name, row, index) + '</span>'
			if (ind != categories.length - 1)
				formattedCategories += ', ';
		});
		return formattedCategories;
	},

	clearFilters: function (){
				$('.entityCategory.active').removeClass('active');
				$('.typeKind.active').removeClass('active');
				$('.module.active').removeClass('active');
				$(".tableSearch")[0].value=""
				this.filterObject=filterTable(this.filterObject,this.connector)
        this.updateClearFilters()
	}
});

export default MainView;

export function	newDataTypeDropdown() {
		return `
		<ul style="left:auto; top:auto" class="dropdown-menu wide-tooltip">
			<li>
				<a class="addType" data-kind="DICTIONARY">
					<span>${app.getResource('dictionary')}</span>
				</a>
			</li>
			<li>
				<a class="addType" data-kind="PRIMITIVE">
					<span>${app.getResource('primitive')}</span>
				</a>
			</li>
			<li>
				<a class="addType" data-kind="EMBEDDED">
					<span>${app.getResource('embedded')}</span>
				</a>
			</li>
			<li>
				<a class="addType" data-kind="DOCUMENT">
					<span>${app.getResource('document')}</span>
				</a>
			</li>
			<li>
				<a class="addType" data-kind="REPORT">
					<span>${app.getResource('report')}</span>
				</a>
			</li>
			<li>
				<a class="addType" data-kind="REGISTER">
					<span>${app.getResource('register')}</span>
				</a>
			</li>
			<li>
				<a class="addType" data-kind="TRANSIENT">
					<span>${app.getResource('transient')}</span>
				</a>
			</li>
		</ul>
		`
	}

export function addEntityType(event, op){
	op = op || {}
	const model = new BaseModel({ name: { translations: {} } })
	const kind = $(event.currentTarget).data('kind')
	if (kind == 'DOCUMENT' || kind == 'REGISTER') {
		if (!app.postingUpdateAccess) {
			return;
		}
	}
	let options = {
		url: app.urls.create(Constants.ID_TYPE_TYPE),
		headerResource: `add.${kind.toLowerCase()}.type`,
		model: model,
		afterCreateOnSuccess: (data) => {
			if (data.typeKind == TypeKind.DICTIONARY && (window.location.href.indexOf('entityType?saveIdToLocalStorage=true') != -1)){
				StateRecovery.put("createdDictionaryInWizard",data.id)
			}
			if ((data.typeKind == TypeKind.DICTIONARY || data.typeKind == TypeKind.DOCUMENT) && !window.localStorage.getItem("createdDictionaryInWizard")) {
				StateRecovery.put("createdDictionaryInWizard",data.id)
			}
			utils.redirectTo(app.urls.open(Constants.ID_TYPE_TYPE, data.id));
		},
		createView: CreateType
	}
	options = Object.assign(options, op)
	model.set('typeKind', kind)
	const modalInstance = op.modalInstance || app.createModal
	modalInstance.show(options)
}
export function buildPopoversForAddType(el){
	const dataType = el.find('.addType')
	dataType.each((a, elem) => {
		if (elem.classList.contains('no-popover')){
			return
		}
		let parent = $(elem).parent()
		let message = `<span>${translate(`description.${elem.dataset.kind.toLowerCase()}.type`)}</span>`
		if (!app.postingUpdateAccess && ['DOCUMENT', 'REGISTER'].indexOf(elem.dataset.kind) !== -1) {
			message += `<br><span style="color:greenyellow"><span class="fa fa-warning"></span><b>${translate('lack.of.builder.license')}</b></span>`
			parent.addClass('disabled')
		}
		buildDefaultPopover(parent, {
			toggle: 'popover',
			content: message,
			html: true,
			delay: 1000,
			container: 'body',
			placement: 'right'
		})
	})
}
function buildPopoverForDisabledDelete(wrapper) {
	buildDefaultPopover($(wrapper), {
		toggle: 'popover',
		content: translate('delete.button.disabled'),
		html: true,
		delay: 500,
		container: 'body',
		placement: 'top',
		trigger: 'click'
	})
}
