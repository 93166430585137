import IndexTable from './indexTable';
import FoldersTree from './foldersTree';
import StateRecovery from '../../common/components/stateRecovery';
import {convertSelectionsToIds} from '../../utils/tableUtils';
import utils from '../../common/components/utils.js';
import LocalStorageKeysService from '../../common/service/localStorageKeysService'

export default class VirtualCollection extends IndexTable {

	initialize(options) {
		this.showAllChildren=$(options.el).attr('data-show-all-children')
		this.typeId = options.typeId
		var loadAllInstancesPromise
		if (this.showAllChildren) {
			loadAllInstancesPromise = this.loadAllInstances(false)
		} else {
			loadAllInstancesPromise = Promise.resolve()
		}
		this.initPromise = loadAllInstancesPromise.then(() => {
		this.isField = true;
		super.initialize(options);
		if (options.folders && options.folders.length && this.hasFolderTree) {
			this.tree = new FoldersTree({
				el: options.folders,
				captionEl: options.caption,
				addInstance: () => this.addInstance(),
				table: this.foldersTreeStruct,
				type: options.treeViewKind,
				typeId: options.typeId,
				fieldId: options.fieldId,
				showInstance: (typeId, rowId) => this.showInstance(typeId, rowId),
				addNewButton: this._get('add'),
				addNewInContextMenu: true,
				dndEnabled: true,
				statefull: true,
				showRoot: true,
				isTreeSelectable: this.isTreeSelectable,
				onlyFolderTree: this.onlyFolderTree,
				updateTableSelection: () => { this.updateTableSelection() },
				showAllChildren: this.showAllChildren,
				loadAllInstances: (reloadTable) => { this.loadAllInstances(reloadTable) }
			});
			this.setTree(this.tree);
			this._get('toggleFolders').first().click(() => this.toggleFolders());
			this.foldersClosed = StateRecovery.get(LocalStorageKeysService.buildKeyForFoldersTree(options.fieldId));
			if (!this.foldersClosed) {
				this.toggleFoldersNoAnimation();
			}
			this.$el.closest('.row').addClass('flex-collection')
			this.$el.closest('.row').find('.folders-tree').parent().resize(() => { this.updateWidth() })
			this.tree.render();
			this.tree.$el.css('max-height', '400px');
			this.tree.$el.perfectScrollbar();
			this.tree.$el.parent().resizable({
				minWidth: 50,
				handles: 'e'
			});
		}
	})
	}

	toggleFolders (invisible) {
		this.tree.$el.parent().toggleClass('col-0 col-md-2');
		if (!invisible) {
			this.foldersClosed = !this.foldersClosed;
			StateRecovery.put(LocalStorageKeysService.buildKeyForFoldersTree(this.tree.fieldId), this.foldersClosed);
			this.updateWidth()
		}
	}

	toggleFoldersNoAnimation () {
		this.tree.$el.parent().toggleClass('slide-content no-slide-content');
		this.tree.$el.parent().next().toggleClass('slide-content no-slide-content');
		this.toggleFolders(true);
		this.tree.$el.parent().toggleClass('slide-content no-slide-content');
		this.tree.$el.parent().next().toggleClass('slide-content no-slide-content');
	}

	getTreeSelectedIds () {
		return this.foldersTreeStruct.selectedFolderIds || []
	}

	updateTableSelection () {
		this.data.forEach(item => {
			if (item) {
				var obj = {
					item: item
				}
				var selected = this.getTreeSelectedIds()
				if (selected.includes(item.id)) {
					obj.value = true
				} else {
					obj.value = false
				}
				this.newTable.checkOne(obj)
			}
		})
		this.selected = this.getSelectedIds()
		this.onCheckAndUncheck(true)
	}

	synchronizeTableSelection (treeIds) {

		treeIds.forEach((treeItemId) => {
			var item = this.data.find((i) => { return i.id == treeItemId })
			if (item) {
				var obj = {
					item: item,
					value: true
				}
				this.newTable.checkOne(obj)
			}
		})

		this.data.forEach((instance) => {
			var item = treeIds.find((i) => { return i == instance.id })
			if (!item) {
				var obj = {
					item: instance,
					value: false
				}
				this.newTable.checkOne(obj)
			}
		})

	}

	updateTreeSelection (selectedInTable) {

		var selectedInTree = [...this.getTreeSelectedIds()]
		selectedInTable.forEach((id) => {
			if ((!selectedInTree && id) || (selectedInTree && !selectedInTree.includes(id))) {
				this.tree.addToCheckedNodes(id)
			}
		})
		_.without(this.selected,selectedInTable).forEach((id) => {
			if ((!selectedInTable || !selectedInTable.includes(id)) && this.data.find((el) => { return el.id == id })) {
				this.tree.removeFromCheckedNodes(id)
			}
		})

	}

 // Use only for table with tree where tree is with option showAllChildren
	loadAllInstances (reloadTable) {
		let url = app.urls.getFolders + '?instanceId=&typeId=' + this.typeId + '&treeRoot=false&wholeTree=true'
		return utils.getRequest(url).then((data) => {
			this.allInstancesTree = this.buildMapForTree(data)
			if (reloadTable) {
				this.newTable.resetMore()
			}
		})
	}

	buildMapForTree(data) {
			let groupByParent = _.groupBy(data, e => e.parent)
			let groupByParentWithChildren = new Map()
			for (let i in groupByParent) {
				let childrenWithInstances = []
				let children = groupByParent[i]
				if (children.length) {
					children.forEach((child) => {
						if (Object.keys(groupByParent).includes(child.id)) {
							childrenWithInstances.push(child.id)
						}
					})
				}
				if (childrenWithInstances.length) {
					groupByParentWithChildren.set(i, childrenWithInstances)
				}
			}
			return groupByParentWithChildren
	}

	getAllIdsOfParentsWithChildren() {
		let selectedId = this.foldersTreeStruct.selectedFolderId
		let result = []
		if (selectedId) {
			result = this.allInstancesTree.get(selectedId.toString())
			let level = result || []
			while (level.length) {
				let newLevel = []
				level.forEach((node) => {
					if (this.allInstancesTree.has(node)) {
						let children = this.allInstancesTree.get(node)
						newLevel = newLevel.concat(children)
					}
				})
				level = newLevel
				result = result.concat(newLevel)
			}
		}
		return result
	}

}
