import MultilingualString from '../common/models/multilingualString.js';
import MetaObjectTreeViewKind from '../common/enums/metaObjectTreeViewKind.js';
import Constants from '../common/models/constants'
import { fillResourceValues } from '../common/service/stringResourceService'

export default {
	main: {
		render: () => {
			$('.logo-mini > .navbar-brand').attr('href', app.urls.home);
			$('.logo > .logo-lg > .navbar-brand').attr('href', app.urls.home);
			if (!app.currentUser) {
				$('.user-menu').hide();
				$('.user-login').show();
				$('.user-login-a').attr('href', app.urls.login);
			} else {
				$('.user-menu').show();
				$('.user-login').hide();
				$('.user-menu .user-name').html(`
					<img src="${app.urls.userIcon}" class="user-image" alt="User Image">
					<span class="hidden-xs">${MultilingualString.toHTML(app.currentUser.firstName) +
							' ' + MultilingualString.toHTML(app.currentUser.secondName)}</span>
				`);
				$('.user-menu .user-header').html(`
					<img src="${app.urls.userIcon}" class="img-circle" alt="User Image">
					<p>${MultilingualString.toHTML(app.currentUser.firstName) +
							' ' + MultilingualString.toHTML(app.currentUser.secondName)}</p>
				`);
			}
			$('.configuration-update').attr('href', app.urls.update(Constants.ID_TYPE_CONFIGURATION));
			$('.system-menu-item.appUsers > a').attr('href', app.urls.open(Constants.ID_TYPE_APP_USER_ACCOUNT));
		}
	},
	clientFormTemplate: $el => ({
		render: (html) => {
			let formsContainer = $el.find('.forms-container')
			if (formsContainer.length == 0) formsContainer = $el
			formsContainer.html('<div>' + html + '</div>');
			fillResourceValues($el.find('.forms-container'));
			if (!app.builderMode) {
				$el.find('.change-read-only').remove();
			}
			$el.find('.confirm.remove')
				.attr('data-text', app.getResource('action.delete.items.question'))
				.attr('title', app.getResource('delete'));
		}
	}),
	indexTemplate: op => ({
		render: (html) => {
				const $el = op && op.$el
				$el.find('.forms-container').html('<div>' + html + '</div>');
				$el.find('.caption').html(op.entityTypeName);
				if (!op.hasMetaObject) {
					$el.find('table-index-table_remove').parent().remove();
				}
				if (!op.hasMetaObject || op.treeViewKind == MetaObjectTreeViewKind.NOT_USED) {
					$el.find('.folders-switcher').remove();
					$el.find('.folders-col').remove();
					$el.find('.table-col').addClass('slide-content col-md-12')
				} else {
					$el.find('.table-col').addClass('slide-content col-md-10')
				}
				if (op.canViewHistory != null && !op.canViewHistory) {
					$el.find('.show-deleted').remove();
				}
				if (!op.hasMetaObject || !(op.canCreate == null || op.canCreate)) {
					$el.find('.table-index-table_add').remove();
				}
				if (op.hideSelection) {
					$el.find('.table-index-table_selected-dropdown').remove();
				}
				if (!op.isDocuments) {
					$el.find('.table-index-table_applyMany').parent().remove();
					$el.find('.table-index-table_relieveMany').parent().remove();
				}
				if (op.hideContextMenu) {
					$el.find('.context-menu-dropdown').remove();
				}
				if (!op.showConfirmSelect) {
					$el.find('.table-index-table_confirm-select').remove();
				}
		}
	})
};
