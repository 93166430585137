import utils from '../../common/components/utils.js';
import MultilingualString from '../../common/models/multilingualString.js';
import FieldInput from './fieldInput.js';
import multilingual from './multilingual.js';
import Formatter from '../../common/components/formatter';
import {calcFixedTopOffset} from '../../common/components/utils.js';
import Suggestions from './suggestions';

class MultilingualInputField extends Backbone.View {

	constructor (options) {
		super({
			el: options.el,
			events: {
				keyup: '_onKeyUp',
				change: '_onChange'
			}
		})
		this.model = options.model
		this.modelAttr = options.modelAttr
		this.parentField = options.parentField;
		this.context = options.context;

		this.msModel = options.model.get(options.modelAttr);
		if (!(this.msModel instanceof MultilingualString)) {
			this.msModel = new MultilingualString(this.msModel.toJSON());
		}

		if (this.$el.attr('data-is-string-multilingual') === 'short') {
			this.msType = 'short';
		}
		if (this.$el.attr('data-is-string-multilingual') === 'long') {
			this.msType = 'long';
		}

		if (this.$el.attr('disabled') === 'disabled') {
			this.disabled = true;
		}
		this._initializeOptions()

		if (this.msType) {
			this._buildMSDOM();
		}

		this._bindingOn();

		if (this.msType) {
			this._initializeWidgetEvents();
		}

		this.listenTo(this.msModel, 'change', () => this._updatePlaceholder());

		this.listenTo(this.model, 'change:' + this.modelAttr, () => {

			this.stopListening(this.msModel)
			this.msModel = this.model.get(this.modelAttr)
			this.listenTo(this.msModel, 'change', () => this._updatePlaceholder());
			this._bindingOff();
			this._bindingOn();
			this.render();

		});
		if (this.disabled){
			this.button && this.button.attr('disabled', 'disabled');
		}
		new Suggestions(
			this.$el,
			this.$input.setDataToModel.bind(this.$input),
			this.modelAttr,
			this.model,
			this.parentField,
			this.context);
	}

	setDataToModel (data) {
		if (this.model && this.model.get(this.modelAttr) !== data) {
			this.model.set(this.modelAttr, data);
		}
	}

	render () {
		this.$input.render();
		_.each(this.translationInputs, function (input) {
			input.render();
		});
		this._updatePlaceholder();
	}

	focus () {
		this.$el.focus();
	}
	setValue () {
		if (this.readOnlyText) {

			const primitiveType = utils.getPrimitiveType(this.$el);
			const formatterId = this.$el.attr('data-formatter-id');
			const typeId = this.$el.attr('data-entity-type-id');

			let html = Formatter.format(this.msModel, {
				formatterId: formatterId,
				typeId: typeId,
				primitiveType: primitiveType
			});

			if (this.msModel.isBlank()) {
				html = '-';
			}
			this.$textEl.html(html);
		}
	}

	getValue() {
		return this.msModel.toJSON();
	}

	changed () {
		setTimeout(() => this.model.trigger('change',this.model,{}), 0)
	}

	isDisabledInFormBuilder () {
		return this.disabled
	}

	enable () {
		this.$el.removeAttr('disabled');
		if (this.msType) {
			this.button && this.button.removeAttr('disabled');
		}
		if (this.readOnlyText) {
			this.$textEl.addClass('hidden');
			if (this.msType) {
				this.$el.parent().removeClass('hidden');
			} else {
				this.$el.removeClass('hidden');
			}
			this.button && this.button.removeClass('hidden');
		}
	}

	disable () {
		this.$el.attr('disabled', 'disabled');
		if (this.msType) {
			this.button && this.button.attr('disabled', 'disabled');
		}
		if (this.readOnlyText) {
			this.$textEl.removeClass('hidden');
			if (this.msType) {
				this.$el.parent().addClass('hidden');
			} else {
				this.$el.addClass('hidden');
			}
			this.button && this.button.addClass('hidden');
		}
	}

	destroy () {
		this._bindingOff();
		this.undelegateEvents();
		this.msWidget.remove();
	}

	reset () {
		if (this.$el) {
			this._hideDropdown();
		}
		this.$input.reset();
		_.each(this.translationInputs, i => i.reset());
		this.changed();
	}

	/* Events */

	_onFocus (e) {
		if (this.isTranslator && !this.focusAfterClose) {
			this._showDropdown();
		}
		this.focusAfterClose = false;
	}

	_onChange(e) {
		this.changed();
	}

	_onKeyUp(e) {
		this._updatePlaceholder();
	}

	_onKeyDown (e) {
		if (e.which == 38) {
			var input = $('input').eq($('input').index($(e.currentTarget)) - 1);
			if ($.contains(this.dropdown[0], input[0])) {
				input.focus();
			}
		} else if (e.which == 40) {
			var input = $('input').eq($('input').index($(e.currentTarget)) + 1);
			if ($.contains(this.dropdown[0], input[0])) {
				input.focus();
			}
		} else if (e.which == 27) {
      this.focusAfterClose = true
      this._hideDropdown()
		} else if (e.which == 13) {
			if (e.altKey || e.ctrlKey){
        this.focusAfterClose = true
        this._toggleDropdown()
			}
		}
	}

	_onShownDropDown () {

		let width = this.msWidget.width();

		 var diffWidth = $(window).width() - width - Math.max(this.msWidget.offset().left, 0); - $(window).scrollLeft();
		let scrollTop = 0;
		if (this.$table().length) {
			scrollTop = $(window).scrollTop() + this.$table().scrollTop()
		}
		if (this.$propertiesBar().length) {
			scrollTop = $(window).scrollTop() + this.$propertiesBar().scrollTop()
		}
		this.dropdown.css('width', width);
		this.dropdown.css('top', -5 + calcFixedTopOffset(scrollTop, this.$input.$el.offset().top, this.dropdown.height(), this.msWidget.height()) + 'px')
		this.dropdown.css('left', diffWidth<0? 0 - width +this.msWidget.width() +'px' : 0)
		this.dropdown.css('display', 'block');

		setTimeout(() => {
			this.dropdown.find(`.lang-tab a[data-locale=${this._getCurrentLanguage()}]`)
					.first().tab('show');
			this.dropdown
				.find(`.box-body input[data-locale=${this._getCurrentLanguage()}],
						.box-body textarea[data-locale=${this._getCurrentLanguage()}]`)
				.first().focus();
		});
	}

	_onHideDropDown () {
		this.dropdown.hide();
		this.msWidget.find('mscontainer').append(this.dropdown);
    if (this.focusAfterClose) {
      this.$el.focus()
    }
		this._updatePlaceholder();
	}

	/* Initialize */

	_bindingOn() {
		if (!this.msType) {
			this.$input = new FieldInput({
				el: this.el,
				model: this.model.get(this.modelAttr),
				modelAttr: 'value',
				isHTML: false,
				manualChangeTrigger: () => {
					this.model.trigger('manualChange:' +  this.modelAttr, this.model)
				}
			})
		} else {
			this.$input = new FieldInput({
				el: this.el,
				model: this.model.get(this.modelAttr).get('translations'),
				modelAttr: this._getCurrentLanguage(),
				isHTML: false,
				manualChangeTrigger: () => {
					this.model.trigger('manualChange:' +  this.modelAttr, this.model)
				}
			})
		}
		if (this.msType) {
			this._initializeTranslationInputs();
		}
	}

	_bindingOff () {
		this.$input.undelegateEvents();
		_.each(this.translationInputs, v => {
			v.$el.off('change');
			v.undelegateEvents();
		})
	}

	_initializeOptions () {
		this.isTranslator = JSON.parse(localStorage.getItem('is-translator'));
		this.readOnlyText = this.$el.attr('data-read-only-text') == 'true';
		this.$textEl = this.$el.closest('.form-group').find('.read-only-text');
		this.showDefaultValue = this.$el.attr('data-show-default-value') == 'true';
		this.placeholder = this.$el.attr('placeholder')
	}

	_initializeTranslationInputs () {
		var that = this;
		this.translationInputs = {};
		this.dropdown
			.find('.box-body input[data-locale], .box-body textarea[data-locale]')
			.each(function (i, el) {
				var modelAttr = $(el).attr('data-locale');
				that.translationInputs[modelAttr] = new FieldInput({
					el: el,
					model: that.model.get(that.modelAttr).get('translations'),
					modelAttr: modelAttr,
					isHTML: false,
					manualChangeTrigger: () => {
						that.model.trigger('manualChange:' +  that.modelAttr, that.model)
					}
				});
				that.translationInputs[modelAttr].$el.on('change', () => that.$el.trigger('change'));
			});
	}

	$propertiesBar () {
		return this.$el.closest("#properties.main-component")
	}

	$table () {
    return this.$el.closest('.cjg-table').find('.virtual-scroller')
	}

	$jstree () {
		return this.$el.closest('.jstree-anchor')
	}

	_containerPosition () {
		return this.$table().length||this.$propertiesBar().length||this.$jstree().length ? 'fixed' : 'absolute'
	}

	_buildMSDOM () {
		var that = this;

		if (this.msType) {
			this.msWidget = $(`<div class="input-group-button-inside dropdown ${this.msModel.cid}"></div>`);

			this.buttonDiv = $('<div class="input-button">');
			this.button =
				$(`<button class="btn btn-link soft-button dropdown-toggle" data-target=".${this.msModel.cid}"
					tabindex="-1" data-toggle="dropdown" aria-expanded="true">
					<span class="glyphicon glyphicon-globe"></span>
				</button>`);
			this.tagSmall = $(`<small class="lang-tag"></small>`);
			this.buttonDiv.append(this.tagSmall);
			this.buttonDiv.append(this.button);

			this.dropdown = $(`<ul class="dropdown-menu dropdown-menu-right ms-dropdown-menu">
						<li>
							<div class="box box-widget box-nomargin">
								<div class="btn-group multilingual-btn-group">
									${this.msType === 'short' ?
										`<button type="button" class="soft-button btn btn-link"
											tabindex="-1" data-toggle="tooltip" data-html="true" data-placement="bottom"
											data-original-title="Navigate: Tab or ↓↑ <br> Save: Enter or Esc">
											<span class="fa fa-info"></span>
										</button>` : ``}
									<button type="button" class="ms-collapse soft-button btn btn-link" tabindex="-1">
										<span class="fa fa-minus"></span>
									</button>
								</div>
								<div class="box-body">
								</div>
							</div>
						</li>
					</ul>`);
			if (app.enabledLanguages.length < 2) {
				this.buttonDiv.css("display", "none");
			}
			if (this.msType === 'short') {
				this.dropdown.addClass('dropdown-menu-short');
			} else if (this.msType === 'long') {
				this.dropdown.addClass('dropdown-menu-long');
			}

			if (this.msType) {
				this.msWidget.insertAfter(this.$el);
				this.msWidget.append(this.$el);
				this.msWidget.append(this.buttonDiv || this.button);
				this.msWidget.append(`<div class='mlscontainer' style='position:${this._containerPosition()};z-index:100'> </div>`)
				this.msWidget.find('.mlscontainer').append(this.dropdown);

				this.dropdown.find('.box-body').append($(multilingual(this.msType)));
			}

			this.msWidget.find('a[data-locale]').each(function (i, a) {
				$(a).attr('href', $(a).attr('href') + that.msModel.cid);
				$(a).on('click', (e) => {
					e.preventDefault()
				})
			});
			this.msWidget.find('.tab-pane').each(function (i, tab) {
				$(tab).attr('id', $(tab).attr('id') + that.msModel.cid);
			});
		}
	}

	/* Popup window */

	_initializeWidgetEvents () {
		var that = this;

		that.dropdown.find('.lang-tab a').on('shown.bs.tab', function (e) {
			that.dropdown
				.find(`.box-body textarea[data-locale=${$(e.target).attr('data-locale')}]`)
				.focus();
		});

		this.dropdown.find('[data-toggle="tooltip"]').tooltip();
		this.dropdown.click(function (e) {
			if (!$(e.target).hasClass('ms-collapse') &&
					!$(e.target).parent().hasClass('ms-collapse')) {
				e.stopPropagation();
				if ($(e.target).parents('.note-btn-group').length) {
					$(e.target).parents('.note-btn-group').find('.dropdown-menu').toggle();
				}
			}
		});
		this.dropdown.find('.lang-tab').click(function (e) {
			$(e.currentTarget).find('a').tab('show');
		});

		this.msWidget.on('shown.bs.dropdown', function () {
			that._onShownDropDown();
		});

		this.msWidget.on('hide.bs.dropdown', function () {
			that._onHideDropDown();
		});

		this.dropdown.find('input, textarea').keydown(function (e) {
			that._onKeyDown(e);
		});

		this.$el.focus(function (e) {
			that._onFocus(e);
		});

		this.$el.keydown(function (e) {
			that._onKeyDown(e);
		});
	}

	_showDropdown () {
		if (this.msWidget && !this.msWidget.hasClass('open')) {
			this.button.dropdown('toggle');
		}
	}

	_hideDropdown () {
		if (this.msWidget && this.msWidget.hasClass('open')) {
			this.button.dropdown('toggle');
		}
	}

	_toggleDropdown () {
		this.button && this.button.dropdown('toggle');
	}

	/* Misc */

	_getCurrentLanguage () {
		return app.currentLanguage;
	}

	_getPlaceholder () {
		const that = this;
		if (this.msType) {
			let result = '';
			_.some(app.enabledLanguages, function (lang) {
				if (that._getCurrentLanguage() != lang && that.msModel.getTranslation(lang)) {
					result = that.msModel.getTranslation(lang);
					return true;
				}
			});
			if (!result && this.showDefaultValue) {
				result = this.msModel.getValue();
			}
			return result;
		}
	}

	_updatePlaceholder () {
		this.setValue();
		if (!this.placeholder){
			this.$el.attr('placeholder', this._getPlaceholder() || '');
			if (this.msType) {
				if (!this.$input.getValue() && this.msModel.getFirstTranslation(false).value) {
					this.tagSmall.text(this.msModel.getFirstTranslation(false).lang);
					this.tagSmall.show();
				} else {
					this.tagSmall.hide();
				}
			}
		}
	}

	prepareToSave () {
		this.$input.prepareToSave();
	}
}

export default MultilingualInputField;
