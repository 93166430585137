import NavigationView from './views/navigationView.js';



class NavigationPresenter {

  constructor() {}



  present(options) {
    const el = options.el, hideLoading = options.hideLoading;
    el.parent().parent().addClass('navigation-tab');
    app.getAllDataPromise.then(() => {
      let navigationView = new NavigationView();
      navigationView.render();
    });
    hideLoading();
  }
};

export default new NavigationPresenter();
