/* @flow */
import  utils from '../components/utils';
import { Service } from './service';

class MetaObjectService extends Service {

  prefix(){
    return 'metaObject';
  }

  move(item){
    return utils.postRequest(item, app.urls.home + this.prefix() + '/move');
  }

}

export let metaObjectService = new MetaObjectService();
