import IndexTable from '../components/indexTable';
import FoldersTree from '../components/foldersTree';
import utils from '../../common/components/utils';
import StateRecovery from '../../common/components/stateRecovery';
import LocalStorageKeysService from '../../common/service/localStorageKeysService'

var IndexView = Backbone.View.extend({

	events: {
		'click .folders-switcher': 'doToggleFolders'
	},

	initialize: function (options) {
		this.setElement(options.el)
		var that = this;
		this.total_width = this.$el.width();
		this.prevRatio = 2;
		this.table = new IndexTable({
			el: this.$('.table-index-table')[0],
			isDocuments: options.isDocuments,
			hasMetaObject: options.hasMetaObject,
			treeViewKind: options.treeViewKind,
			hideCheckbox: options.hideCheckbox,
			typeId: options.typeId,
			viewId: options.viewId,
			context: options.context,
			onConfirmSelect: options.onConfirmSelect
		});
		this.initialFilters = options.filters;
		this.selected = options.selected;
		this.typeId = options.typeId
		if (this.$('.folders-tree').length) {
			this.folders = new FoldersTree({
				el: this.$('.folders-tree'),
				captionEl: this.$('.caption'),
				table: this.table.foldersTreeStruct,
				addInstance: () => this.table.addInstance(),
				showInstance: (typeId, rowId) => this.table.showInstance(typeId, rowId),
				treeViewKind: options.treeViewKind,
				typeId: options.typeId,
				addNewButton: $('table_index_table_add'),
				addNewInContextMenu: true,
				dndEnabled: true,
				statefull: true,
				showRoot: true
			});
		}
		this.table.setTree(this.folders);
		this.foldersClosed = StateRecovery.get(LocalStorageKeysService.buildKeyForFoldersTree(this.typeId));
		if (this.foldersClosed) { // opened by default, must close
			this.toggleFoldersNoAnimation();
		}
		this.$('.folders-col').resizable({
			helper: 'js-tree-resizable-helper',
			animate: false,
			handles: 'e',
			stop: function( event, ui ) {
				let total = $('.content-wrapper').width();
				let w = ui.size.width + 50;
				if (w - 0.5 > total){
					w = total - 0.5;
				}
				if (w < total / 12 + 0.5){
					w = total / 12 + 0.5;
				}
				let ratio = Math.floor(12*w/total);
				that.applyRatio(ratio);
			},
			resize: function(e, ui){
				ui.size.height = Math.round( ui.size.height / 30 ) * 30;
			}
		});
		this.themeUrl = options.context && options.context.themeUrl;
		options.context.$el.find('[data-server_event="LIST_LINK"]').click(function () {
			const tab = app.cjTabs.activeTab
			if (tab.parent) {
				tab.close()
			} else {
				if (app.builderMode){
					utils.redirectTo(app.urls.entityTypeDetails(app.typeId, app.configuration.id, 'ENTITY_TYPE'))
				} else {
					utils.redirectTo('/')
				}
			}
		});
	},

	async initializeAsyncComponents () {
		await this.table.initializeTableAsync()
		if (this.initialFilters) {
			this.table.filter(this.initialFilters, true);
		}
		if (this.selected) {
			this.selected.forEach(x => {
				this.table.newTable.checkOne({item: x, value: true});
			});
		}
	},

	applyRatio: function(ratio){
		this.$('.folders-col').css('width', '');
		this.$('.folders-col').removeClass('col-md-' + this.prevRatio).addClass('col-md-' + ratio);
		this.$('.table-col').removeClass('col-md-' + (12-this.prevRatio)).addClass('col-md-' + (12-ratio));
		this.prevRatio = ratio;
	},

	doToggleFolders: function () {
		this.toggleFolders();
	},

	updateTheme: function () {
		let previousUrl = $('#cssTheme').attr('href')
		if (this.themeUrl !== previousUrl) {
			if (this.themeUrl) {
				$('#cssTheme').attr('href', this.themeUrl);
			} else {
				$('#cssTheme').removeAttr('href');
			}
		}
	},

	toggleFolders: function (invisible) {
		this.$('.folders-switcher .folder-icon').toggleClass('fa-folder fa-folder-o');
		this.$('.folders-col').toggleClass('col-0 col-md-' + this.prevRatio);
		this.$('.table-col').toggleClass('col-md-12 col-md-' + (12 - this.prevRatio));
		if (!invisible) {
			this.foldersClosed = !this.foldersClosed;
			StateRecovery.put(LocalStorageKeysService.buildKeyForFoldersTree(this.typeId), this.foldersClosed);
		}
	},

	toggleFoldersNoAnimation: function () {
		this.$('.folders-col').toggleClass('slide-content no-slide-content');
		this.$('.table-col').toggleClass('slide-content no-slide-content');
		this.toggleFolders(true);
		this.$('.folders-col').toggleClass('slide-content no-slide-content');
		this.$('.table-col').toggleClass('slide-content no-slide-content');

	},

	render () {
		this.updateTheme();
		this.table.render();
		this.folders && this.folders.render();
	}
});

export default IndexView;
