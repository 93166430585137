import Constants from '../models/constants';
import urlService from '../components/urlService';

const HOME_LINK = app.urls.home;

const openPath={
  [Constants.ID_TYPE_TYPE]:"entityType/details",
  [Constants.ID_TYPE_FIELD]:"entityField/update",
  [Constants.ID_TYPE_USER_ROLE]:"appUserAccount#",
  [Constants.ID_TYPE_TASK]:"entityTask",
  [Constants.ID_TYPE_APP_USER_ACCOUNT]:"appUserAccount#",
  [Constants.ID_TYPE_BLOCK]:"entityBlock/construct",
  [Constants.ID_TYPE_CATEGORY]:"#",
  [Constants.ID_TYPE_MODULE]:"#",
  [Constants.ID_TYPE_SHARED_STYLE]:"sharedStyle/update",
  [Constants.ID_TYPE_PERMISSION]:"permission/",
  [Constants.ID_TYPE_ENTITY_MENU]:"entityMenu/",
  [Constants.ID_TYPE_ENTITY_GENERATOR]:"entityGenerator",
  [Constants.ID_TYPE_DOCUMENT_TIMELINE]:"documentTimeline#",
  [Constants.ID_TYPE_MULTILINGUAL_STRING_RESOURCE]:"stringResources#",
  [Constants.ID_TYPE_CONFIGURATION]:"/",
  [Constants.ID_TYPE_VIEW]:"entityView/edit",
  [Constants.ID_TYPE_SYSTEM_INSTANCE]:"entityType/updateInstance",
  [Constants.ID_TYPE_API_ENTRY]:"apiEntry#",
  [Constants.ID_TYPE_PRIMITIVE_FORMATTER]:"primitiveFormatter#",
  [Constants.ID_TYPE_CSS_THEME]: "themeEditor/update",
  'APPLICATIONTYPE':"entity{0}/update",
  [Constants.ID_TYPE_ENTITY_ROUTE]: "configuration/update#routes",
  [Constants.ID_TYPE_CUSTOM_LIBRARY]: "customResources"
}

const updatePath = {
  [Constants.ID_TYPE_APP_USER_ACCOUNT]: "appUserAccount/update",
  [Constants.ID_TYPE_PERMISSION]: "permission/update",
  [Constants.ID_TYPE_ENTITY_MENU]: "entityMenu/update",
  [Constants.ID_TYPE_DOCUMENT_TIMELINE]:"documentTimeline/update",
  [Constants.ID_TYPE_MULTILINGUAL_STRING_RESOURCE]: "stringResources/update",
  [Constants.ID_TYPE_CUSTOM_LIBRARY]: "customResources/update",
  [Constants.ID_TYPE_SCHEDULER]: "scheduler/update",
  [Constants.ID_TYPE_SHARED_STYLE]: "sharedStyle/update",
  [Constants.ID_TYPE_TYPE]: "entityType/update",
  [Constants.ID_TYPE_BLOCK]: "entityBlock/update",
  [Constants.ID_TYPE_FIELD]: "entityField/update",
  [Constants.ID_TYPE_API_ENTRY]: "apiEntry/update",
  [Constants.ID_TYPE_ENTITY_GENERATOR]: "entityGenerator/update",
  [Constants.ID_TYPE_MULTILINGUAL_STRING_RESOURCE]:"stringResources/update",
  [Constants.ID_TYPE_CONFIGURATION]:"configuration/update",
  [Constants.ID_TYPE_USER_ROLE]: "userRole/update",
  [Constants.ID_TYPE_REGISTER_INDEX] : "registerIndex/update",
  [Constants.ID_TYPE_PERMISSION_NETWORK_WHITELIST] : "whitelist/update",
  [Constants.ID_TYPE_PRIMITIVE_FORMATTER]:"primitiveFormatter/update",
  [Constants.ID_TYPE_MULTILINGUAL_STRING]: "multilingualString/update",
  [Constants.ID_TYPE_CSS_THEME]: "themeEditor/update"
}

const deletePath = {
  [Constants.ID_TYPE_CUSTOM_LIBRARY]: "customResources/delete",
  [Constants.ID_TYPE_BLOCK]: "entityBlock/delete",
  [Constants.ID_FIELD_BLOCK]: "entityBlock/deleteSelected",
  [Constants.ID_TYPE_USER_ROLE]: "userRole/deleteSelected",
  [Constants.ID_TYPE_SCHEDULER]: "scheduler/delete",
  [Constants.ID_TYPE_ENTITY_MENU]: "entityMenu/deleteSelected",
  [Constants.ID_TYPE_PERMISSION]: "permission/deleteSelected",
  [Constants.ID_TYPE_SHARED_STYLE]: "sharedStyle/deleteSelected",
  [Constants.ID_TYPE_DOCUMENT_TIMELINE]: "documentTimeline/deleteSelected",
  [Constants.ID_TYPE_FIELD]: "entityField/deleteSelected",
  [Constants.ID_FIELD_OWNER]: "entityField/{0}/deleteSelected",
  [Constants.ID_TYPE_ENTITY_GENERATOR]: "entityGenerator/deleteSelected",
  [Constants.ID_TYPE_API_ENTRY]:"apiEntry/deleteSelected",
  [Constants.ID_TYPE_CATEGORY]: "entityCategory/deleteSelected",
  [Constants.ID_TYPE_MODULE]: "module/deleteSelected",
  [Constants.ID_TYPE_TYPE]: "entityType/deleteSelected",
  [Constants.ID_TYPE_PERMISSION_NETWORK_WHITELIST] : "whitelist/deleteSelected",
  [Constants.ID_TYPE_PRIMITIVE_FORMATTER]: "primitiveFormatter/deleteSelected",
  [Constants.ID_TYPE_CSS_THEME]: "themeEditor/deleteSelected"
}

const createPath = {
  [Constants.ID_TYPE_PERMISSION]: "permission/create",
  [Constants.ID_TYPE_TYPE]: "entityType/create",
  [Constants.ID_TYPE_ENTITY_MENU]: "entityMenu/create",
  [Constants.ID_TYPE_DOCUMENT_TIMELINE]:"documentTimeline/create",
  [Constants.ID_TYPE_CUSTOM_LIBRARY]: "customResources/create",
  [Constants.ID_TYPE_SCHEDULER]: "scheduler/create",
  [Constants.ID_TYPE_EVENT_HANDLER]: "eventHandler/create",
  [Constants.ID_TYPE_USER_ROLE]: "userRole/create",
  [Constants.ID_TYPE_SYSTEM_INSTANCE]: "entityType/createInstance",
  [Constants.ID_TYPE_FIELD]: "entityField/create",
  [Constants.ID_TYPE_SHARED_STYLE]: "sharedStyle/create",
  [Constants.ID_TYPE_API_ENTRY]: "apiEntry/create",
  [Constants.ID_TYPE_PERMISSION_NETWORK_WHITELIST] : "whitelist/create",
  [Constants.ID_TYPE_REGISTER_INDEX] : "registerIndex/create",
  [Constants.ID_TYPE_PRIMITIVE_FORMATTER]: "primitiveFormatter/create",
  [Constants.ID_TYPE_CSS_THEME]: "themeEditor/create",
  'APPLICATIONTYPE': "entity{0}/create",
  [Constants.ID_TYPE_ENTITY_GENERATOR]: "entityGenerator/create"
}

const dependenciesPath = {
  [Constants.ID_TYPE_PERMISSION]: "permission/dependencies",
  [Constants.ID_TYPE_ENTITY_MENU]: "entityMenu/dependencies",
  [Constants.ID_TYPE_CATEGORY]: "entityCategory/dependencies",
  [Constants.ID_TYPE_MODULE]: "module/dependencies",
  [Constants.ID_TYPE_FIELD]: "entityField/dependencies",
  [Constants.ID_FIELD_OWNER]:"entityField/{0}/dependencies",
  [Constants.ID_TYPE_USER_ROLE]: "userRole/dependencies",
  [Constants.ID_TYPE_VIEW]: "entityView/dependencies",
  [Constants.ID_TYPE_BLOCK]: "entityBlock/dependencies",
  [Constants.ID_TYPE_TYPE]: "entityType/dependencies",
  [Constants.ID_TYPE_MULTILINGUAL_STRING_RESOURCE]: "stringResources/dependencies",
  [Constants.ID_TYPE_ENTITY_GENERATOR]: "entityGenerator/dependencies",
  [Constants.ID_TYPE_PERMISSION_NETWORK_WHITELIST] : "whitelist/dependencies",
  [Constants.ID_TYPE_CUSTOM_LIBRARY]: "customResources/dependencies",
  [Constants.ID_TYPE_SHARED_STYLE]: "sharedStyle/dependencies",
  [Constants.ID_TYPE_PRIMITIVE_FORMATTER]:"primitiveFormatter/dependencies",
  [Constants.ID_TYPE_CSS_THEME]: "themeEditor/dependencies"
}

function isPredefinedType (typeId) {
	return Constants.ID_SEQUENCE_OFFSET.lte(typeId) && Constants.ID_SEQUENCE_OFFSET.mul(2).gte(typeId)
}

function isPredefinedReportType (typeId) {
	return Constants.ID_TYPE_CLEANER_REPORT == typeId
}

function openLink(typeId, arg, skipHomeLink){
  let link = skipHomeLink ? '/' : HOME_LINK
  if (!isPredefinedType(typeId) || isPredefinedReportType(typeId)){
    return link + resolver(openPath, 'APPLICATIONTYPE',[typeId]) + (arg ? '/'+ arg : '')
  }
  switch(typeId) {
  case Constants.ID_TYPE_CONFIGURATION:
    return arg ? ('/' + app.currentLanguage + openPath[typeId] + arg) : HOME_LINK  + 'configuration/update'
  case Constants.ID_TYPE_PERMISSION:
  case Constants.ID_TYPE_ENTITY_MENU:
    return link + openPath[typeId] + arg
  default:
    return link + openPath[typeId] + (arg ? '/'+ arg : '')
  }
}

function deployLink(appId, updateMode, projectJarId){
  let link = HOME_LINK + 'deploy?appId=' + appId + '&updateMode=' + updateMode;

  if (projectJarId != null) {
    link = link + '&projectJarId=' + projectJarId;
  }

  return link;
}

function downloadLink(appId, updateMode, projectJarId){
  let link = HOME_LINK + 'downloadApp?appId=' + appId + '&updateMode=' + updateMode;

  if (projectJarId != null) {
    link = link + '&projectJarId=' + projectJarId;
  }

  return link;
}

function updateLink(typeId, arg, options){
  if (!isPredefinedType(typeId) || isPredefinedReportType(typeId)){
    return openUpdateInstance(typeId, arg, options ? options : {});
  }
  return HOME_LINK + updatePath[typeId] + (arg ? '/'+ arg : '')
}

function deleteLink(typeId, arg, ownerId){
  if (!isPredefinedType(typeId) || isPredefinedReportType(typeId)) {
    if (app.builderMode) {
      return HOME_LINK + 'builder/deleteSelected'
    } else {
      return HOME_LINK + `entity${typeId}/deleteSelected`
    }
  } else {
    return HOME_LINK + resolver(deletePath, typeId, [ownerId]) + (arg ? '/'+ arg : '')
  }
}

function nullifyLink(typeId, arg, ownerId){
  return HOME_LINK + `entity${typeId}/nullifyGdprSelected`
}

function createLink(typeId, formViewId, options) {
  if (!isPredefinedType(typeId) || isPredefinedReportType(typeId)) {
    return openCreateInstance(typeId, formViewId, options)
  } else {
    return HOME_LINK + createPath[typeId] + (formViewId ? '/'+ formViewId : '')
  }
}

function dependenciesLink(typeId, arg) {
  if (!isPredefinedType(typeId) || isPredefinedReportType(typeId)) {
		if (app.builderMode) {
			return HOME_LINK + 'builder/dependencies'
		} else {
			return undefined // intended return, see confirm modal js
		}
  } else {
    return HOME_LINK + resolver(dependenciesPath, typeId, [arg])
  }
}

function typeIndexPageLink(typeId, options) {
  options = options || {};
	if (app.builderMode) {
		return HOME_LINK + 'entityType/instances' + '/' + typeId + (options.rowViewId ? '?rowViewId=' + options.rowViewId : '');
	} else {
		return HOME_LINK + 'entity' + typeId + (options.rowViewId ? '?rowViewId=' + options.rowViewId : '');
	}
}

function instanceUsageLink(itemId) {
	if (app.builderMode) {
		return HOME_LINK + 'builder/instanceUsages' + '/' + itemId ;
	} else {
		throw 'Invalid operation';
	}
}

function openUpdateInstance(typeId, instanceId, options) {
  var link = '';
  if (app.builderMode) {
    link += HOME_LINK + 'entityType/updateInstance' + '/' + typeId + '/' + instanceId + (options.formViewId ? '?formViewId=' + options.formViewId : '');
  } else {
    link += HOME_LINK + 'entity' + typeId + '/update/' + instanceId + (options.formViewId ? '?formViewId=' + options.formViewId : '');
  }
  if (options.edit) {
    link += (options.formViewId) ? '&edit=true' : '?edit=true';
  }
  return link;
}

function openCreateInstance(typeId, formViewId, options) {
	if (app.builderMode) {
		return HOME_LINK + 'entityType/createInstance' + '/' + typeId +  (formViewId ? '?formViewId=' + formViewId : '');
	} else {
		let link =  HOME_LINK + 'entity' + typeId + '/create';

		let addArg = (() => {
				let args = 0;
				return (key, val) => {
					link += args ? '&' : '?'
					link += key + '=' + val;
					++args;
				}
		})();

		if (formViewId)addArg('formViewId', formViewId);
		if (options)_.each(options, (val, key) => {
			if (val)addArg(key, val);
		})
		return link;
	}
}

function entityTypeDetailsLink(typeId, configurationId, usageKind) {
	let configuration = app.urls.open(Constants.ID_TYPE_CONFIGURATION, configurationId)
	if (!typeId && usageKind == 'SHARED_FIELDS_TABLE') {
		return configuration + '/entityField'
	}
	if (usageKind == 'PERMISSION') {
		return configuration + '/permission'
	}
	if (usageKind == 'ENTITY_TYPE') {
		return configuration + '/entityType/details/' + typeId;
	}
  if (usageKind == 'ENTITY_ROUTE') {
    return configuration + '/configuration/update#routes'
  }
  if(usageKind == 'API_ENTRY') {
    return configuration + '/apiEntry'
  }
}

function usageDetailsLink(usageId, usageKind, typeId, configurationId, parentUsageKind) {
	let configuration = app.urls.open(Constants.ID_TYPE_CONFIGURATION, configurationId)
	if (usageKind == 'CONFIGURATION') {
		return configuration + '/configuration/update'
	}
	if (usageKind == 'ENTITY_VIEW') {
		return configuration + '/entityView/edit/' + typeId + '/' + usageId;
	}
	if (usageKind == 'ENTITY_BLOCK') {
		return configuration + '/entityBlock/construct/' + usageId;
	}
	if (usageKind == 'ENTITY_FIELD' || usageKind == 'SHARED_FIELD') {
		return app.urls.entityTypeDetails(typeId, configurationId, parentUsageKind);
	}
	if (usageKind == 'ENTITY_MENU') {
    if (usageId) return configuration + '/entityMenu/' + usageId
		return configuration + '/entityMenu';
	}
	if (usageKind == 'PERMISSION') {
		return configuration + '/permission';
	}
  if (usageKind == 'ENTITY_ROUTE') {
    return configuration + '/configuration/update#routes'
  }
  if(usageKind == 'API_ENTRY') {
    return configuration + '/apiEntry'
  }
  if (usageKind == 'ENTITY_TYPE') {
    return configuration + '/entityType/details/' + usageId;
  }
}

function taskRethrowLink(taskId){
  return HOME_LINK + 'taskmanager/rethrow/' + taskId
}

function websocketTopicLink(confId){
  return '/topic/buildstatus/' + confId
}

function htmlByMenuIdLink(menuId){
  return HOME_LINK + 'entityMenu/htmlByMenuId/' + menuId
}

function apiEntryByIdLink(entryId){
  return HOME_LINK + 'apiEntry/findApiEntry/' + entryId
}

function blockConstructLink(blockId){
  return HOME_LINK + 'entityBlock/construct/' + blockId
}

function builtCodeLink(blockId){
  return HOME_LINK + 'entityBlock/code/' + blockId
}

function createAndAddToRowViewLink(ownerId, viewId){
  return HOME_LINK + 'entityField/createAndAddToRowView/' + ownerId + (viewId ? ('/' + viewId) : '')
}

function openDynamicInstanceLink(id){
  return HOME_LINK + 'open/' + id
}

function hubLoginLink() {
  return '/' + app.currentLanguage + '/hub-login';
}

function resolver(array, type, args) {
    let formatted = array[type] || '';
    for (var i = 0; i < args.length; i++) {
      var regexp = new RegExp('\\{'+i+'\\}', 'gi');
      formatted = formatted.replace(regexp, args[i]);
    }
    return formatted;
}

function getJournalRecordsCount (documentId) {
	return HOME_LINK + 'documentTimeline/getJournalRecordsCount/' + documentId
}

function getJournalRecords (documentId, journalTypeId) {
	return HOME_LINK + 'documentTimeline/getJournalRecords/' + documentId + '/' + journalTypeId
}

function getJournalRecordsPage (documentId, journalTypeId) {
	return HOME_LINK + 'documentTimeline/getJournalRecordsPage/' + documentId + '/' + journalTypeId
}

function getApplicationVersionListLink(appId) {
  return HOME_LINK + 'getApplicationJars?appId=' + appId
}

function usePublish(state){
  return HOME_LINK + 'builder/switchpublish?m=' + state
}

function editView(viewId, ownerId) {
  return HOME_LINK + 'entityView/edit/' + ownerId + '/' + viewId;
}

function deleteView(viewId, ownerId) {
  return HOME_LINK + 'entityView/delete/' + ownerId + '/' + viewId;
}

function editViewProperties(viewId, ownerId) {
  return HOME_LINK + 'entityView/formProperties/' + viewId;
}

function createView(ownerId) {
  return HOME_LINK + 'entityView/create/' + ownerId;
}

function rowClickLink(typeId, fieldId, rowClickedId) {
  return HOME_LINK + 'entity' + typeId + '/rowclick/' + fieldId + '/' + rowClickedId
}

function createFieldFromPreset(ownerId, options) {
  let link = HOME_LINK + 'entityField/createFromPreset/' + ownerId;
  let args = 0;
  if (options.addToRowView) {
    link += '?addToRowView=true';
    args++;
  }
  if (options.view) {
    link += args ? '&' : '?';
    link += 'viewId=' + options.view.id;
    args++;
  }
  if (options.generateEnum) {
    link += args ? '&' : '?';
    link += 'generateEnum=true';
    args++;
  }
  if (options.isEmbedded) {
    link += args ? '&' : '?';
    link += 'isEmbedded=true';
  }
  return link;
}

function data(typeId, objectId, options){
  let viewId = options && options.viewId;
  let url;
  const params = urlService.getAllParameters();
  if (objectId) {
    url = app.builderMode
      ? HOME_LINK + 'metaObject/' + typeId + '/open/' + objectId
      : HOME_LINK + 'entity' + typeId + '/open' + (viewId ? ('/' + viewId) : '') + '/' + objectId;
  } else {
    url = app.builderMode
      ? HOME_LINK + 'metaObject/' + typeId + '/onCreate/'
      : HOME_LINK + 'entity' + typeId + '/onCreate/';
    params.formViewId = viewId;
    params.fillFunctionId = options && options.fillFunctionId;
    params.fillInstanceId = options && options.fillInstanceId;
  }
  const paramsStr = _.chain(params)
    .pick(_.identity)
    .map((v, k) => `${k}=${v}`)
    .join('&')
    .value();
  if (paramsStr) {
    url += '?' + paramsStr;
  }
  return url;
}

function presentationInfo(typeId, viewId, isIndex) {
  let url = app.builderMode
    ? HOME_LINK + 'entityType/presentationInfo/' + typeId
    : HOME_LINK + 'entity' + typeId + '/presentationInfo';
  url += "?v=" + app.info.buildNumber;
  if (viewId) {
    url += '&viewId=' + viewId;
  }
  if (isIndex) {
    url += '&isIndex=true'
  }
  return url;
}

function customEvent(typeId, blockId) {
  return HOME_LINK + 'entity' + typeId + '/invokeCustomEvent/' + blockId;
}

function change(typeId, fieldId, subFieldId, relativeOrder, userAction) {
  let subField = !subFieldId ? '' : 'subFieldId=' + subFieldId.toString() + '&';
  let relativeOrderParameter = relativeOrder === null ? '' : 'relativeOrder=' + relativeOrder.toString();
  return HOME_LINK + `entity${typeId}/change/${fieldId}/?userAction=${userAction}&${subField}${relativeOrderParameter}`;
}

function suggest(typeId, fieldId, subFieldId, relativeOrder) {
  let subField = !subFieldId ? '' : 'subFieldId=' + subFieldId.toString() + '&';
  let relativeOrderParameter = relativeOrder === null ? '' : 'relativeOrder=' + relativeOrder.toString();
  return HOME_LINK + `entity${typeId}/suggestValues/${fieldId}/?${subField}${relativeOrderParameter}`;
}

function formLoad(typeId, viewId) {
  return HOME_LINK + 'entity' + typeId + '/formLoad/' + viewId;
}

function history(typeId, objectId) {
  return HOME_LINK + 'entity' + typeId + '/getHistory/' + objectId;
}

function dashboardAliases(builderId) {
  return "https://apps.codejig.com/" + app.currentLanguage + "/aliases?builder=" + builderId
}

function openEntitySpreadsheet(typeId, viewId) {
  return HOME_LINK + 'entity' + typeId + '/getSpreadsheet' + (viewId ? '?viewId=' + viewId : '')
}

function openEntityXml(typeId, viewId, skipValidation) {
  return HOME_LINK + 'entity' + typeId + '/getXml' + (viewId ? '?viewId=' + viewId : '') + (skipValidation ? (viewId ? '&' : '?') + 'skipValidation=true' : '')
}

function openEntityDoc(typeId, viewId, skipValidation) {
  return HOME_LINK + 'entity' + typeId + '/getDoc' + (viewId ? '?viewId=' + viewId : '') + (skipValidation ? (viewId ? '&' : '?') + 'skipValidation=true' : '')
}

function openEntityPdf(typeId, viewId, skipValidation) {
  return HOME_LINK + 'entity' + typeId + '/getPdf' + (viewId ? '?viewId=' + viewId : '') + (skipValidation ? (viewId ? '&' : '?') + 'skipValidation=true' : '')
}

function buildStringView(typeId, viewId, languageId) {
  return HOME_LINK + 'entity' + typeId + '/buildStringView' + (viewId ? '?viewId=' + viewId : '') + (languageId ? (viewId ? '&' : '?') + 'languageId='+languageId : '')
}

function tasksByTarget(instanceId) {
  return HOME_LINK + 'entityTask/findByTarget/' + instanceId
}

function find(typeId, instanceId) {
  return HOME_LINK + 'entity' + typeId + '/find/' + instanceId + '/';
}

function buildReport(typeId) {
  return HOME_LINK + 'entity' + typeId + '/buildReport';
}

function runTestBlock(blockId) {
  return HOME_LINK + 'tests/' + blockId + '/run';
}

function reorder(entity) {
  return HOME_LINK + entity + '/reorder'
}

function checkPermissionLink(typeId){
  return  HOME_LINK + 'entity' + typeId + '/checkPermission'
}

function buildLink(devMode) {
	let url =  HOME_LINK + 'build';
	if (devMode) {
		url += '?devMode=true';
	}
	return url;
}

function exportReportLink(typeId) {
  return HOME_LINK + 'entity' + typeId + '/exportReport';
}

function addView(typeId) {
  return HOME_LINK + 'entityView/create/' + typeId;
}

function addBlock(typeId) {
  return HOME_LINK + 'entityBlock/create/' + typeId;
}

function addFieldFunction(typeId) {
  return HOME_LINK + 'entityBlock/createFillFunction/' + typeId;
}

function addField(typeId) {
  return HOME_LINK + 'entityField/create/' + typeId;
}

function dataTypesPage(params) {
  let paramsStr = ''
  params.forEach((p) => {
    if (paramsStr.length) {
      paramsStr += `&${p}`
    } else {
      paramsStr += `?${p}`
    }
  })
  return HOME_LINK + 'entityType' + paramsStr
}

export let  urls = {
  update: updateLink,
  delete: deleteLink,
  nullify: nullifyLink,
  create: createLink,
  deploy: deployLink,
  build: buildLink,
  changePassword: HOME_LINK + 'changePassword',
  usePublish: usePublish,
  publish: HOME_LINK + 'publish',
  getApps: HOME_LINK + 'getApps',
  getCategories: HOME_LINK + 'entityCategory',
  import: HOME_LINK + 'import',
  export: HOME_LINK + 'export',
  generators: HOME_LINK + 'entityGenerator',
  formatters: HOME_LINK + 'primitiveFormatter',
  uploadFile: HOME_LINK + 'internalFile/',
  serverStatus: HOME_LINK + 'serverStatus',
  findResource: HOME_LINK + 'stringResources/find',
  findResourceByKey: HOME_LINK + 'stringResources/findByKey',
  findManyStringViews: HOME_LINK + 'findManyStringViews',
  systemInstancesForDeploy: HOME_LINK + 'systemInstancesForDeploy',
  createWebPage: HOME_LINK + 'entityType/createDirectoryWithWebPage',
  entity: HOME_LINK + 'entity',
  indexTemplate: HOME_LINK + 'entityView/getIndexView?v=' + app.info.buildNumber,
  getApplicationVersionList: getApplicationVersionListLink,
	openDynamicInstance: openDynamicInstanceLink,
	getJournalRecordsCount,
	getJournalRecords,
	getJournalRecordsPage,
  createFieldFromPreset,
  createAndAddToRowViewLink,
  dashboardAliases,
  openEntitySpreadsheet,
  openEntityXml,
  openEntityDoc,
  openEntityPdf,
  tasksByTarget,
  find,
  buildReport,
  task: {
    cancel: HOME_LINK + 'taskmanager/cancel',
    rethrow: taskRethrowLink
  },
  websocket: {
    taskmanager: "/taskmanager",
    topic: websocketTopicLink
  },
  blockConstruct: blockConstructLink,
  builtCode: builtCodeLink,
  htmlByMenuId: htmlByMenuIdLink,
  apiEntryById: apiEntryByIdLink,
  entityTypeDetails: entityTypeDetailsLink,
  usageDetails: usageDetailsLink,
  dependencies: dependenciesLink,
  open: openLink,
  hubLogin: hubLoginLink,
  indexPage: typeIndexPageLink,
  instanceUsages: instanceUsageLink,
  editView: editView,
  createView: createView,
  data: data,
  presentationInfo,
  invoke: {
    customEvent,
    change,
    formLoad,
    rowClick: rowClickLink,
    suggest
  },
  history,
  editViewProperties: editViewProperties,
  deleteView: deleteView,
  runTestBlock,
  reorder: reorder,
  checkPermission: checkPermissionLink,
  exportReport: exportReportLink,
  downloadApp: downloadLink,
  getAllData: HOME_LINK + 'entityType/getAllData',
  getBlocks: HOME_LINK + 'entityBlock/getBlocks',
  getViews: HOME_LINK + 'entityView/getViews',
  getTypes: HOME_LINK + 'entityType/getTypes',
  getFields: HOME_LINK + 'entityField/getFields',
  getAllTypesAndCategories: HOME_LINK + 'entityType/getAllTypesAndCategories',
  addView: addView,
  addBlock: addBlock,
  addFieldFunction: addFieldFunction,
  addField: addField,
  updateRoute: HOME_LINK + 'configuration/routeUpdate',
  openRoutesInAdvancedMode: HOME_LINK + 'configuration/update?RoutesAdvancedMode#routes',
  recentlyEdited: HOME_LINK + 'recentlyEdited/',
  dataTypes: dataTypesPage,
  buildStringView: buildStringView
}
