import Pairs from '../collections/pairs';

export default class Suggestions {

	constructor(input, selectCallback, modelAttr, model, parentField, context, $el) {
		this.$input = $(input);
		this.selectCallback = selectCallback;
		this.modelAttr = modelAttr;
		this.model = model;
		this.parentField = parentField;
		this.context = context;
		this.constructDOM(input, $el);

		$(input).on('focus', this.onInputFocus.bind(this))
				.on('blur', this.onInputBlur.bind(this))
				.on('input', this.onInputChange.bind(this));
		this.$ul.on('mousedown', (e) => e.preventDefault())
				.on('click', 'li', this.onSelect.bind(this));
	}

	async getSuggestions() {
		const suggestions = new Pairs();
		if (app.userObservers && this.context) {
			const key = (this.parentField && this.parentField + '.' + this.modelAttr) || this.modelAttr;
			const func = app.userObservers.getSuggestFunction(this.context.type.id, key);
			if (func) {
				await app.tasksQueue.add(async () => {
					await func.call(this.context, suggestions, this.context.model, this.model);
				});
			}
		}
		return suggestions;
	}

	constructDOM(input, $el) {
		this.$ul = $('<ul/>')
				.addClass('suggestions hidden')
		if ($el) {
			$el.append(this.$ul);
		} else {
			this.$ul.insertAfter(input);
		}

	}

	onInputFocus() {
		this.onInputChange();
	}

	onInputChange() {
		if (this.isInputFocused()) {
			this.inputValue = this.$input.val();
			this.open();
		}
	}

	isInputFocused() {
		const input = this.$input[0];
		return input === document.activeElement && ( input.type || input.href );
	}

	async getOptions() {
		if (this.lastFetchTime) {
			const diff = new Date() - this.lastFetchTime;
			if (diff < 500) {
				return;
			}
		}
		const data = await this.getSuggestions();
		this.lastFetchTime = new Date();
		data.forEach((d, ind) => {
			d.set('index', ind);
		});
		this.data = data;
	}

	onInputBlur() {
		this.close();
	}

	onSelect(e) {
		let li = e.target;
		while (li && li.nodeName.toLowerCase() !== 'li') {
			li = li.parentNode;
		}
		if (li) {
			this.close();
			const index = $(li).data('index');
			const value = this.data.at(index).one();
			this.selectCallback(value instanceof Backbone.Model ? value.get('value') : value);
		}
	}

	buildOptions() {
		let data = this.data.where(d => d.two() && d.two().get('value') &&
		d.two().get('value').startsWith(this.inputValue));
		this.$ul.empty();
		data.forEach((item) => {
			const $li = $('<li/>');
			$li.data('index', item.get('index'));
			const label = item.two();
			$li.html(label.toHTML());
			this.$ul.append($li);
		});
	}

	async open() {
		await this.getOptions().then(() => {
			this.buildOptions();
		});
		if (this.data.length) {
			this.onOpen();
			this.$ul.removeClass('hidden');
		}
	}

	onOpen() {
		this.$ul.outerWidth(this.$input.outerWidth());
		this.setUlPosition();
		$(window).on('scroll', this.setUlPosition);
	}

	setUlPosition = () => {
		this.$ul.css({
			top: this.$input.offset().top - $(document).scrollTop() + this.$input.outerHeight(),
			left: this.$input.offset().left
		});
	}

	close() {
		$(window).off('scroll', this.setUlPosition);
		this.$ul.addClass('hidden');
	}
}
