import entityPresenter from '../../entity/views/entityPresenter';
import ErrorCode from '../../common/enums/errorCode';
import MultilingualString from '../../common/models/multilingualString';
import {translate} from '../../common/service/stringResourceService'
import urlService from '../../common/components/urlService';
import ConfirmModal from '../../common/components/confirmModal';
import navigationPresenter from '../../Navigation/navigationPresenter';


export default class InstanceViewer extends Backbone.View {
	initialize() {
		super.initialize.apply(this, arguments);
		this.setElement($('#instance-view'));
		this.closed = true;
		this.presentationContext = null;
		this.$body = $('body');
		this.$('.close-instance').click(() => this.checkBeforeClose());
	}

	checkBeforeClose() {
		if (this.preventPageLeave && this.preventPageLeave.predicate && this.preventPageLeave.predicate()) {
			var modal = new ConfirmModal()
			modal.show({
				resource: 'unstaged.changes',
				buttons: {
					'save': (e) => {
						this.preventPageLeave.save(e)
					},
					'continue': () => {}
				},
				then: () => {
					this.preventPageLeave.removeEventHandler && this.preventPageLeave.removeEventHandler()
					this.close()
			}})
		}
		else {
			this.close()
		}
	}

	close() {
		if (!this.closed) {
			if (this.presentationContext) {
				this.presentationContext.destroy();
				this.presentationContext = null;
			}
			let previousUrl = $('#cssTheme').attr('href')
			if (this.previousContext && this.previousContext && previousUrl !== this.previousContext.themeUrl) {
				if (this.previousContext.themeUrl) {
					$('#cssTheme').attr('href', this.previousContext.themeUrl);
				} else {
					$('#cssTheme').removeAttr('href');
				}
			}
			this.previousContext && this.previousContext.update();

			this.closed = true;
			this.$el.parent().css({
				width: 'unset',
				height: 'unset'
			});
			this.$body
				.removeClass('editing-instance')
			setTimeout(() => {
				this.$el.parent().css({
					width: '',
					height: ''
				})
			}, 300);
			window.scroll(0, this.scroll);
		}
	}

	save() {
		if (this.presentationContext) {
			this.presentationContext.wizardView.save();
		}
	}

	async show(url, callback, options) {
		this._show(() => {});
		options = options || {}
		this.previousContext = options.previousContext;
		try {
			this.presentationContext = await entityPresenter.present(
				_.extend(options, {
					el: this.$el.children('.instance'),
					url: url,
					viewControl: this,
					ignoreBlocks: app.builderMode,
					hideLoading: () => this.hideLoading(),
					afterFormLoaded: () => {
						this.$('.fixed-toolbar').addClass('affix');
					},
					afterSaved: (data) => {
						callback && callback(data);
					},
					onClose: () => this.close()
			}));
		} catch (e) {
			if (e.message == ErrorCode.META_DATA_IS_STALE) {
				app.notificationManager.addError(
					MultilingualString.fromStringInCurrentLanguage(translate('stale.meta.data'))
				);
				this.close();
			} else {
				throw e;
			}
		}
		this.$('.scrollable').perfectScrollbar('update');
	}

	async _show(showFn) {
		this.$el.addClass('hold-transition');
		this.$('.loading').show();
		if (this.closed) {
			this.closed = false;
			this.scroll = window.scrollY;
			this.$body
				.addClass('editing-instance')
		}
		if (this.presentationContext) {
			this.presentationContext.destroy();
		}

		this.$el.children('.instance')
			.html('<div class="forms-container" />');

		await showFn();

		this.$('.scrollable').perfectScrollbar('update');
	}

	async showNavigation(options) {
		return await this._show(async () => {
			navigationPresenter.present({
				el: this.$el.children('.instance'),
				hideLoading: () => this.hideLoading()
			});
		});
	}

	hideLoading() {
		this.$('.loading').fadeOut('fast')
		setTimeout(() => {
			this.$el.removeClass('hold-transition');
		}, 100);
	}

	canShow() {
		return !this.presentationContext;
	}
};
