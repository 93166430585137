/* @flow */

import ReportTable from '../components/reportTable.js';
import utils, { showInstance } from '../../common/components/utils';
import entityManager from '../../common/components/entityManager';
import { translate } from '../../common/service/stringResourceService';
import OpenMode from '../../common/enums/openMode';

export default /*ReportView*/ Backbone.View.extend({

	events: {
		'click button.build': 'buildReport',
		'click .expand-all': 'expandAll',
		'click .collapse-all': 'collapseAll',
		'click .ref': 'showInstance'
	},

	initialize(options) {

		this.noDataMessage = translate('no.data');
		this.errorMessage = translate('error.has.occurred');
		this.configureAndBuildReportMessage = translate('configure.and.build.report');
		this.context = options.context;

		this.$el.html(`
			<div class="box-body reportErrorBody hidden">
				<h1><small><span class="error-text"></span></small></h1>
			</div>
			<div class="box-body reportMainBody">
				<div class="report-toolbox">
					<a class="expand-all">${translate('expand.all')}</a>
					<a class="collapse-all">${translate('collapse.all')}</a>
				</div>
				<div class="xtable">
				</div>
			</div>
		`);

		this.showMessage(this.configureAndBuildReportMessage);

		this.table = new ReportTable({
			el: this.$('.xtable'),
			model: options.model
		});

	},

	render() {
		this.table.render();
	},

	async buildReport() {
		const spin = $(`
				<div class="overlay">
					<i class="fa fa-refresh fa-spin"></i>
				</div>
		`);
		this.$el.parents('.box').append(spin);

		try {
			const json = this.context.getServerData();
			const data = await entityManager.fetchReport(json);
			if (this.hasRows(data)) {
				this.showMainBody();
				await this.table.updateTreeTable(data);
			} else {
				this.showMessage(this.noDataMessage);
			}
		} catch (e) {
			this.showMessage(this.errorMessage);
		} finally {
			spin.remove();
		}
	},

	exportReport() {
		const json = this.context.getServerData();
		utils.postRequest(json, app.urls.exportReport(this.context.type.id))
		.then((file) => {
			utils.downloadFile(file);
		});
	},

	showInstance(e) {
		let currentTarget = $(e.currentTarget);
		let typeId = currentTarget.data('type-id'), objectId = currentTarget.data('object-id');
		showInstance({
			typeId: typeId,
			objectId: objectId,
			openMode: OpenMode.NEW_CJ_TAB
		});
	},

	hasRows(data) {
		return data && data.rowGroupRootValue.children.length !== 0;
	},

	showMessage(text) {
		this.$('.reportErrorBody .error-text').text(text);
		this.showErrorBody();
	},

	showErrorBody() {
		this.$('.reportMainBody').addClass('hidden');
		this.$('.reportErrorBody').removeClass('hidden');
	},

	showMainBody() {
		this.$('.reportErrorBody').addClass('hidden');
		this.$('.reportMainBody').removeClass('hidden');
	},

	expandAll() {
		this.table.expandAll();
	},

	collapseAll() {
		this.table.collapseAll();
	}
});
