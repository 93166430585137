export function disableField (fieldName) {
  const field = this.wizardView.forms[0].findComponentObject(fieldName)
  field.disabled = true
  field.disable()
}

export function enableField (fieldName) {
  const field = this.wizardView.forms[0].findComponentObject(fieldName)
  field.disabled = false
  if (this.updating) {
    field.enable()
  }
}
