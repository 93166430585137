/* @flow */
import utils from '../../common/components/utils.js';
import BaseModel from '../../common/models/baseModel';
import Duration from '../../time/models/duration';
import Instant from '../../time/models/instant';
import Formatter from '../components/formatter';
import DeploymentOptionsModal from '../../deploy/views/deploymentOptionsModal';
import MultilingualString from '../../common/models/multilingualString.js';
import AppUpdateMode from '../../common/enums/appUpdateMode';
import { multilingualStringService } from '../../common/service/multilingualStringService';
import { timeSynchronizer } from '../../time/utils';
import FormatterInfo from '../service/formatterInfo'
import DeploymentForbiddanceReason from '../../common/enums/deploymentForbiddanceReason'
import { buildDefaultPopover } from '../../common/components/utils'
import { translate } from '../service/stringResourceService.js';
import ConfirmModal from '../components/confirmModal';
import { showConfirmModal } from '../../common/components/confirmModalVue'
import ApplicationType from '../../common/enums/applicationType';

export default class BuildView extends Backbone.View {

		constructor() {
				super({
						el: '#buildView',
						events: {
								'click #nopublish': '_noPublish',
								'click #build': '_build',
								'click #publish': '_publish',
								'show.bs.popover #build-container': '_onShowBuildPopover',
								'click.bs.dropdown #deploy': '_onGetDeployLinks',
								'click.bs.dropdown #open': '_onGetOpenItemLinks',
								'click #deploy': '_onDeployPopup',
								'click #open': '_onOpenPopup',
								'click .deploy-link': '_onDeploy',
								'click .open-item-link': '_onOpen',
								'click .get-deploy-info': '_onGetDeployInfo',
								'click .get-open-item-info': '_onGetOpenItemInfo'
						}
				});
		}
		_returnPublish () {
			$(".publish-mode-dependent").addClass('publish-mode')
			utils.postRequest({},app.urls.usePublish(true)).catch(e => console.log(e))
		}

		_onPublish (e) {
			this.buildBtn.attr('disabled', true);
			this.publishBtn.attr('disabled', true);
			this.publishBtn.addClass('animated');
			this.publishBtn.attr('disabled', 'disabled');

			utils.postRequest({}, app.urls.publish)
			.then((appId)=>{
				var url = window.location.protocol + '//'
				utils.getRequest(app.urls.getApps)
				.then((deployList) => {
					_.each(deployList, (instance, index) => {
						if (instance.instanceId == appId)
						{
							url = url + instance.domains[0]+"/"+app.currentLanguage;
						}
					});
				});
				showConfirmModal({
					title: translate('app') + ' ' + ' ' + translate('has.been.published') + ' ' + translate('do.you.wish.to.open.it'),
					confirmButtonText: translate('yes'),
					cancelButtonText: translate('no'),
					onConfirm: () => {
						window.open(url);
					}
				})
			})
			.catch(e => console.log(e));
			e.preventDefault();
		}

		_noPublish(){
			$(".publish-mode-dependent ").removeClass('publish-mode')
			utils.postRequest({},app.urls.usePublish(false)).catch(e => console.log(e))
		}

		_build(e, devMode) {
			this._checkBeforePublishOrBuild(e, e => this._onBuild(e, devMode))
		}

		_publish(e) {
			if (this._getPublishDeployInstance().deploymentForbiddanceReason != DeploymentForbiddanceReason.NONE) return
			this._checkBeforePublishOrBuild(e, this._onPublish)
		}

		_onBuild(e, devMode) {
				this.buildBtn.attr('disabled', true);
				utils.postRequest({}, app.urls.build(devMode)).catch(e => console.log(e));
				e.preventDefault();
		}

		_checkBeforePublishOrBuild(e, action){
			if (app.utils.preventPageLeave.predicate && app.utils.preventPageLeave.predicate()) {
				var modal = new ConfirmModal()
				modal.show({
					resource: 'unstaged.changes',
					buttons: {
						'save': (e) => {
							app.utils.preventPageLeave.save(e)
						},
						'continue': () => {}
					},
					then: () => {
						app.tasksQueue.add(() => action.call(this, e));
				}})
			}
			else {
				action.call(this, e)
			}
		}

		_onShowBuildPopover(el) {
			// Refresh time info
			this._renderBuild();
		}

		_onGetDeployLinks(el) {
			this._hideAllInfoBlocks('deploy-info');
		}

		_onGetOpenItemLinks(el) {
			this._hideAllInfoBlocks('open-item-info');
		}

		_hideAllInfoBlocks(cssClass) {
			$('.' + cssClass).parent().find('.get-' + cssClass).each( (index, el) => {
				this._hideInfoBlock($(el), cssClass);
			});
		}

		_onDeployPopup(el) {
			this._updateDeployList();
		}

		_onOpenPopup(el) {
			this._updateDeployList();
		}

		_onDeploy(el) {
				const appId = $(el.target).attr('data-app-id');
				if (appId) {
					// Define app update mode

					const application = _.find(this.deployList, {instanceId: appId});
					const isRemote = ApplicationType.isRemote(application.applicationType);
					const isDev = application.applicationType == ApplicationType.DEVELOPMENT;
					const deploymentOptions = new DeploymentOptionsModal();
					const model = new BaseModel({
						applicationUpdateMode: AppUpdateMode.READ_ONLY,
						appId: appId
						});
					const appNameMs = new MultilingualString();
					appNameMs.setValue($(el.target).text());
					const headerResource = multilingualStringService.format(app.getResource('deploy.to'), [appNameMs]);

					deploymentOptions.show({
							 headerResource: headerResource,
							 okResource: isRemote ? 'download' : 'deploy',
							 model: model,
							 isDev: isDev,
							 onSubmit: (resultModel) => {
								 // Post deploy request
								 const updateMode = resultModel.get('applicationUpdateMode');
								 const instancesToMerge = resultModel.get('instancesToMerge');
								 const deployData = (instancesToMerge ? instancesToMerge : []);

								 const jarVersion = resultModel.get('jarVersion');

								 const urlProvider = app.urls[isRemote ? 'downloadApp' : 'deploy'];
								 const url = urlProvider(
										appId,
										updateMode,
										(jarVersion != null && jarVersion != -1) ? jarVersion : null
										);

								const method = isRemote ? this.downloadApp : this.deploy;

								method(deployData, url)
							 }
					 });
				}
				el.preventDefault();
		}

		deploy(deployData, deployUrl) {
			utils.postRequest(deployData, deployUrl);
		}

		downloadApp(data, url) {
			utils.downloadBlobByUrl(url, data);
		}

		_onOpen(el) {
				const link = el.currentTarget.attributes.link;
				if (link) {
					window.open(link.value);
				}
				el.preventDefault();
		}

		_onGetDeployInfo(ev) {
			this._toggleInfoBlock(ev, 'deploy-info');
		}

		_onGetOpenItemInfo(ev) {
			this._toggleInfoBlock(ev, 'open-item-info');
		}

		_toggleInfoBlock(ev, cssClass) {
			// Hide the shown item and show the clicked one if it is initially hidden
			const $target = $(ev.currentTarget);
			const isVisible = $target.parent().parent().find('.' + cssClass).is(':visible');
			$('.' + cssClass + ':visible').parent().find('.get-' + cssClass).each( (index, el) => {
				this._hideInfoBlock($(el), cssClass);
			});
			if (!isVisible) {
				this._showInfoBlock($target, cssClass);
			}
			ev.preventDefault();
			ev.stopPropagation();
		}

		_showInfoBlock($containerEl, cssClass) {
			const class1 = 'fa-chevron-down';
			const class2 = 'fa-chevron-up';
			$containerEl.find('.' + class1).removeClass(class1).addClass(class2);
			$containerEl.parent().parent().find('.' + cssClass).show();
		}

		_hideInfoBlock($containerEl, cssClass) {
			const class1 = 'fa-chevron-down';
			const class2 = 'fa-chevron-up';
			$containerEl.find('.' + class2).removeClass(class2).addClass(class1);
			$containerEl.parent().parent().find('.' + cssClass).hide();
		}

		initialize() {
				this.buildBtn = this.$el.find('#build');
				this.deployBtn = this.$el.find('#deploy');
				this.publishBtn = this.$el.find('#publish')
				this.noPublishBtn = this.$el.find('#nopublish')
				this._addBuildTooltip();
				this._addPublishTooltip()
				this._addNoPublishTooltip()
				this.listReady = utils.deferedPromise();
				window.addEventListener('click', (e) => {
					if (e.target.classList){
						if (e.target.classList.contains('return-publish')) {
							this.buildBtn.parent().popover('hide')
							this._returnPublish()
						}
						if (e.target.classList.contains('no-publish')) {
							this.publishBtn.popover('hide')
							this._noPublish()
						}
						if (e.target.classList.contains('dev-build')) {
							this.buildBtn.parent().popover('hide')
							console.log(this)
							this._build(e, true);
						}
					}
				})
		}

		_addBuildTooltip() {
				buildDefaultPopover(
					this.buildBtn.parent(),
					{ container: 'body',
					content: app.getResource('no.active.build'),
					placement: 'bottom',
					html: true }
				);
		}

		_addPublishTooltip() {
				buildDefaultPopover(
					this.publishBtn,
					{ container: 'body',
					content: translate('publish.explanation'),
					placement: 'bottom',
					html: true }
				)
		}

		_addNoPublishTooltip() {
				buildDefaultPopover(
					this.noPublishBtn,
					{ container: 'body',
					content: translate('press.to.use.build.deploy'),
					placement: 'bottom',
					html: true }
				)
		}

		message(status){
			this.render(status)
		}

		render(status) {
				if (!app.builderMode){
					return;
				}
				const renderBuild = (status.last !== undefined);
				this._updateDeployList().then(() => {
					if (renderBuild) {
							this._renderBuild(status)
							if (this._getPublishDeployInstance() && this._getPublishDeployInstance().deploymentForbiddanceReason != DeploymentForbiddanceReason.NONE){
								this.publishBtn.addClass('disabled')
							} else {
								this.publishBtn.removeClass('disabled')
							}
					} else {
							this._renderDeploy(status)
					}
				})
		}

		_renderDeploy(status) {
				if (status.isRunning) {
						this._runningDeploy();
				} else {
						this._stoppedDeploy();
				}
		}

		_updateDeployList () {
			return utils.getRequest(app.urls.getApps)
			.then((data) => {
				this.deployList = data;
				this._renderDeployServers();
				this._renderOpenServers();
			});
		}

		_renderDeployServers() {
			const that =  this;
			let servers = '';
			_.each(this.deployList, (instance, index) => {
				let time = Instant.fromJSON(instance.deployedAt);
				if (time){
					time = this._formatDuration(time);
				}
				servers += `
				<li>
						<div class="deploy">
							<a class="deploy-link" href="#"
								${instance.deploymentForbiddanceReason == DeploymentForbiddanceReason.NONE
										? 'data-app-id="' + instance.instanceId + '"': ''}>
									${_.escape(instance.title)}
								</a>
							<a class="get-deploy-info" href="#">
								<span class="fa fa-chevron-down"></span>
							</a>
						</div>
						<span class="deploy-info" style="display:none;">
							${that._renderDeploymentForbiddanceReason(instance)}
							${app.getResource('application.state')}: ${instance.isAppResponding ? app.getResource('responding') : app.getResource('not.responding')}<br/>
							${app.getResource('deployment.state')}: ${instance.deploymentState ? app.getResource('container.state.' + instance.deploymentState.toLowerCase()) : '?'}<br/>
							${app.getResource('deployed.at')}: ${time}<br/>
							${app.getResource('deployed.by')}: ${_.escape(instance.deployedBy)}<br/>
							${app.getResource('application.version')}: ${FormatterInfo.formatVersion(instance.applicationVersion, instance.buildNumber)}<br/>
							${app.getResource('builder.version')}: ${_.escape(instance.builderVersion)}
						</span>
				</li>`;
			});
			const deployList = this.$el.find('.servers-deploy-list');
			deployList.html(servers);
		}

		_renderDeploymentForbiddanceReason(appInstance) {
			if (appInstance.deploymentForbiddanceReason  != DeploymentForbiddanceReason.NONE) {
				return `<span class='deploy-forbiddance-status'>
						${app.getResource('is.deployment.enabled')}: ${app.getResource('no')}
					</span><br/>
					<span class='deploy-forbiddance-reason'>
						${app.getResource('deploy.forbiddance.reason.' + appInstance.deploymentForbiddanceReason.toLowerCase().replace(/_/g,'.'))}
						${appInstance.deploymentForbiddanceReason == DeploymentForbiddanceReason.CONTAINER_IS_NOT_ASSIGNED
							? multilingualStringService.formatSystemString(app.getResource('deploy.forbiddance.solution.text.assign.container', true), [app.urls.dashboardContainerManagement])
							: multilingualStringService.formatSystemString(app.getResource('deploy.forbiddance.solution.text', true), [app.urls.dashboard]) }
						<br/>
					</span>`;
			} else {
				return '';
			}
		}
		_renderOpenServers() {
				let servers = this.$el.find('.servers-open-list')
				servers[0].innerHTML=''
				_.each(this.deployList, (instance, index) => {
					if (!ApplicationType.isRemote(instance.applicationType)) {
						let time = Instant.fromJSON(instance.deployedAt);
						if (time){
							time = this._formatDuration(time);
						}
						let openItem = $(`
						<li>
								<div class="open-item">
									<a class="open-item-link" href="#"
										${instance.isAppResponding ? 'link="' + window.location.protocol + '//' + instance.domains[0] + '"': ''}>
											${_.escape(instance.title)}
									</a>
									<a class="get-open-item-info" href="#">
										<span class="fa fa-chevron-down"></span>
									</a>
								</div>
								<span class="open-item-info" style="display:none;">
									${instance.isAppResponding ? '' : app.getResource('application.state') + ': ' + app.getResource('not.responding') + '<br/>'}
									${app.getResource('deployment.state')}: ${instance.deploymentState ? app.getResource('container.state.' + instance.deploymentState.toLowerCase()) : '?'}<br/>
									${app.getResource('deployed.at')}: ${time}<br/>
									${app.getResource('deployed.by')}: ${_.escape(instance.deployedBy)}<br/>
									${app.getResource('application.version')}: ${FormatterInfo.formatVersion(instance.applicationVersion, instance.buildNumber)}<br/>
									${app.getResource('builder.version')}: ${_.escape(instance.builderVersion)}<br/>
								</span>
								${this._renderServerAliases(instance)}
						</li>`)
						if (instance.deploymentState == null){
						buildDefaultPopover(
							openItem,
							{
								container: 'body',
								content: app.getResource('never.deployed'),
								placement: 'right',
								html: true
							})
						}
						servers.append(openItem)
					}
				});
		}

		_renderServerAliases(instance) {
			if (instance.domains.length <= 1) {
				return '';
			}
			let aliases = `${app.getResource('aliases')}:<br/>`;
			_.each(instance.domains, (domain, index) => {
				if (index > 0) {
					aliases += `<a href='${window.location.protocol + '//' + domain}'>${_.escape(domain)}</a><br/>`;
				}
			});
			aliases = `<span class="open-item-info open-item-info-aliases" style="display:none;">${aliases}</span>`
			return aliases;
		}

		_stoppedDeploy() {
				this.deployBtn.removeClass('animated');
				this.publishBtn.removeClass('animated')
				this.deployBtn.removeClass('disabled');
				this.buildBtn.removeAttr('disabled');
				this.publishBtn.removeAttr('disabled')
		}

		_runningDeploy() {
				this.deployBtn.addClass('animated');
				this.publishBtn.addClass('animated');
				this.publishBtn.attr('disabled', 'disabled');
				this.deployBtn.toggleClass('disabled', true);
				this.buildBtn.attr('disabled', true);
		}

		_renderBuild(status) {
				if (status) {
					this.build = status;
				}
				if (!this.build) {
					return;
				}
				status = this.build;
				if (status.last && status.last.isRunning) {
						this._runningBuild();
				} else {
						this._stoppedBuild();
						if (!status.last) {
								// this._noPreparedBuild();
								this._buildSuccess();
						} else if (status.last.isSuccessful) {
								this._buildSuccess();
						} else {
								this._buildError(status);
						}
				}
		}

		// _noPreparedBuild(){
		// 		this.deployBtn.toggleClass('disabled', true);
		// 		buildDefaultPopover(
		// 			this.deployBtn,
		// 			{
		// 				container: 'body',
		// 				content: app.getResource('no.prepared.build'),
		// 				placement: 'bottom',
		// 				html: true
		// 			}
		// 		)
		// }

		_stoppedBuild() {
				this.buildBtn.removeClass('animated');
				this.buildBtn.removeAttr('disabled');
				this.publishBtn.removeClass('animated');
				this.publishBtn.removeAttr('disabled');
		}

		_runningBuild() {
				this._changeTooltipText(this._getBuildTooltipText());
				this.buildBtn.removeClass('btn-red-border');
				this.buildBtn.removeClass('btn-white-border');
				this.buildBtn.addClass('animated');
				this.publishBtn.addClass('animated');
				this.publishBtn.attr("disabled", "disabled");
				this.buildBtn.attr("disabled", true);
				this.deployBtn.addClass("disabled");
		}

		_buildSuccess() {
				this._changeTooltipText(this._getBuildTooltipText());
				this.buildBtn.removeClass('btn-red-border');
				this.buildBtn.addClass('btn-white-border');
				this.publishBtn.removeClass('btn-red-border');
				this.deployBtn.removeClass('disabled');
				this.deployBtn.popover('destroy');

		}

		_buildError() {
				this._changeTooltipText(this._getBuildTooltipText());
				this.buildBtn.removeClass('btn-white-border');
				this.publishBtn.removeClass('animated')
				this.buildBtn.addClass('btn-red-border');
				this.publishBtn.addClass('btn-red-border');
				this.publishBtn.removeAttr('disabled');
				this.deployBtn.toggleClass('disabled', !this.build.lastSuccessful);
		}

		_getBuildTooltipText() {
				let text = '';
				if (this.build.last) {
					let status = this.build.last;
					if (status.isRunning || status.isSuccessful === false) {
						let time = Instant.fromJSON(status.buildTimestamp);
						if (time) {
							time = this._formatDuration(time);
						}
						let username = _.escape(status.userName) || app.getResource('anonymous');
						text = `<b>${app.getResource(status.isRunning ? 'current.build' : 'last.build')}</b><br/>`;
						text += `${app.getResource('last.started.at')}: ${time}<br/>`;
						text += `${app.getResource('last.started.by')}: ${username}`;
						if (status.isSuccessful === false) {
							text += `<br/><a href="${app.urls.home}lastBuildError"> ${app.getResource('view.errors')} </a>`;
						}
					}
				}
				const text2 = this._getLastSuccessfulBuildTooltipText();
				text += (text.length > 0 && text2.length > 0 ? '<br/><br/>' : '') + text2;

				if (!text) {
					text = app.getResource('no.active.build');
				}
				return text;
		}

		_getLastSuccessfulBuildTooltipText() {
				if (!this.build.lastSuccessful) {
					return '';
				}
				let status = this.build.lastSuccessful;
				let text = `<b>${app.getResource('last.successful')}</b><br/>`;
				let time = Instant.fromJSON(status.buildTimestamp);
				if (!time){
					text += `${app.getResource('no.successful.build').toLowerCase()}`;
				} else {
					time = this._formatDuration(time);
					const username = _.escape(status.userName) || app.getResource('anonymous');
					text += `${app.getResource('started.at')}: ${time}<br/>`;
					text += `${app.getResource('started.by')}: ${username}<br/>`;
					text += `${app.getResource('version')}: ${FormatterInfo.formatVersion(status.applicationVersion, status.buildNumber, status.isDevMode)}`;
				}
				return text;
		}
		_getPublishDeployInstance() {
			return this.deployList[0]
		}
		_changeTooltipText(text: string) {
				this.buildBtn.parent().attr('data-content', text +
				`</br></br>
					<a class='dev-build'>${translate('run.dev.build')}</a>
				</br>
					<a class='return-publish'>${translate('return.to.publish')}</a>`);
				this.publishBtn.attr('data-content', translate('publish.explanation') + "</br>" + (this._getPublishDeployInstance() ? this._renderDeploymentForbiddanceReason(this._getPublishDeployInstance()) : '') + "</br>" + text + `</br></br><a class='no-publish'>${translate('press.to.use.build.deploy')}</a>`);
		}

		_formatDuration(time) {
			let eventAge = timeSynchronizer.getServerEventAge(time);
			if (eventAge.toMillis() < 0)  {
				// Fix the case when response from server is slightly delayed
				eventAge = Duration.ofNanos(0);
			}
			return Formatter.formatDuration(eventAge);
		}
}
