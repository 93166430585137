/* @flow */

import BlockKind from '../enums/blockKind';
import BlockType from '../enums/blockType';
import BlockTrigger from '../enums/blockTrigger';
// import Type from './type';
// import Field from './field';

export default class Block extends Backbone.Model {

	initialize(): void {
		if (this.get('id')) {
			this.set('id', this.get('id').toString());
		}
	}

	procedure(): any {
		try {
			return JSON.parse(this.get('procedure'));
		} catch (e) {
			return null;
		}
	}

	returnType(): any {
		try {
			return JSON.parse(this.get('returnType'));
		} catch (e) {
			return null;
		}
	}

	owner(): any {
		return app.types.get(this.get('ownerId'));
	}

	type(): string {
		return this.get('blockType');
	}

	isPredefined(): boolean {
		return this.get('isPredefined');
	}

	isSystem(): boolean {
		return this.get('isSystem');
	}

	isClientBlock(): boolean {
		return this.type() === BlockType.CLIENT;
	}

	isServerBlock(): boolean {
		return this.type() === BlockType.SERVER;
	}

	isHybridBlock(): boolean {
		return this.type() === BlockType.HYBRID;
	}

	kind(): string {
		return this.get('blockKind');
	}

	systemName(): string {
		return this.get('systemName');
	}

	systemJavaPackageName(): string {
		return this.get('systemJavaPackageName');
	}

	systemClassName(): string {
		return this.get('systemClassName');
	}

	systemMethodName(): string {
		return this.get('systemMethodName');
	}

	isAction(): boolean {
		return this.kind() === BlockKind.ACTION;
	}

	isFunction(): boolean {
		return this.kind() === BlockKind.FUNCTION;
	}

	blockTrigger(): string {
		return this.get('trigger');
	}

	trigger(): string {
		return this.get('trigger');
	}

	isValidateBlock(): boolean {
		return this.isAction() && this.blockTrigger() === BlockTrigger.VALIDATE;
	}

	isFilterBlock(): boolean {
		return this.isAction() && this.blockTrigger() === BlockTrigger.FIELD_FILTERED;
	}

	isFilterTypeBlock(): boolean {
		return this.isAction() && this.blockTrigger() === BlockTrigger.FIELD_TYPE_FILTERED;
	}

	isFormLoadBlock(): boolean {
		return this.isAction() && this.blockTrigger() === BlockTrigger.FORM_LOAD;
	}

	hasFunction(): boolean {
		let isFunction = this.isFunction();
		let notNullOrEmpty =
			this.get('procedure') !== null && this.get('procedure').length !== 0;
		return isFunction && notNullOrEmpty;
	}

	hasClientFunction(): boolean {
		if (this.isCustomAction() && !this.isSystem()) {
			return true;
		}
		return ((this.isClientBlock() || this.isHybridBlock()) && this.hasFunction());
	}

	hasServerFunction(): boolean {
		if (this.isCustomAction() && this.isServerBlock() && !this.isSystem()) {
			return true;
		}
		return (this.isServerBlock() || this.isHybridBlock()) && this.hasFunction();
	}

	eventTriggerField(): any {
		return app.fields.get(this.get('eventTriggerFieldId'));
	}

	eventTriggerSubField(): any {
		return app.fields.get(this.get('eventTriggerSubFieldId'));
	}

	view(): any {
		return app.views.get(this.get('viewId'));
	}

	fillResultType() {
		return app.types.get(this.get('fillResultTypeId'));
	}

	isFieldAction(): boolean {
		return !!this.get('eventTriggerFieldId');
	}

	isCollectionInsertedEvent(): boolean {
		return this.trigger() == BlockTrigger.COLLECTION_INSERTED;
	}

	isCollectionDeletedEvent(): boolean {
		return this.trigger() == BlockTrigger.COLLECTION_DELETED;
	}

	isViewAction(): boolean {
		return !!this.get('viewId');
	}

	isCustomAction(): boolean {
		return this.get('trigger') == BlockTrigger.CUSTOM;
	}

	isRowClickAction(): boolean {
		return this.get('trigger') == BlockTrigger.ROW_CLICKED || this.get('trigger') == BlockTrigger.ROW_CLICK;
	}

	isValueSuggestAction(): boolean {
		return this.trigger() == BlockTrigger.VALUE_SUGGEST || this.trigger() == BlockTrigger.VALUE_SUGGESTED;
	}

	name(): string {
		return this.get('name');
	}

	isTypeAction(): boolean {
		return !this.isFunction() && !this.isCustomAction() && !this.isViewAction() &&
					!this.isFieldAction() && !this.isFillFunction();
	}

	triggerOrder(): int {
		let triggersOrder = [BlockTrigger.FORM_LOAD, BlockTrigger.VALIDATE,
			BlockTrigger.FIELD_CHANGE, BlockTrigger.FIELD_CHANGED, BlockTrigger.FIELD_FILTERED];
		return triggersOrder.indexOf(this.trigger());
	}

	typeOrder(): int {
		let typesOrder = [BlockType.SERVER, BlockType.HYBRID, BlockType.CLIENT];
		return typesOrder.indexOf(this.type());
	}

	isFormGetNext(): boolean {
		return this.trigger() == BlockTrigger.FORM_GET_NEXT;
	}

	isCheckPermission(): boolean {
		return this.trigger() == BlockTrigger.PERMISSION_CHECK;
	}

	isEventHandler(): boolean {
		return this.trigger() == BlockTrigger.HANDLE_EVENT;
	}

	isGetDataLeaves(): boolean {
		return this.trigger() == BlockTrigger.GET_DATA_LEAVES;
	}

	isFillFunction(): boolean {
		return this.trigger() == BlockTrigger.CREATE_FROM;
	}

	isTreeChange(): boolean {
		return this.trigger() == BlockTrigger.TREE_CHANGE;
	}

	isApiEntry(): boolean {
		return this.trigger() == BlockTrigger.API;
	}

	nameWithArguments(): string {
		let procedure = this.procedure();
		let argList = [];
		for(let j = 0; j < procedure[1].length; ++j) {
			argList.push(procedure[1][j] || '');
		}
		return this.name() + ' (' + argList.join(', ') + ')';
	}

	imports(): string {
		return this.get('customImports').source || '';
	}

	usedInScheduler(): boolean {
		return !!_.find(app.blocksUsedInScheduler, block => block.id === this.id);
	}

	workspace(): string {
		return this.get('workspace').source || '';
	}

	eventHandlerTargetType() {
		return app.types.get(this.get('eventHandlerTargetTypeId'));
	}

	apiInputType() {
		return app.types.get(this.get('apiInputTypeId'));
	}

	apiOutputType() {
		return app.types.get(this.get('apiOutputTypeId'));
	}
}
