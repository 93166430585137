import entityPresenter from '../../entity/views/entityPresenter';
import ErrorCode from '../../common/enums/errorCode';
import { translate } from '../../common/service/stringResourceService'
import { multilingualStringService } from '../../common/service/multilingualStringService'


class Widget {

  constructor (options) {
    this.field = options.field
    this.model = options.model
    this.modelAttr = options.modelAttr
    this.el = options.el
    this.$el = $(options.el)
    this.context = options.context;
    this.viewId = this.el.getAttribute('data-view-id')
    this.height = this.el.getAttribute('data-height')
    this.width = Number.parseInt(this.el.getAttribute('data-width') || '0', 10)
    this.pageSize = Number.parseInt(this.el.getAttribute('data-page-size'), 10)
    this.columnsQuantity = Number.parseInt(this.el.getAttribute('data-columns-quantity'), 10)
    this.itemWidth = this.width || 'calc(' + 100 / this.columnsQuantity + '%' + ' - 22px)'
    this.hiddenStubs = this.width ? this.el.offsetWidth / (this.width + 22) : this.columnsQuantity
    this.model && this.model.get(this.modelAttr).on('update reset', () => {
      this.render()
    })
    this.$el.on('click', (e) => {
      if ($(e.target).hasClass('load-more-widget')) {
        this.renderPage(this.start, this.end)
      }
    })
  }
  async renderOne (options) {
    let container = $('<div class="widget-item"></div>')
    container.css('height', this.height + 'px')
    container.css('width', this.itemWidth)
    this.$el.append(container)
    let url =  app.urls.update(this.field.type().id, options.objectId, {
      formViewId: this.viewId,
    })
    if (!options.objectId) {
      url = app.urls.create(this.field.type().id, this.viewId)
    }
    if (!options.customContent){
      try {
        this.presentationContext = await entityPresenter.present(
        {
          url: url,
          typeId: this.field.type().id,
          viewId: this.viewId,
          objectId: options.objectId,
          afterSaved: (data) => {},
          el: container,
          ignoreBlocks: app.builderMode,
          viewControl: this,
          isWidget: true
        })
      } catch (e) {
        if (e.message == ErrorCode.META_DATA_IS_STALE) {
          app.notificationManager.addError(
            MultilingualString.fromStringInCurrentLanguage(translate('stale.meta.data'))
          );
        } else {
          throw e;
        }
      }
    } else if (options.hiddenStub) {
      container.addClass('hidden-stub custom')
      container.css('height', 0)
    } else {
      container.append(options.customContent)
      container.addClass('custom')
    }
  }
  removeCustom () {
    this.$el.children('.widget-item.custom').remove()
  }
  renderPage (start, end) {
    this.removeCustom()
    let models = this.model.get(this.modelAttr).models
    for (let i = start; i < end; i++){
      let model = models[i]
      if (!model) break
      this.renderOne({
        objectId: model.id
      })
    }
    if (models.length > end){
      this.renderLoadMore()
    }
    this.start = end
    this.end = end + this.pageSize
    this.addHiddenStubsForRow()
  }
  addHiddenStubsForRow () {
    for (let i =  0; i < this.hiddenStubs; i++) {
      this.renderOne({
        hiddenStub: true,
        customContent: true
      })
    }
  }
  renderLoadMore () {
    this.renderOne({
      customContent: `<div style="height:100%;width:100%" class="load-more-widget">${multilingualStringService.formatSystemString(translate('load.more'),[''])}</div>`
    })
  }
  render () {
    this.$el.empty()
    this.renderPage(0, this.pageSize)
  }

  focus () {
  //  this.checkbox.focus()
  }

  disable () {
  //  this.props.disabled = true
  }

  isDisabledInFormBuilder () {
  //  return this.disabled
  }

  enable () {
  //  this.props.disabled = false
  }
}
export default Widget;
