export const icons = {
	edit:
	{
		icon: 'edit',
		iconClasses: 'material-icons fa defaultAction'
	},
	replace:
	{
		icon: 'find_replace',
		iconClasses: 'material-icons fa defaultAction'
	},
	usages:
	{
		icon: 'timeline',
		iconClasses: 'material-icons fa defaultAction'
	},
	remove:
	{
		icon: 'delete',
		iconClasses: 'material-icons fa defaultAction'
	},
	openView:
	{
		iconClasses: 'open-view'
	},
	openType:
	{
		iconClasses: 'open-type'
	},
	makeDefault:
	{
		iconClasses: 'fa makeDefault defaultAction make-default-alignment',
		icon: 'D'
	},
	wizard:
	{
		icon: 'dialpad',
		iconClasses: 'material-icons fa defaultAction'
	},
	copy:
	{
		icon: 'content_copy',
		iconClasses: 'material-icons fa defaultAction'
	},
	add_to_scheduler:
	{
		icon: 'restore',
		iconClasses: 'material-icons fa defaultAction'
	},
	remove_from_scheduler:
	{
		icon: 'delete',
		iconClasses: 'material-icons fa defaultAction'
	},
	VALIDATE:
	{
		icon: 'playlist_add_check',
		iconClasses: 'material-icons onValidate serverEvent fa'
	},
	VALIDATE_FIELD:
	{
		icon: 'playlist_add_check',
		iconClasses: 'material-icons onValidate serverEvent fa'
	},
	VALIDATE_FORM:
	{
		icon: 'playlist_add_check',
		iconClasses: 'material-icons onValidate serverEvent fa'
	},
	FIELD_CHANGE:
	{
		icon: 'cached',
		iconClasses: `material-icons serverEvent fa`
	},
	FIELD_CHANGED:
	{
		icon: 'cached',
		iconClasses: 'material-icons clientEvent fa'
	},
	FIELD_FILTERED:
	{
		icon: 'filter_list',
		iconClasses: 'material-icons fa clientEvent'
	},
	FIELD_TYPE_FILTERED:
	{
		icon: 'filter_list',
		iconClasses: 'material-icons fa clientEvent'
	},
	FORM_LOAD:
	{
		icon: 'filter_tilt_shift',
		iconClasses: 'material-icons serverEvent fa'
	},
	FORM_LOADED:
	{
		icon: 'filter_tilt_shift',
		iconClasses: 'material-icons clientEvent fa'
	},
	ROW_LOAD:
	{
		icon: 'filter_tilt_shift',
		iconClasses: 'material-icons serverEvent fa'
	},
	ROW_LOADED:
	{
		icon: 'filter_tilt_shift',
		iconClasses: 'material-icons clientEvent fa'
	},
	FORM_GET_NEXT:
	{
		icon: 'playlist_play',
		iconClasses: 'material-icons hybridEvent fa'
	},
	COLLECTION_INSERTED:
	{
		icon: 'add_to_queue',
		iconClasses: 'material-icons fa clientEvent'
	},
	COLLECTION_DELETED:
	{
		icon: 'remove_from_queue',
		iconClasses: 'material-icons fa clientEvent'
	},
	CREATE_FROM:
	{
		source:
		{
			classes: 'table-label label label-source block-label'
		},
		result:
		{
			classes: 'table-label label label-result block-label'
		}
	},
	ROW_CLICKED:
	{
		icon: 'call_missed_outgoing',
		iconClasses: 'clientEvent material-icons fa'
	},
	ROW_CLICK:
	{
		icon: 'call_missed_outgoing',
		iconClasses: 'serverEvent fa material-icons fa'
	},
	VALUE_SUGGEST:
	{
		icon: 'short_text',
		iconClasses: 'serverEvent fa material-icons fa'
	},
	VALUE_SUGGESTED:
	{
		icon: 'short_text',
		iconClasses: 'clientEvent fa material-icons fa'
	},
	SAVE:
	{
		iconClasses: 'serverEvent fa fa-save'
	},
	LOAD:
	{
		iconClasses: 'serverEvent fa fa-spinner'
	},
	LOADED:
	{
		iconClasses: 'clientEvent fa fa-spinner'
	},
	DELETE:
	{
		iconClasses: 'serverEvent fa fa-trash'
	},
	AFTER_IMPORT:
	{
		iconClasses: 'serverEvent fa fa-upload'
	},
	BUILD:
	{
		iconClasses: 'clientEvent fa fa-wrench'
	},
	TREE_CHANGE:
	{
		iconClasses: 'serverEvent fa fa-tree'
	},
	TEST:
	{
		iconClasses: 'clientEvent fa fa-heartbeat'
	},
	DOCUMENT_POST:
	{
		iconClasses: 'serverEvent glyphicon glyphicon-hand-up'
	},
	GET_DATA_LEAVES:
	{
		iconClasses: 'serverEvent fa fa-location-arrow'
	}
};
