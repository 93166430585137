export default {
	PRIMITIVE: 'PRIMITIVE',
	STRING: 'STRING',
	CELL: 'CELL',
	ROW: 'ROW',
	FORM: 'FORM',
	SPREADSHEET: 'SPREADSHEET',
	PDF: 'PDF',
	DOC: 'DOC',
	XML: 'XML',
	CLIENT: 'CLIENT',
	WIDGET: 'WIDGET'
};
