/* @flow */

export default /*xTable*/ Backbone.View.extend({

	events: {
		'scroll .main-container': '_scroll'
	},

	initialize() {
		this.$el.html(`
			<div class="fixed-col-header-container">
				<table class="fixed-col-header table">
					<col>
					<thead>
						<tr />
					</thead>
				</table>
			</div>
			<div class="header-container">
				<table class="header table">
					<colgroup />
					<thead></thead>
				</table>
			</div>
			<div class="fixed-col-outer-container">
				<div class="fixed-col-container">
					<table class="fixed-col table">
						<col>
						<tbody></tbody>
					</table>
				</div>
			</div>
			<div class="main-container">
				<table class="main table">
					<colgroup />
					<tbody></tbody>
				</table>
			</div>
		`);

		this.fixedColumn = null;
		this.columns = [];

		this.$mainContainer = this.$('.main-container');
		this.$fixedColContainer = this.$('.fixed-col-container');
		this.$fixedColOuterContainer = this.$('.fixed-col-outer-container');
		this.$fixedCol = this.$('.fixed-col');
		this.$fixedColHeader = this.$('.fixed-col-header');
		this.$headerContainer = this.$('.header-container');
		this.$main = this.$('.main');
		this.$header = this.$('.header');

		this.$mainContainer[0].addEventListener('scroll', _.bind(this._scroll, this), {passive: true});
		this.$fixedColContainer[0].addEventListener('scroll', _.bind(this._scrollFixed, this), {passive: true});
		$(window).resize(_.bind(this._onWindowResize, this));

		this.$header.on('mousedown', '.resizer', _.bind(this._onColumnResize, this));
		this.$fixedColHeader.on('mousedown', '.resizer', _.bind(this._onFixedColumnResize, this));
	},

	_onColumnResize(e) {
		const $th = $(e.target.parentElement);
		const index = $th.data('colindex');
		const column = this.columns[index];
		this._startResizing(e, column);
		e.preventDefault();
	},

	_onFixedColumnResize(e) {
		this._startResizing(e, this.fixedColumn);
		e.preventDefault();
	},

	_startResizing(e, column) {
		const originalWidth = column.$headerCol.width();
		const originalMouseX = e.pageX;
		const resize = (e) => {
			const width = Math.max(originalWidth + (e.pageX - originalMouseX), this.getColumnMinWidth(column));
			this.setColumnWidth(column, width);
		};
		const stopResize = () => {
			window.removeEventListener('mousemove', resize)
		};
		window.addEventListener('mousemove', resize);
		window.addEventListener('mouseup', stopResize);
	},

	getColumnMinWidth(column) {
		if (column.isFixed) {
			return 100;
		}
		return 30;
	},

	getColumnDefaultWidth(column) {
		if (column.isFixed) {
			if (window.innerWidth <= 500) {
				return 200;
			}
			return 400;
		}
		return 132;
	},

	setColumnWidth(column, width) {
		column.width = width;
		const cssWidth = width + 'px';
		column.$headerCol.css({ width: cssWidth });
		column.$mainCol.css({ width: cssWidth });
		if (column.isFixed) {
			this.$main.add(this.$header)
					.css({ marginLeft: cssWidth });
		}
		this._recalculateTableWidth();
	},

	_scroll() {
		const ignoreScroll = this.ignoreScroll;
		this.ignoreScroll = false;
		if (ignoreScroll) {
			return;
		}
		if (this.$fixedColContainer[0].scrollTop != this.$mainContainer[0].scrollTop) {
			this.ignoreScrollFixed = true;
			this.$fixedColContainer[0].scrollTop = this.$mainContainer[0].scrollTop;
		}
		this.$headerContainer[0].style.marginLeft = -this.$mainContainer[0].scrollLeft + 'px';
	},

	_scrollFixed() {
		const ignoreScrollFixed = this.ignoreScrollFixed;
		this.ignoreScrollFixed = false;
		if (ignoreScrollFixed) {
			return;
		}
		if (this.$mainContainer[0].scrollTop != this.$fixedColContainer[0].scrollTop) {
			this.ignoreScroll = true;
			this.$mainContainer[0].scrollTop = this.$fixedColContainer[0].scrollTop;
		}
	},

	_onWindowResize() {
		this._setMaxMainHeight();
		this._recalculateFixedColHeight();
	},

	_setMaxMainHeight() {
		this.$mainContainer.css('max-height', $(window).height() - 180 - this.$headerContainer.height());
	},

	_recalculateFixedColHeight() {
		this.$fixedColContainer.height(this.$mainContainer[0].clientHeight);
		this.$fixedColOuterContainer.height(this.$mainContainer[0].clientHeight);
	},

	_recalculateTableWidth() {
		const width = _.reduce(this.columns, (sum, column) => sum += column.width, 0);
		this.$main.width(width)
		this.$header.width(width);

		this.$fixedCol.width(this.fixedColumn.width);
		this.$fixedColOuterContainer.width(this.fixedColumn.width);
		this.$fixedColHeader.width(this.fixedColumn.width);
	},

	setHeader(columns) {
		const valueColumns = [];
		const columnsWithChildren = [];
		this._groupTreeNodes({children: columns}, valueColumns, columnsWithChildren);
		this.fixedColumn = valueColumns.shift();
		this.fixedColumn.isFixed = true;
		this.fixedColumn.width = this.getColumnDefaultWidth(this.fixedColumn);
		this.columns = valueColumns;

		_.each(columnsWithChildren, column => {
			column['class'] = 'underlined';
		});
		_.each(valueColumns, (column, index) => {
			column.colIndex = index;
			column.width = this.getColumnDefaultWidth(column);
		});

		const notFixedColumns = _.tail(columns);
		const $rows = this._buildHeaderRows(notFixedColumns);
		this.$('.header thead')
				.empty()
				.append($rows);

		const $fixedColHeader = this.$('.fixed-col-header thead');
		$fixedColHeader
				.empty()
				.append($('<tr />').append(this._buildHeaderCell(this.fixedColumn)));

		const treeHeight = this._getFullTreeHeight({children: notFixedColumns}) - 1;
		_.times(treeHeight - 1, () => {
			$fixedColHeader.append('<tr />');
		});

		this.initCols();
		this._setMaxMainHeight();
		this.setColumnWidth(this.fixedColumn, this.getColumnDefaultWidth(this.fixedColumn)); // reset fixed column width on rebuild
		this._recalculateTableWidth();
	},

	_groupTreeNodes(column, leaves, branches) {
		if (!column.children || !column.children.length) {
			leaves.push(column);
		} else {
			branches.push(column);
			_.each(column.children, (child) => {
				this._groupTreeNodes(child, leaves, branches);
			});
		}
	},
	

	_getFullTreeHeight(tree) {
		if (!tree.children || !tree.children.length) {
			return 1;
		}
		return this._getFullTreeHeight(tree.children[0]) + 1;
	},

	initCols() {
		const headerCols = _.map(this.columns, column => {
			return column.$headerCol = $('<col>');
		});
		const mainCols = _.map(this.columns, column => {
			return column.$mainCol = $('<col>');
		});
		this.$('.header colgroup').empty().append(headerCols);
		this.$('.main colgroup').empty().append(mainCols);
		
		this.fixedColumn.$headerCol = this.$fixedColHeader.find('col')
				.removeAttr('style');
		this.fixedColumn.$mainCol = this.$fixedCol.find('col')
				.removeAttr('style');
	},

	_buildHeaderRows(columns) {
		const rows = [];
		this._treeToGrid(rows, {children: columns}, 0);
		rows.shift();

		return _.map(rows, row => {
			const tr = $('<tr>');
			_.each(row, cell => {
				tr.append(this._buildHeaderCell(cell));
			});
			return tr;
		});
	},

	_treeToGrid(result, node, level) {
		result[level] = result[level] || [];
		result[level].push(node);
		_.each(node.children, child => {
			this._treeToGrid(result, child, level + (node.rowspan || 1));
		});
	},

	_buildHeaderCell(cell) {
		const attrs = _.pick(cell, ['rowspan', 'colspan', 'class']);
		const th = $('<th>', attrs);
		if (cell.html) {
			th.html(cell.html);
		}
		if (cell.colIndex != null) {
			th.data('colindex', cell.colIndex);
		}
		if (!cell.children || cell.children.length == 0) {
			th.append($('<div class="resizer"/>'));
		}
		return th;
	},

	addData(data) {
		this.$('.fixed-col tbody').empty();
		this.$('.main tbody').empty();

		const rowsFixedCol = document.createDocumentFragment();
		const rows = document.createDocumentFragment();

		this.originalData = data;
		this.viewData = [];

		this._traverseData(data, (item, level) => {
			item.index = this.viewData.length;
			this.viewData.push(item);
			this._buildRow(item, rowsFixedCol, rows, level);
		}, 1);

		this.$('.fixed-col tbody')[0].appendChild(rowsFixedCol);
		this.$('.main tbody')[0].appendChild(rows);

		_.defer(() => this._recalculateFixedColHeight());
	},

	_traverseData(data, func, level) {
		_.each(data, (item) => {
			func(item, level);
			if (item.children) {
				this._traverseData(item.children, func, level + 1);
			}
		})
	},

	_buildRow(item, rowsFixedCol, rows, level) {
		const rowFormat = this.rowFormatter(null, item);

		const rowFixedCol = document.createElement('tr');
		if (rowFormat) {
			rowFixedCol.classList.add(...rowFormat.classes);
		}
		if (level > 1) {
			rowFixedCol.classList.add('hidden');
		}
		if (item.children && item.children.length) {
			rowFixedCol.classList.add('collapsed');
		}
		rowFixedCol.onclick = _.bind(this._onRowClick, this);
		const cellFixed = this._buildCell(this.fixedColumn, item);
		this._prependSpacersAndExpander(cellFixed, item, level);
		rowFixedCol.appendChild(cellFixed);
		rowsFixedCol.appendChild(rowFixedCol);


		const row = document.createElement('tr');
		if (rowFormat) {
			row.classList.add(...rowFormat.classes);
		}
		if (level > 1) {
			row.classList.add('hidden');
		}
		_.each(this.columns, column => {
			const cell = this._buildCell(column, item);
			row.appendChild(cell);
		});
		rows.appendChild(row);
	},

	_prependSpacersAndExpander(node, item, level) {
		const prefix = document.createDocumentFragment();
		if (item.shiftLeft) {
			--level;
		}
		for (let i = 0; i < level; ++i) {
			prefix.appendChild(this._spacer());
		}

		if (item.children && item.children.length) {
			prefix.appendChild(this._expander());
		}

		node.insertBefore(prefix, node.firstChild);
	},

	_spacer() {
		const span = document.createElement('span');
		span.classList.add('spacer');
		return span;
	},

	_expander() {
		const i = document.createElement('i');
		i.classList.add('material-icons');
		i.classList.add('expander'); // IE doesn't support multiple args for 'add'
		return i;
	},

	_onRowClick(e) {
		const tr = e.currentTarget;
		const index = tr.rowIndex;
		const item = this.viewData[index];

		if (tr.classList.contains('expanded')) {
			this._collapse(item);
		} else { // 'collapsed'
			this._expand(item);
		}
		this._recalculateFixedColHeight();
	},

	_collapse(item) {
		const fixedTr = this.$fixedCol[0].rows[item.index];
		const tr = this.$main[0].rows[item.index];
		fixedTr.classList.remove('expanded');
		tr.classList.remove('expanded');
		if (item.children && item.children.length) {
			fixedTr.classList.add('collapsed');
		}

		_.each(item.children, child => {
			this._hideAndCollapse(child);
		});
	},

	_hideAndCollapse(item) {
		const fixedTr = this.$fixedCol[0].rows[item.index];
		const tr = this.$main[0].rows[item.index];
		fixedTr.classList.add('hidden');
		tr.classList.add('hidden');

		this._collapse(item);
	},

	_expand(item) {
		if (!item.children || !item.children.length) {
			return;
		}
		const fixedTr = this.$fixedCol[0].rows[item.index];
		const tr = this.$main[0].rows[item.index];
		fixedTr.classList.add('expanded');
		fixedTr.classList.remove('collapsed');
		tr.classList.add('expanded');

		_.each(item.children, child => {
			this._show(child);
		});
	},

	_show(item) {
		const fixedTr = this.$fixedCol[0].rows[item.index];
		const tr = this.$main[0].rows[item.index];
		fixedTr.classList.remove('hidden');
		tr.classList.remove('hidden');
	},

	_buildCell(column, item) {
		const cell = document.createElement('td');
		const html = column.formatter.call(column, null, item);
		cell.innerHTML = html;
		return cell;
	},

	expandAll() {
		_.each(this.viewData, this._expand.bind(this));
		this._recalculateFixedColHeight();
	},

	collapseAll() {
		_.each(this.originalData, this._collapse.bind(this));
		this._recalculateFixedColHeight();
	}

});
