import { translate } from '../common/service/stringResourceService'
import { multilingualStringService } from '../common/service/multilingualStringService'
import { buildDefaultPopover } from '../common/components/utils'

var docs = {
  'applications': '4611686018427448473',
  'scheduler': '4611686018427446052',
  'custom-resources': '4611686018427443707',
  'entity-menu': '4611686018427406756',
  'entity-generator': '4611686018427443207',
  'api-function': '4611686018427467232',
  'merge': '4611686018427434110',
  'string-resources': '4611686018427406776',
  'permission': '4611686018427406752',
  'entity-types': '4611686018427448023',
  'entity-type-details': '4611686018427447347',
  'entity-block-construct': '4611686018427447347',
  'entity-field': '4611686018427445931',
  'shared-style': '4611686018427406643',
  'event-handler': '4611686018427447032',
  'entity-task': '4611686018427447102',
  'configuration-update': '4611686018427447236',
  'string-builder': '4611686018427406651',
  'row-builder': '4611686018427406647',
  'form-builder': '4611686018427406627',
  'theme-editor': '4611686018427897138',
  'fields-general': '4611686018427406599',
  'views-general': '4611686018427406623',
  'blocks-general': '4611686018427406711',
  'shared-fields': '4611686018427445931',
  'getting-started': '4611686018427406514',
  'user-roles': '4611686018427406744',
  'how-to-shared-style': '4611686018427464007',
  'how-to-type-details': '4611686018427463679',
  'how-to-data-types': '4611686018427463613',
  'how-to-welcome-page': '4611686018427463587',
  'how-to-permissions': '4611686018427463712',
  'how-to-menu-editor': '4611686018427463826',
  'how-to-sequences': '4611686018427463874',
  'how-to-string-resources': '4611686018427463925',
  'how-to-custom-resources': '4611686018427464080',
  'how-to-scheduler': '4611686018427464143',
  'how-to-api-functions': '4611686018427464199',
  'how-to-merge': '4611686018427464225',
  'how-to-settings': '4611686018427464277',
  'how-to-form-editor': '4611686018427464347',
  'how-to-shared-fields': '4611686018427463960',
  'how-to-block-editor': '4611686018427464413',
  'data-type-instances': '4611686018427446287',
  'how-to-theme-editor': '4611686018427897138',
  'release-notes': '4611686018428254062'
}

var userpilots = {
  'how-to-data-types': '1578645215hIhp1473',
  'how-to-permissions': '1578649756nFou1537',
  'how-to-menu-editor': '1578580340sGvv9309',
  'how-to-sequences': '1578671542lSeo8185',
  'how-to-primitive-formatters': '1578671705bQcn6742',
  'how-to-string-resources': '1578672038kYqk8389',
  'how-to-shared-fields': '1578673102fMsz6429',
  'how-to-shared-style': '1578904986nViu0576',
  'how-to-custom-resources': '1578901512hIxm1044',
  'how-to-scheduler': '1583928588xPlp1642',
  'how-to-api-functions': '1583928731tHky9715',
  'how-to-settings': '1583929395dFqi7956',
  'how-to-type-details': '1578647225jYnd8013',
  'how-to-form-editor': '1576940048pCnd7441',
  'how-to-row-editor': '1578902397oHpz2709',
  'how-to-string-view-editor': '1578901665rYlx8118',
  'how-to-welcome-page': '1578653230sCqc2989',
  'how-to-block-editor': '1578648723rRfq0815',
  'how-to-merge': '1583929559qUdh1512',
  'how-to-theme-listing': '1583928953gKpb0716',
  'how-to-theme-editor': '1583929177xCbo6859',
  'how-to-home-page': '1578653230sCqc2989'
}


var popovers = {
  'merge-tree-legend': {
    content: () => translate('merge.legend.help.popover')
  },
  'merge-filters': {
    content: () => translate('merge.filters.help.popover')
  },
  'fields': {
    name: 'fields',
    content: () => translate('add.new.name') + readMoreLink('fields-general')
  },
  'fields-form-builder': {
    content: () => translate('add.field.popover') + readMoreLink('fields-general')
  },
  'shared-fields': {
    content: () => translate('shared-fields.description')
  },
  'system-instances': {
    content: () => translate('add.new.system.instance') + readMoreLink('data-type-instances')
  },
  'blocks': {
    name: 'blocks',
    content: () => translate('blocks.description') + readMoreLink('blocks-general')
  },
  'preferences': {
    name: 'preferences',
    doc: 'entity-type-details'
  },
  /*
  'property-required': {
    content: () => translate('property.required')
  },
  'property-read-only': {
    content: () => translate('property.read.only')
  },
  'property-comment': {
    content: () => translate('property.comment')
  },
  */
  'views': {
    name: 'views',
    content: () => translate('views.description') + readMoreLink('views-general')
  },
  'categories': {
    content: () => translate('categories.description')
  },
  'add-types': {
    content: () => translate('add.new.data.type.tip') + "<a href='https://en.wikipedia.org/wiki/Data_type' tagret='_blank'>Wikipedia.</a>"
  },
  'add-userrole': {
    content: () => translate('add.user.role.description')
  },
  'reorder-button': {
    content: () => translate('enable.draganddrop')
  },
  'search-button': {
    content: () => translate('find') + ' ' + translate('types') + '.'
  },
  'add-text': {
    content: () => translate('add.static.fragment')
  },
  'preferences-edit': {
    content: () => translate('preferences-edit.description')
  },
  'preview-mode': {
    content: () => translate('toggle.preview')
  },
  'property-classes': {
    content: () => translate('property.classes')
  },
  'property-css': {
    content: () => translate('property.css')
  },
  'property-shared-styles': {
    content: () => translate('property.shared.styles')
  },
  'property-identifier': {
    content: () => translate('property.identifier')
  },
  'property-read-only-view': {
    content: () => translate('property.read.only.view')
  },
  'form-property-read-only': {
    content: () => translate('form.property.read.only')
  },
  'form-property-comment': {
    content: () => translate('form.property.comment')
  },
  'row-property-required': {
    content: () => translate('row.property.required')
  },
  'binary-property-max': {
    content: () => translate('binary.property.max')
  },
  'property-width': {
    content: () => translate('property.width')
  },
  'form-components-drag': {
    name: 'components',
    content: () => translate('form.name.drag')
  },
  'table-drag': {
    content: () => translate('table.drag')
  },
  'form-fields-drag': {
    name: 'fields',
    content: () => translate('form.name.drag')
  },
  'menu-components-drag': {
    name: 'components',
    content: () => translate('menu.name.drag')
  },
  'menu-types-drag': {
    content: () => translate('menu.types.drag.description')
  },
  'client-resources': {
    content: () => translate('client.resources.description')
  },
  'server-resources': {
    content: () => translate('server.resources.description')
  },
  'permission-ip-whitelist': {
    content: () => translate('permission.ip.whitelist.description')
  },
  'data-type-delete': {
    content: () => translate('data.type.delete.description')
  },
  'type-index-clear-filter': {
    content: () => translate('type.index.clear.filter.description')
  },
  'add-new-permission': {
    content: () => translate('add.new.permission.description')
  },
  'delete-menu-item': {
    content: () => translate('delete.menu.item.description')
  },
  'add-new-menu': {
    content: () => translate('add.new.menu.description')
  },
  'menu-item-action': {
    content: () => translate('menu.item.action.description')
  },
  'menu-item-permission': {
    content: () => translate('menu.item.permission.description')
  },
  'menu-undo-button': {
    content: () => translate('menu-undo-button.description')
  },
  'menu-redo-button': {
    content: () => translate('menu-redo-button.description')
  },
  'menu-item-view': {
    content: () => translate('menu-item-view.description')
  },
  'generator-create-initial': {
    content: () => translate('generator-create-initial.description')
  },
  'generator-create-period': {
    content: () => translate('generator-create-period.description')
  },
  'generator-create-unique': {
    content: () => translate('generator-create-unique.description')
  },
  'generator-create-shared': {
    content: () => translate('generator-create-shared.description')
  },
  'generator-create-timezone': {
    content: () => translate('generator-create-timezone.description')
  },
  'string-resource-create': {
    content: () => translate('string-resource-create.description')
  },
  'string-resource-delete': {
    content: () => translate('string-resource-delete.description')
  },
  'shared-field-transient': {
    content: () => translate('shared-field-transient.description')
  },
  'shared-field-create': {
    content: () => translate('shared-field-create.description')
  },
  'shared-field-delete': {
    content: () => translate('shared-field-delete.description')
  },
  'shared-style-css': {
    content: () => translate('shared-style-css.description')
  },
  'shared-style-create': {
    content: () => translate('shared-style-create.description')
  },
  'shared-style-delete': {
    content: () => translate('shared-style-delete.description')
  },
  'scheduler-about-create': {
    content: () => translate('scheduler-about-create.description')
  },
  'scheduler-create-item': {
    content: () => translate('scheduler-create-item.description')
  },
  'scheduler-about-cron-expression': {
    content: () => translate('scheduler-about-cron-expression.description') + "<a href='https://docs.oracle.com/cd/E12058_01/doc/doc.1014/e12030/cron_expressions.htm' tagret='_blank'>Read more.</a>"
  },
  'api-function-about': {
    content: () => translate('api-function-about.description')
  },
  'api-function-create': {
    content: () => translate('api-function-create.description')
  },
  'api-function-create-input-type': {
    content: () => translate('api-function-create-input-type.description')
  },
  'api-function-create-output-type': {
    content: () => translate('api-function-create-output-type.description')
  },
  'api-function-create-url': {
    content: () => translate('api-function-create-url.description')
  },
  'api-function-create-permission': {
    content: () => translate('api-function-create-permission.description')
  },
  'settings-start-page-create-new': {
    content: () => translate('settings-start-page-create-new.description')
  },
  'settings-start-page-system': {
    content: () => translate('settings-start-page-system.description')
  },
  'settings-app-name': {
    content: () => translate('settings-app-name.description')
  },
  'settings-version': {
    content: () => translate('settings-version.description')
  },
  'settings-menu': {
    content: () => translate('settings-menu.description')
  },
  'settings-timezone': {
    content: () => translate('settings-timezone.description')
  },
  'settings-posting': {
    content: () => translate('settings-posting.description')
  },
  'settings-posting-single-document': {
    content: () => translate('settings-posting-single-document.description')
  },
  'settings-posting-single-transaction': {
    content: () => translate('settings-posting-single-transaction.description')
  },
  'settings-posting-separate-transaction': {
    content: () => translate('settings-posting-separate-transaction.description')
  },
  'settings-authentication-codejig': {
    content: () => translate('settings-authentication-codejig.description')
  },
  'settings-authentication-in-app': {
    content: () => translate('settings-authentication-in-app.description')
  },
  'settings-authentication-active-directory': {
    content: () => translate('settings-authentication-active-directory.description')
  },
  'settings-permission-tab-desc': {
    content: () => translate('settings-permission-tab-desc.description')
  },
  'settings-permission-default': {
    content: () => translate('settings-permission-default.description')
  },
  'settings-permission-search': {
    content: () => translate('settings-permission-search.description')
  },
  'settings-permission-jobs': {
    content: () => translate('settings-permission-jobs.description')
  },
  'settings-permission-personal-jobs': {
    content: () => translate('settings-permission-personal-jobs.description')
  },
  'settings-permission-export': {
    content: () => translate('settings-permission-export.description')
  },
  'settings-permission-import': {
    content: () => translate('settings-permission-import.description')
  },
  'settings-permission-document-timeline': {
    content: () => translate('settings-permission-document-timeline.description')
  },
  'settings-active-directory-tab-desc': {
    content: () => translate('settings-active-directory-tab-desc.description')
  },
  'settings-robots-text': {
    content: () => translate('settings-robots-text.description')
  },
  'preferences-permission': {
    content: () => translate('preferences-permission.description')
  },
  'preferences-menu': {
    content: () => translate('preferences-menu.description')
  },
  'preferences-sequence': {
    content: () => translate('preferences-sequence.description')
  },
  'preferences-custom-index': {
    content: () => translate('preferences-custom-index.description')
  },
  'preferences-categories': {
    content: () => translate('preferences-categories.description')
  },
  'preferences-tree-view': {
    content: () => translate('preferences-tree-view.description')
  },
  'preferences-blocks-can-modify': {
    content: () => translate('preferences-blocks-can-modify.description')
  },
  'preferences-system-instances-readonly': {
    content: () => translate('preferences-system-instances-read-only.description')
  },
  'property-data-source': {
    content: () => translate('property-data-source.description')
  },
  'property-has-create-new': {
    content: () => translate('property-has-create-new.description')
  },
  'property-has-view-link': {
    content: () => translate('property-has-view-link.description')
  },
  'property-placeholder': {
    content: () => translate('property-placeholder.description')
  },
  'property-show-in-extended-mode-only': {
    content: () => translate('property-show-in-extended-mode-only.description')
  },
  'property-type': {
    content: () => translate('property-type.description')
  },
  'property-upload': {
    content: () => translate('property-upload.description')
  },
  'property-preview': {
    content: () => translate('property-preview.description')
  },
  'property-link-action': {
    content: () => translate('property-link-action.description')
  },
  'property-zoom': {
    content: () => translate('property-zoom.description')
  },
  'property-name': {
    content: () => translate('property-name.description')
  },
  'preferences-report-data-type': {
    content: () => translate('preferences-report-data-type.description')
  },
  'report-create-data-type': {
    content: () => translate('report-create-data-type.description')
  },
  'property-show-header': {
    content: () => translate('property-show-header.description')
  },
  'property-show-toolbar': {
    content: () => translate('property-show-toolbar.description')
  },
  'property-client-page-title': {
    content: () => translate('property-client-page-title.description')
  },
  'property-client-page-head-entries': {
    content: () => translate('property-client-page-head-entries.description')
  },
  'property-custom-resources': {
    content: () => translate('property-custom-resources.description')
  },
  'property-read-only': {
    content: () => translate('property-read-only.description')
  },
  'property-static-response': {
    content: () => translate('property-static-response.description')
  },
  'property-use-static-response-for-crawler': {
    content: () => translate('property-use-static-response-for-crawler.description')
  },
  'property-use-static-response-for-browser': {
    content: () => translate('property-use-static-response-for-browser.description')
  },
  'property-style': {
    content: () => translate('property-style.description')
  },
  'property-before-custom-server-event': {
    content: () => translate('property-before-custom-server-event.description')
  },
  'property-custom-server-event': {
    content: () => translate('property-custom-server-event.description')
  },
  'property-after-custom-server-event': {
    content: () => translate('property-after-custom-server-event.description')
  },
  'property-link': {
    content: () => translate('property-link.description')
  },
  'property-comment': {
    content: () => translate('property-comment.description')
  },
  'property-comment-placement': {
    content: () => translate('property-comment-placement.description')
  },
  'property-disabled': {
    content: () => translate('property-disabled.description')
  },
  'property-hidden': {
    content: () => translate('property-hidden.description')
  },
  'property-initial-state': {
    content: () => translate('property-initial-state.description')
  },
  'property-solid': {
    content: () => translate('property-solid.description')
  },
  'property-collapse': {
    content: () => translate('property-collapse.description')
  },
  'property-max-row-count': {
    content: () => translate('property-max-row-count.description')
  },
  'property-can-add': {
    content: () => translate('property-can-add.description')
  },
  'property-can-delete': {
    content: () => translate('property-can-delete.description')
  },
  'property-can-reorder': {
    content: () => translate('property-can-reorder.description')
  },
  'property-hide-checkbox': {
    content: () => translate('property-hide-checkbox.description')
  },
  'property-link-form-view': {
    content: () => translate('property-link-form-view.description')
  },
  'property-required': {
    content: () => translate('property-required.description')
  },
  'property-hide-title-xs': {
    content: () => ''
  },
  'property-align': {
    content: () => translate('property-align.description')
  },
  'property-icon': {
    content: () => translate('property-icon.description')
  },
  'property-icon-color': {
    content: () => translate('property-icon-color.description')
  },
  'deployment.READ_ONLY.options': {
    content: () => translate('deployment.READ_ONLY.options') + readMoreLink('data-type-instances')
  },
  'deployment.ALL.options': {
    content: () => translate('deployment.ALL.options')
  },
  'deployment.CUSTOM.options': {
    content: () => translate('deployment.CUSTOM.options')
  },
  'property-multiline': {
    content: () => translate('property-multiline.description')
  },
  'property-has-post-column': {
    content: () => translate('property-has-post-colum.description')
  },
  'property-has-folder-tree': {
    content: () => translate('property-has-folder-tree.description')
  },
  'property-use-tree-only': {
    content: () => translate('property-use-tree-only.description')
  },
  'property-is-tree-selectable': {
    content: () => translate('property-is-tree-selectable.description')
  },
  'property-show-all-children': {
    content: () => translate('property-show-all-children.description')
  },
  'add-whitelist': {
    content: () => translate('add.whitelist.description')
  },
  'settings-whitelists-tab-desc': {
    content: () => translate('settings-whitelists-tab-desc.description')
  },
  'settings-start-with-action': {
    content: () => translate('settings.start.with.action.description')
  },
  'settings-start-with-data-type': {
    content: () => translate('settings.start.with.data.type.description')
  },
  'settings-start-with-instance': {
    content: () => translate('settings.start.with.instance.description')
  },
  'settings-start-with-view': {
    content: () => translate('settings.start.with.view.description')
  },
  'settings-routes-tab-desc': {
    content: () => translate('settings-routes-tab-desc.description')
  },
  'system-instance-readonly': {
    content: () => translate('system.instance.read.only.description')
  },
  'primitive-formatters-about': {
    content: () => translate('primitive-formatters-about.description')
  },
  'preferences-formatter': {
    content: () => translate('preferences-formatter.description')
  },
  'select.application.version.for.deploy.help': {
    content: () => translate('select.application.version.for.deploy.help')
  },
  'property-select-width': {
    content: () => translate('property-select-width.description')
  },
  'theme-create': {
    content: () => translate('theme-create.description')
  },
  'theme-delete': {
    content: () => translate('theme-delete.description')
  },
  'settings-theme': {
    content: () => translate('settings-theme.description')
  },
  'property-valign': {
    content: () => translate('property-valign.description')
  },
  'cross-origin': {
    content: () => translate('cross-origin.description')
  },
  'menu-item-unavailable-module-mode': {
    content: () => translate('menu.item.unavailable.module.mode.description')
  },
  'property-seed-instances': {
    content: () => translate('property-seed-instances.description')
  },
  'property-track-changes-on-page': {
    content: () => translate('property-track-changes-on-page.description')
  },
  'property-css-theme': {
    content: () => translate('property-css-theme.description')
  },
  'property-create-new-view': {
    content: () => translate('property-create-new-view.description')
  },
  'property-open': {
    content: () => translate('property-open.description')
  },
  'property-row-height': {
    content: () => translate('property-row-height.description')
  },
  'property-selection-type': {
    content: () => translate('property-selection-type.description')
  },
  'property-widget-height': {
    content: () => translate('property-widget-height.description')
  },
  'property-columns-quantity': {
    content: () => translate('property-columns-quantity.description')
  },
  'property-page-size': {
    content: () => translate('property-page-size.description')
  },
  'property-width-criteria': {
    content: () => translate('property-width-criteria.description')
  },
  'property-duration-formatter': {
    content: () => translate('property-duration-formatter.description')
  },
  'property-formatter': {
    content: () => translate('property-formatter.description')
  },
  'property-client-view': {
    content: () => translate('property-client-view.description')
  },
  'property-load-in-edit-mode': {
    content: () => translate('property-load-in-edit-mode.description')
  },
  'property-field-type': {
    content: () => translate('property-field-type.description')
  },
  'property-field-name': {
    content: () => translate('property-field-name.description')
  },
  'property-hide-fillfunctions': {
    content: () => translate('property-hide-fillfunctions.description')
  },
  'property-min-date': {
    content: () => translate('property-min-date.description')
  },
  'property-max-date': {
    content: () => translate('property-max-date.description')
  },
  'property-add-action': {
    content: () => translate('property-add-action.description')
  },
  'property-dropdown-align': {
    content: () => translate('property-dropdown-align.description')
  },
  'property-header': {
    content: () => translate('property-header.description')
  },
  'property-label': {
    content: () => translate('property-label.description')
  },
  'property-field-label': {
    content: () => translate('property-field-label.description')
  },
  'property-min': {
    content: () => translate('property-min.description')
  },
  'property-max': {
    content: () => translate('property-max.description')
  },
  'property-pattern': {
    content: () => translate('property-pattern.description')
  },
  'property-template': {
    content: () => translate('property-template.description')
  },
  'property-view': {
    content: () => translate('property-view.description')
  },
  'property-title': {
    content: () => translate('property-view.description')
  },
  'save-block-desc': {
    content: () => translate('save.block.description')
  },
  'load-block-desc': {
    content: () => translate('load.block.description')
  },
  'loaded-block-desc': {
    content: () => translate('loaded.block.description')
  },
  'validate-block-desc': {
    content: () => translate('validate.block.description')
  },
  'delete-block-desc': {
    content: () => translate('delete.block.description')
  },
  'tree-change-block-desc': {
    content: () => translate('tree.change.block.description')
  },
  'after-import-block-desc': {
    content: () => translate('after.import.block.description')
  },
  'test-block-desc': {
    content: () => translate('test.block.description')
  },
  'build-block-desc': {
    content: () => translate('build.block.description')
  },
  'document-post-block-desc': {
    content: () => translate('document.post.block.description')
  },
  'get-data-leaves-block-desc': {
    content: () => translate('get.data.leaves.block.description')
  },
  'field-change-block-desc': {
    content: () => translate('field.change.block.description')
  },
  'field-changed-block-desc': {
    content: () => translate('field.changed.block.description')
  },
  'value-suggest-block-desc': {
    content: () => translate('value.suggest.block.description')
  },
  'value-suggested-block-desc': {
    content: () => translate('value.suggested.block.description')
  },
  'validate-field-block-desc': {
    content: () => translate('validate.field.block.description')
  },
  'collection-inserted-block-desc': {
    content: () => translate('collection.inserted.block.description')
  },
  'collection-deleted-block-desc': {
    content: () => translate('collection.deleted.block.description')
  },
  'row-click-block-desc': {
    content: () => translate('row.click.block.description')
  },
  'row-clicked-block-desc': {
    content: () => translate('row.clicked.block.description')
  },
  'field-filtered-block-desc': {
    content: () => translate('field.filtered.block.description')
  }
}

export function userPilotByDocsResource(key) {
  return userpilots[key]
}

export function urlByDocsResource(key){
  if (!docs[key]) throw new Error('No doc found by key ' + key)
  return docsUrlById(docs[key])
}

export function articleByDocsResource(key){
  if (!docs[key]) throw new Error('No article found by key ' + key)
  return app.utils.getRequest(app.urls.home + `docs-resource/${docs[key]}`)
}

function inDocumentationLink(docsKey) {
  return ' <a target="_blank" href="'+ docsUrlById(docs[docsKey]) + '">' + translate('in.documentation') + '</a>.'
}

function readMoreLink(docsKey) {
  return ' <a target="_blank" href="'+ docsUrlById(docs[docsKey]) + '">' + translate('read.more') + '</a>.'
}

function addNewNameAndReadMore(){
  return translate('add.new.name') + ' ' + translate('read.more.about')
}

function docsUrlById(id) {
  return `https://docs.codejig.com/en/entity2305843015656313960/view/${id}`
}

function contentForHelpPopover(key){
  if (!popovers[key]) throw new Error('No help popover found by key ' + key)
  return multilingualStringService.formatSystemString(popovers[key].content(), [translate(popovers[key].name)])
}

export function buildHelpPopover(elem){
  buildDefaultPopover($(elem), {
    toggle: 'popover',
    content: contentForHelpPopover(elem.getAttribute('help-popover')),
    html: true,
    delay: 1000,
    container:'body',
    placement: (window.matchMedia("(max-width: 767px)").matches)? 'bottom': 'left auto'
  })
}

export function initHelpPopovers () {
  document.querySelectorAll('[help-popover]').forEach(function (elem) {
    buildHelpPopover(elem)
  })
}
