import BaseCreateView from '../../common/views/baseCreateView';

export default class CreateView extends BaseCreateView {

	initialize(options) {
		super.initialize(options);
	}

	getHTML() {
		 return `<div class="grid-1x-padding">
							<label for="name">${app.getResource('name')}</label>
						</div>
						<div class="grid-2x-padding">
							<input class="form-control" type="text" data-is-string-multilingual="short" data-field="name"/>
						</div>
						`;
	}

	saveConfiguration() {
			let data = this.model.toJSON();
			return data;
	}
}
