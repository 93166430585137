/* @flow */
import MultilingualStringResource from '../models/multilingualStringResource';
import FormOption from '../../common/enums/formOption.js';
import { EntityState } from '../enums/entityState';
import Constants from '../models/constants';
import { stringViewService } from '../service/stringViewService'
import ModelFactory from '../models/modelFactory';
import MultilingualString from '../models/multilingualString';
import Pairs from '../collections/pairs';

import utils, {showInstance} from './utils.js';

const data = {};

const stringViewMap = {};

const formViewMap = {};

const addStringView = (
		viewId: ?string,
		metaObjectId: string,
		stringView: string): void => {

	// $SuppressFlow: it can
	stringViewMap[viewId] = stringViewMap[viewId] || {};
	// $SuppressFlow
	stringViewMap[viewId][metaObjectId] = stringView;
};

const mergeStringViewMap = (map: Dictionary<Dictionary<string>>): void  => {
	$.each(map, (viewId, viewMap) => {
		$.each(viewMap, (metaObjectId, stringView) => {
			addStringView(viewId, metaObjectId, stringView)
		});
	});
};

type StringViewRequest = {id: string, viewId: string};

/**
 * Available as `app.entityManager`
 * @namespace entityManager
 */
var entityManager = {

	mergeStringViewMap: mergeStringViewMap,

	stringViewRequests: {},

	_fetch: function (typeId: string, metaObjectId: string): Promise<EntityJSON> {
		return new Promise(function (resolve, reject) {
			if (metaObjectId == null) {
				reject();
			} else if (data.hasOwnProperty(metaObjectId)) {
				resolve(data[metaObjectId]);
			} else if (typeId == Constants.ID_TYPE_TYPE) {
				resolve(app.types.get(metaObjectId).attributes);
			} else if (typeId == Constants.ID_TYPE_FIELD) {
				resolve(app.fields.get(metaObjectId).attributes);
			} else if (typeId == Constants.ID_TYPE_LANGUAGE) {
				resolve(app.languages.find(lang => lang.id === metaObjectId));
			} else if (typeId == Constants.ID_TYPE_APP_USER_ACCOUNT
				&& metaObjectId && app.currentUser
				&& metaObjectId === app.currentUser.id) {
				resolve(app.currentUser);
			} else if (typeId == Constants.ID_TYPE_CONFIGURATION
				&& metaObjectId === app.configuration.id) {
				resolve(app.configuration);
			} else {
				utils.ajaxRequest(null, app.urls.find(typeId, metaObjectId),
					'get', resolve, reject, true);
			}
		}).then(function (result: EntityJSON) {
			result.entityTypeId = typeId;
			data[metaObjectId] = result;
			return result;
		});
	},

	/**
	 * Fetch string resource by id
	 * @param resourceId {string} Identifier
	 * @returns {Promise}
	 */
	fetchResource: function (resourceId: string): Promise<MultilingualStringResource> {
		return new Promise(function (resolve, reject) {
			if (resourceId == null) {
				reject();
			} else if (app.userStringResources.has(resourceId)) {
				resolve(app.userStringResources.get(resourceId))
			} else {
				return utils.request(resourceId, app.urls.findResource, 'post').then(response => {
					resolve(response);
				});
			}
		}).then(function (data) {
			return new MultilingualStringResource(data);
		});
	},

	/**
	 * Fetch string resource by key
	 * @param resourceKey {string} Key
	 * @returns {Promise}
	 */
	fetchResourceByKey (resourceKey) {
		return utils.postPlainText(resourceKey, app.urls.findResourceByKey).then(response => {
			return new MultilingualStringResource(response)
		})
	},

	fetchById: function (typeId: string, metaObjectId: string): Promise<EntityJSON> {
		return this._fetch(typeId, metaObjectId);
	},

	fetchRowView: function (typeId: string, viewId: string, metaObjectId: string): Promise<EntityJSON> {
		return new Promise(function (resolve, reject) {

			var onSuccess = function (response) {
				data[metaObjectId] = response;
				resolve(response);
			};

			if (viewId) {
				utils.ajaxRequest(null, app.urls.findAndRenderOne + '/' + metaObjectId + '/' +
					viewId, 'get', onSuccess, reject, true);
			} else {
				utils.ajaxRequest(null, app.urls.findAndDefaultRenderOneRowView + '/' +
					metaObjectId + '/' + typeId, 'get', onSuccess, reject, true);
			}
		});
	},

	fetchFormView: function (viewId)  {
			const formView = this.getFormView(viewId);
			if (formView) {
				return formView
			} else {
				var request = new XMLHttpRequest()
				request.open('GET', app.urls.home+`entityView/${viewId}`, false)
				var CSRFtoken = $("meta[name='_csrf']").attr("content");
				var CSRFheader = $("meta[name='_csrf_header']").attr("content");
				if(CSRFheader) {
					request.setRequestHeader(CSRFheader, CSRFtoken)
				}
				request.send(null)
				let result = JSON.parse(request.responseText)
				formViewMap[viewId]=result
  			return result
			}
	},

	fetchStringView: function (viewId: ?string, metaObjectId: string): Promise<string> {
		return new Promise((resolve, reject) => {
			this._fetchStringView(viewId, metaObjectId, resolve, reject);
		});
	},

	_fetchStringView: function (viewId: ?string, metaObjectId: string, resolve, reject): Promise<string> {
		const stringView = this.getStringView(viewId, metaObjectId);
		if (stringView || !metaObjectId) {
			resolve && resolve(stringView);
		} else {
			const mapViewId = viewId || null;
			const metaObjectsMap = this.stringViewRequests[mapViewId] || {};
			this.stringViewRequests[mapViewId] = metaObjectsMap;
			metaObjectsMap[metaObjectId] = metaObjectsMap[metaObjectId] || [];
			if (resolve) {
				metaObjectsMap[metaObjectId].push({resolve, reject});
			}

			if (!app.isNodeJsEnvironment) {
				this._postponeStringViewsFetch();
			}
		}
	},

	_postponeStringViewsFetch: function () {
		if (this._postponeStringViews) {
			clearTimeout(this._postponeStringViews)
		}
		this._postponeStringViews = setTimeout(() => this.fetchStringViews(), 200)
	},

	_fetchStringViewsOnSuccess: function (result: Dictionary<Dictionary<string>>): void {
		const stringViewRequests = this.map;
		_.each(stringViewRequests, (map, viewId) => {
			_.each(map, (handlers, metaObjectId) => {
				const metaObjects = result[viewId];
				if (metaObjects) {
					let stringView = metaObjects[metaObjectId];
					if (typeof stringView !== 'undefined') {
						stringView = stringViewService.getValue(stringView)
						addStringView(viewId, metaObjectId, stringView)
						_.each(handlers, h => {
							h.resolve(stringView);
						});
					} else {
						_.each(handlers, h => {
							h.reject();
						});
					}
				} else {
					_.each(handlers, h => {
						h.reject();
					});
				}
			});
		});
		this.request.resolve();
	},

	_fetchStringViewsOnError: function (): void {
		const stringViewRequests = this.map;
		_.each(stringViewRequests, (map, viewId) => {
			_.each(map, (handlers, metaObjectId) => {
				_.each(handlers, h => {
					h.reject();
				});
			});
		});
		this.request.reject();
	},

	// Loads all pending stringviews
	fetchStringViews: function (): Promise<void> {
		return new Promise((resolve, reject) => {
			if (!_.isEmpty(this.stringViewRequests)) {
				const context = {
					map: this.stringViewRequests,
					request: {resolve, reject}
				};
				const requestData = _.mapObject(this.stringViewRequests, _.keys);
				utils.ajaxRequest(
					requestData,
					app.urls.findManyStringViews,
					'post',
					this._fetchStringViewsOnSuccess,
					this._fetchStringViewsOnError,
					true,
					context);
				this.stringViewRequests = {};
			} else {
				resolve();
			}
		});
	},

	fetchManyStringViews(requests: Array<StringViewRequest>): void {
		_.each(requests, request => {
			this._fetchStringView(request.viewId, request.id);
		});
	},

	fetchPageOfRowViews: function (typeId: string, viewId: string, moreStruct: MoreRowViewStructure): Promise<Array<EntityJSON>> {
		return new Promise(function (resolve, reject) {

			var onSuccess = function (data) {
				resolve(data);
			};
			if (viewId) {
				utils.ajaxRequest(moreStruct, (app.urls.loadMore || ('/' + app.currentLanguage + '/entity' + typeId + '/getPageOfRows')) + '/' +
					typeId + '/' + viewId + '/?runBlocks=true', 'post', onSuccess, reject, true);
			} else {
				utils.ajaxRequest(moreStruct, (app.urls.loadMore || ('/' + app.currentLanguage + '/entity' + typeId + '/getPageOfRows')) + '/' +
					typeId + '/?runBlocks=true', 'post', onSuccess, reject, true);
			}
		}).catch(function (response) {
			utils.error(response);
			return Promise.reject(response);
		});
	},

	fetchPageOfStringViews: function (typeId: string, viewId: string, moreStruct: MoreStringViewStructure): Promise<Dictionary<string>> {
		return new Promise(function (resolve, reject) {
			const onSuccess = (arr) => {
				let values = _.mapObject(arr, stringViewService.getValue)
				mergeStringViewMap({[viewId]: values})
				resolve(values)
			};

			if (viewId) {
				utils.ajaxRequest(moreStruct, app.urls.loadMoreWithStringView + '/' +
					typeId + '/' + viewId, 'post', onSuccess, reject, true);
			} else {
				utils.ajaxRequest(moreStruct, app.urls.loadMoreWithStringView + '/' +
					typeId, 'post', onSuccess, reject, true);
			}
		}).catch(function (response) {
			utils.error(response);
			return Promise.reject(response);
		});
	},

	invokeServerChange: function (fieldId: string, subFieldId: ?string, relativeOrder: ?number, context): Promise<any> {
		console.log('invoke server change for ', fieldId);
		const data = context.getServerData();
		let userAction = !data.id ? 'create' : 'update';
		const url = app.urls.invoke.change(context.type.id, fieldId, subFieldId, relativeOrder, userAction);
		return utils.postRequest(data, url).then(json => {
			console.log('server change after post');
			context.model.merge(json, context.data);
			this.fetchStringViews();
		});
	},

	invokeServerSuggest(fieldId: string, subFieldId: ?string, relativeOrder: ?number, context, resultCollection): Promise<any> {
		console.log('invoke server suggest for ', fieldId);
		const data = context.getServerData();
		const url = app.urls.invoke.suggest(context.type.id, fieldId, subFieldId, relativeOrder);
		return utils.postRequest(data, url).then(json => {
			console.log('server suggest after post');
			const valueFieldId = subFieldId || fieldId;
			this._transformSuggestions(valueFieldId, json, resultCollection);
		});
	},

	_transformSuggestions(valueFieldId, data, resultCollection) {
		const field = app.fields.get(valueFieldId);
		const Model = ModelFactory.getModelTypeByField(field);

		_.each(data, item => {
			let value = item.one;
			if (Model) {
				value = Model.fromJSON(value);
			}
			const label = MultilingualString.fromJSON(item.two);
			resultCollection.add({one: value, two: label});
		});
	},

	invokeChange: function (fieldId: string, subFieldId: ?string, relativeOrder: ?number, context): Promise<any> {
		return app.tasksQueue.add(() => {
			return this.invokeServerChange(fieldId, subFieldId, relativeOrder, context)
				.then((item, changed) => {
					resolve(item, changed);
				});
		});
	},

	invokeServerCustomEventFunction: function (blockId: string, context, runInUpdateQueue): Promise<any> {
		const data = context.getServerData();
		let url = app.urls.invoke.customEvent(context.type.id, blockId);
		//TODO::remove this hack
		if (runInUpdateQueue) {
			url += "?runInUpdateQueue=true";
		}
		return utils.postRequest(data, url).then(json => {
			this.evictStringViews(json);
			context.model.merge(json, context.data);
			this.fetchStringViews();
		});
	},

	invokeServerRowClickFunction: function (fieldId: string, rowClickedId: string, context): Promise<any> {
		const data = context.getServerData();
		const url = app.urls.invoke.rowClick(context.type.id, fieldId, rowClickedId);
		return utils.postRequest(data, url).then(json => {
			this.evictStringViews(json);
			context.model.merge(json, context.data);
			this.fetchStringViews();
		});
	},

	invokeClientCustomEventFunction: function (blockId: string, context): Promise<any> {
		let func = app.userObservers.getCustomEventFunction(blockId);
		let result = func.call(context, context.model)
		this.fetchStringViews()
		return result
	},

	/**
 	 * Invoke predefined server event
	 * @param {string} serverEventId - one of __FormOption__ enum values
	 * DOCUMENT_POST, DOCUMENT_UNPOST, SHOW_REGISTERS,
	 * CLONE, SHOW_HISTORY, DOWNLOAD, DELETE, SAVE, SAVE_AND_CLOSE,
	 * CANCEL_AND_CLOSE, CANCEL, DOWNLOAD_SPREADSHEET, DOWNLOAD_XML,
	 * CUSTOMIZE, BUILD
 	 * @returns {Promise<any>}
 	 */
	invokePredefinedServerEvent(serverEventId, context): Promise<any> {
		let wizardView = context.wizardView;
		switch(serverEventId) {
			case FormOption.DOCUMENT_POST:
				return wizardView.post();
			case FormOption.DOCUMENT_UNPOST:
				return wizardView.unpost();
			case FormOption.SHOW_REGISTERS:
				return wizardView.showRegisters();
			case FormOption.SHOW_HISTORY:
				return context.historyView.showHistory();
			case FormOption.PRINT:
				return wizardView.print();
			case FormOption.SHARE:
				return wizardView.share();
			case FormOption.DOWNLOAD:
				return wizardView.download();
			case FormOption.DELETE:
				return wizardView.delete();
			case FormOption.SAVE:
				return wizardView.save();
			case FormOption.LIST_LINK:
				return wizardView.listLink();
			case FormOption.SAVE_AND_CLOSE:
				return wizardView.saveAndClose();
			case FormOption.CLONE:
				return this.invokeClone(context);
			case FormOption.CANCEL_AND_CLOSE:
				return wizardView.close();
			case FormOption.CANCEL:
				return wizardView.cancel();
			case FormOption.DOWNLOAD_SPREADSHEET:
				return wizardView.downloadSpreadsheet();
			case FormOption.DOWNLOAD_XML:
				return wizardView.downloadXml();
			case FormOption.DOWNLOAD_DOC:
				return wizardView.downloadDoc();
			case FormOption.EXPORT_REPORT_TO_XLS:
				return wizardView.exportReportToExcel();
			case FormOption.DOWNLOAD_PDF:
				return wizardView.downloadPdf();
			}
	},

	invokeCustomEvent: function (blocks: Object, context, runInUpdateQueue) {
		return app.tasksQueue.add(() => {
				let beforeServerEventBlockId = blocks.beforeServerEventBlockId;
				let serverEventBlockId = blocks.serverEventBlockId;
				let afterServerEventBlockId = blocks.afterServerEventBlockId;
				let invokedEvent = Promise.resolve();

				if (beforeServerEventBlockId) {
					invokedEvent = invokedEvent.then(() => this.invokeClientCustomEventFunction(beforeServerEventBlockId, context))
				}
				if (serverEventBlockId) {
					if (FormOption.getValues().includes(serverEventBlockId)) {
						invokedEvent = invokedEvent.then(() => this.invokePredefinedServerEvent(serverEventBlockId, context))
					} else {
						invokedEvent = invokedEvent.then(() => this.invokeServerCustomEventFunction(serverEventBlockId, context, runInUpdateQueue))
					}
				}
				if (afterServerEventBlockId) {
					invokedEvent = invokedEvent.then(() => this.invokeClientCustomEventFunction(afterServerEventBlockId, context))
				}
				return invokedEvent;
		});
	},

	invokeServerFormLoad: function (context): Promise<any> {
		const url = app.urls.invoke.formLoad(context.type.id, context.viewId);
		return utils.postRequest(() => context.getServerData(), url).then(json => {
			context.model.merge(json, context.data);
			this.fetchStringViews();
			return viewId;
		});
	},

	invokeClientFormLoad: function (context): Promise<any> {
		let func = app.userObservers.getFormLoadFunction(`clientFormLoad${context.viewId}`);
		return func && func.call(context, context.model);
	},

	invokeFormLoad: function (context): Promise<any> {
		let func = app.userObservers.getFormLoadFunction(context.viewId);
		return func.call(context, context.model);
	},

	invokePageLoad: function (context): Promise<any> {
		let func = app.userObservers.getPageLoadFunction(context.type.id);
		return func && func.call(context, context.model);
	},

	invokeRowLoad: function (viewId: string, item: any): Promise<any> {
		let func = app.userObservers.getRowLoadFunction(viewId);
		return func && func.call(context, item);
	},

	fetchPageOfDocuments: function (moreStruct: MoreDocumentsStructure, documentTimelineId: string): any {

		return new Promise(function (resolve, reject) {

			var onSuccess = function (list) {
				// var docList = [];
				// _.each(list, function (pair) {
				// 	mergeStringViewMap(pair.two);
				// 	docList.push(pair.one);
				// });

				resolve(list);
			};
			var url = documentTimelineId == null ?
				app.urls.getPageOfDocuments :
				app.urls.getPageOfDocuments + '/' + documentTimelineId;

			utils.ajaxRequest(moreStruct, url,
				'post', onSuccess, reject, true);

		}).catch(function (response) {
			utils.error(response);
			return Promise.reject(response);
		});
	},

	// returns existing value, or undefined if entity is not in cache
	get: function (metaObjectId: string): ?EntityJSON {
		return data[metaObjectId];
	},

	evictStringViews: function(json: any): void {
		_.each(json, v => {
			if (v){
				if (_.isArray(v)){
					this.evictStringViews(v);
					_.each(v, inner => {
						this.evictStringViews(inner);
					});
				} else if (v.serverState == EntityState.UPDATED){
					this.evictStringView(v.id);
				}
			}
		});
	},

	evictStringView: function(objectId: string): void {
		_.each(stringViewMap, (v, viewId) => {
			if (v[objectId]) {
				delete v[objectId];
				this.fetchStringView(viewId, objectId);
			}
		});
	},

	getFormView: function (viewId: string): object {
		return formViewMap[viewId]
	},

	getStringView: function (viewId: string, metaObjectId: string): ?string {
		return stringViewMap[viewId] &&
			stringViewMap[viewId][metaObjectId];
	},

	fetchReport: function (report: any): Promise<any> {
		return utils.postRequest(report, app.urls.buildReport(report.entityTypeId));
	},

	invokeClone: function (context) {
		return showInstance({
			typeId: context.type.id,
			fillInstanceId: context.objectId,
			viewId: context.viewId,
			openMode: 'new.cj.tab',
			callback: () => {}
		});
	}
};

export default entityManager;
