export default class TaskKind {
  static SYSTEM = 'SYSTEM';
  static SYSTEM_BUILD = 'SYSTEM_BUILD';
  static DEPLOY = 'DEPLOY';
  static CREATE = 'CREATE';
  static UPDATE = 'UPDATE';
  static DELETE = 'DELETE';
  static DOCUMENT_APPLY = 'DOCUMENT_APPLY';
  static DOCUMENT_RELIEVE = 'DOCUMENT_RELIEVE';
  static REPORT_BUILD = 'REPORT_BUILD';
  static STRING_VIEW_BUILD = 'STRING_VIEW_BUILD';
  static MOVE = 'MOVE';
  static REORDER = 'REORDER';
  static CONSTRUCT = 'CONSTRUCT';
  static INVOKE_TRIGGER = 'INVOKE_TRIGGER';
  static RESTORE = 'RESTORE';
  static FORM_GET_NEXT = 'FORM_GET_NEXT';
  static CHANGE_TYPE = 'CHANGE_TYPE';
  static REMOVE_FIELD_FROM_SUBFORMS = 'REMOVE_FIELD_FROM_SUBFORMS';
  static GET_INSTANCES = 'GET_INSTANCES';
  static ON_OPEN = 'ON_OPEN';
  static ON_CREATE = 'ON_CREATE';
  static INVOKE_CHANGE = 'INVOKE_CHANGE';
  static CUSTOM_EVENT = 'CUSTOM_EVENT';
  static FORM_LOAD = 'FORM_LOAD';
  static MERGE = 'MERGE';
  static EXPORT = 'EXPORT';
  static IMPORT = 'IMPORT';
  static BLOCKS_REBUILD = 'BLOCKS_REBUILD';
  static UPDATE_TRANSLATION = 'UPDATE_TRANSLATION';
  static PERIODIC = 'PERIODIC';
  static MIGRATION = 'MIGRATION';
  static PROCESS_MESSAGE = 'PROCESS_MESSAGE';
  static RESTART = 'RESTART';
  static LOGIN = 'LOGIN';
  static LOGOUT = 'LOGOUT';
  static NODE_BUILD = 'NODE_BUILD';
  static DATABASE_DELETE = 'DATABASE_DELETE';

  static getAll() {
    return [
      this.SYSTEM,
      this.SYSTEM_BUILD,
      this.DEPLOY,
      this.CREATE,
      this.UPDATE,
      this.DELETE,
      this.DOCUMENT_APPLY,
      this.DOCUMENT_RELIEVE,
      this.REPORT_BUILD,
      this.STRING_VIEW_BUILD,
      this.MOVE,
      this.REORDER,
      this.CONSTRUCT,
      this.INVOKE_TRIGGER,
      this.RESTORE,
      this.FORM_GET_NEXT,
      this.CHANGE_TYPE,
      this.REMOVE_FIELD_FROM_SUBFORMS,
      this.GET_INSTANCES,
      this.ON_OPEN,
      this.ON_CREATE,
      this.INVOKE_CHANGE,
      this.CUSTOM_EVENT,
      this.FORM_LOAD,
      this.MERGE,
      this.EXPORT,
      this.IMPORT,
      this.BLOCKS_REBUILD,
      this.UPDATE_TRANSLATION,
      this.PERIODIC,
      this.MIGRATION,
      this.PROCESS_MESSAGE,
      this.RESTART,
      this.LOGIN,
      this.LOGOUT,
      this.NODE_BUILD,
      this.DATABASE_DELETE
    ]
  }
}
