global.globalize = Globalize

import Menu from '../common/components/menu';
import utils, { farFutureDate, addCookie, loadMomentJsTimezonePackage } from '../common/components/utils';
import BuildView from '../common/views/buildView';
import Views from '../common/collections/views';
import Blocks from '../common/collections/blocks';
import Types from '../common/collections/types';
import Fields from '../common/collections/fields';
import SearchBoxView from '../StringView/views/searchBoxView';
import TaskManager from '../common/service/taskManager';
import entityManager from '../common/components/entityManager'
import websocketClient from '../websocket/client';
import CreateModal from '../common/components/createModal';
import EditModal from '../common/components/editModal';
import ConfirmModal from '../common/components/confirmModal';
import ErrorsModal from '../common/components/errorsModal';
import BuilderInfo from '../info/builderInfo'
import ApplicationInfo from '../info/applicationInfo'
import NotificationManager from '../common/service/notificationManager';
import TasksQueue from '../common/components/tasksQueue'
import Formatter from '../common/components/formatter'
import resources from '../resources'
import Constants from '../common/models/constants.js'
import FieldKind from './enums/fieldKind'
import TypeKind from './enums/typeKind'
import StateRecovery from './components/stateRecovery'
import InstanceViewer from '../common/components/instanceViewer.js';
import { createNewMenu } from '../EntityMenu/components/menuList.js';
import { addTheme } from '../cssEditor/utils';
import { createNewPermission } from '../Permission/components/permissionList';
import '../common/components/jsTreeLoader'
import { addHrefToDocs, initHelpPopovers, addDocsModal, addUserPilots } from '../help/utils'
import { buildDefaultPopover, postAndRedirect } from '../common/components/utils'
import { showChangePassModal } from '../common/components/changePassModal'
import { translate } from './service/stringResourceService';
import { urls } from '../common/service/linkBuilder'
import LocalStorageKeysService from './service/localStorageKeysService'
import {
	Input,
	Select,
	MultilingualInput,
	Checkbox,
	Toggle,
	BinaryUploader
} from '@codejig/ui-components'

  import TableConnector from '../common/components/TableConnector'

  require('es6-promise').polyfill()
  import 'whatwg-fetch'

	global.Vue = Vue

	Vue.use({
		install (Vue) {
			Vue.prototype.$t = h => app.getResource(h)
		}
	})

  Vue.component('cjg-select', Select)
  Vue.component('cjg-input', Input)
  Vue.component('cjg-toggle', Toggle)

  global.TableConnector = TableConnector

  global.UI = {
	Components: {
	  Input,
	  Select,
	  MultilingualInput,
	  Checkbox,
	  Toggle,
	  BinaryUploader
	}
  }

_.extend(global.app, resources);

app.Formatter = Formatter
app.entityManager = entityManager
app.utils = utils
app.urls = app.urls || {}
app.urls = Object.assign(app.urls, urls)

export function initialize () {

  moment.locale(app.currentLanguage)
  Globalize.locale(app.currentLanguage)

	global.momentJsHelper = {
		loadTimezonePackage : loadMomentJsTimezonePackage
	}

  if (app.permissionChecks.showPersonalJobs) {
    $('.showPersonalJobs').show();
  }
  if (!app.permissionChecks.showSearch) {
    $('#search-box').remove();
    $('#search-btn').remove();
  }
  if (!app.permissionChecks.showSystemJobs) {
    $('.system-menu-item.systemJobs').remove();
  }
  if (!app.permissionChecks.showSettings) {
    $('.system-menu-item.settings').remove();
  }
  if (!app.permissionChecks.showAppUsers) {
    $('.system-menu-item.appUsers').remove();
  }

  languageSwitcherInit()
  changePasswordInit()
  contentWrapperFullPageSize();
	if ($("script.livezillaloader").length) {
		checkLivezillaChatExist()
	}

  if (!app.isForm) {
    app.hideLoading();
  }

  buildDefaultPopover($('.popover-tooltip'),(window.matchMedia("(max-width: 767px)").matches)?{placement:  'bottom'}: {})
  if (blockLog) {
    alert(blockLog);
  }
  $('.my-apps-btn').attr('href', app.urls.dashboard)
//moved from _layout.html
  $('.main-sidebar a').attr('tabindex','-1');

  $('.panel[data-level]').each(function (i, v) {
    switch ($(v).data('level')) {
      case 'TRACE':
        $(this).addClass('panel-primary');
        break;
      case 'DEBUG':
        $(this).addClass('panel-success');
        break;
      case 'INFO':
        $(this).addClass('panel-info');
        break;
      case 'WARN':
        $(this).addClass('panel-warning');
        break;
      case 'ERROR':
        $(this).addClass('panel-danger');
        break;
    }
  });

  $('.scrollable').perfectScrollbar({
    suppressScrollX: true
  });
  $('.is-translator').click(function (e) {
    var $chbox = $(e.currentTarget);
    localStorage.setItem('is-translator', $chbox.prop('checked'));
    location.reload();
  });
  $('.is-translator').prop('checked',
    JSON.parse(localStorage.getItem('is-translator')));

  $('body').on('click', function (e) {
      if ($(e.target).closest('.mce-container, .mce-widget').length) {
      e.stopPropagation();
    }
  });

	if (!$('.instance-view-wrapper').length) {
		let instanceViewWrapperDom = `    <div class="instance-view-wrapper">
		      <div id="instance-view">
		        <div class="loading" style="width: 100%; height: 100%; position: absolute; top: 0; left: 0;">
		          <div  class="preview-area" >
		            <div class="spinner">
		              <div class="dot1"></div>
		              <div class="dot2"></div>
		            </div>
		          </div>
		        </div>
		        <div class="instance"></div>
		        <div class="close-instance">
		          <span class="fa fa-times"></span>
		        </div>
		      </div>
		    </div>`
		if (app.builderMode) {
			if ($("#gridBuilderParent").length != 0) {
				$('.wrapper:not(.demo)').prepend(instanceViewWrapperDom)
			} else {
				$('#wrapper:not(.demo)').prepend(instanceViewWrapperDom)
			}
		} else {
			$('#wrapper:not(.demo)').prepend(instanceViewWrapperDom)
		}
	}

  var dropdownMenu;
  $(window).on('show.bs.dropdown', function (e) {
    if ($(e.target).hasClass('body-container')) {
      dropdownMenu = $(e.target).find('.dropdown-menu');
      $('body').append(dropdownMenu);
      //additive handler
      var handler= function(e) {
         dropdownMenu.hide()
         window.removeEventListener('click',handler)
      }
      window.addEventListener('click',handler)
    } else if (e.target.classList && e.target.classList.contains('btn-group')) {
			e.target.childNodes.forEach((node) => {
				if (node.classList && node.classList.contains('dropdown-menu')) {
					if (node.classList && node.classList.contains('dropdown-menu-right')){
						node.style.right = ''
						var left = e.target.offsetLeft
						var temp = e.target.offsetParent
						while (temp) {
							left += temp.offsetLeft
							temp = temp.offsetParent
							if (temp && (temp.id == "instance-view" || temp.classList && temp.classList.contains('form-builder-wrapper'))){
								temp = null
							}
						}
						node.style.visibility = 'hidden'
						node.style.display = 'block'
						var offsetLeft = node.offsetLeft
						node.style.display = ''
						node.style.visibility = ''
						var shift = left + offsetLeft
						if (shift < 0){
							node.style.right = `${shift}px`
						}
					}
				}
			})
		}
  });
  $(window).on('shown.bs.dropdown', function (e) {
    if ($(e.target).hasClass('body-container')) {
      dropdownMenu.css('display', 'block');
      var width = dropdownMenu.width();
      var height = dropdownMenu.height();
      var top = $(e.relatedTarget).offset().top + $(e.relatedTarget).outerHeight() -2;
      var left = $(e.relatedTarget).offset().left;
      var diffHeight = $(window).height() - height - top;
      var diffWidth = $(window).width() - left - width;
      top = top + (diffHeight < 15 ? - $(e.target).height() - height - 15 : 0)
      if (top < 0 && $(e.target).hasClass('type-edit-controll')) {
        top = 0
      }
      dropdownMenu.css('top', top)
      if ($(e.target).hasClass('put-down')){
        dropdownMenu.css('left', left);
      }else{
        dropdownMenu.css('left', left - 10 + (diffWidth < 5 ?
          $(e.target).width() - width + 15 : -width + 25));
      }
    }

  });
  $(window).on('hide.bs.dropdown', function (e) {
    if ($(e.target).hasClass('body-container')) {
      $(e.target).append(dropdownMenu);
      dropdownMenu.hide();
    }
  });

  //affix - bootstrap plugin that allow our toolbar to stick to the top of the screen on scroll
  //sometimes (we can not reproduce that bug always) toolbar does not stick to the top of the screen on scroll
  //possible reason is that initializing of affix plugin happens earlier than our toolbar appears in DOM
  //this line enables affix plugin to toolbar, when it is already in DOM
  $('[data-spy="affix"]').affix({offset:{top:50}})

  $('.xlsImport').on('click', function (e) {
    $(location).attr('href', app.urls.import);
  });
  $('.xlsExport').on('click', function (e) {
    $(location).attr('href', app.urls.export);
  });
  $('#logout-btn').click(function () {
    postAndRedirect(app.urls.logout);
  });
  $('.system-menu-item.systemJobs > a').attr('href', app.urls.open(Constants.ID_TYPE_TASK));
  //end old general.js

	app = app || {};
	app.buildView = new BuildView();
	app.taskManager = new TaskManager();
	app.notificationManager = new NotificationManager();
	app.createModal = new CreateModal();
	app.editModal = new EditModal();
	app.confirmModal = new ConfirmModal();
	app.errorsModal = new ErrorsModal();
	app.tasksQueue = new TasksQueue();
	app.instanceViewer = new InstanceViewer();

	saveMenuState();
	initHelpPopovers()
	addHrefToDocs()
	addUserPilots()

	app.taskManager.loadUncompletedTasks();
	if (!app.isForm) websocketClient.connect();

	if (!app.builderMode) {
		utils.getRequest(app.urls.htmlByMenuId(app.menuId))
			.then(html => {
				$('#main-menu').html(html);
				app.menu = new Menu();
			}).then(()=>{
				if (app.raw && app.raw.menus) {
					var menu = app.raw.menus.filter((a)=>{return a.id==app.menuId})[0]
					var subMenuFound = menu.menuItems.filter((submenu)=>{return submenu.metaObject&&submenu.metaObject.id==app.typeId})[0]
					if (subMenuFound){
						while (subMenuFound.parent){
							subMenuFound = menu.menuItems.filter((p)=>{return p.id==subMenuFound.parent.id})[0]
						}
						$('#'+subMenuFound.id).addClass('active')
					}
				}
			})
			new ApplicationInfo().render();

	} else {
		app.taskManager.fetchServerStatus();
		new BuilderInfo().render();
	}

	app.searchBoxView = new SearchBoxView();
	screenResolutionWarning();
	checkLocalStorage()
	if (app.builderMode) {
		app.getAllDataPromise = new Promise((resolve, reject) => {
			utils.getRequest(app.urls.getAllTypesAndCategories).then((data) => {
				if (!app.types) {
					app.types = new Types(data.types);
				}
				app.allCategories = new Backbone.Collection(data.categories);
				resolve();
			});
		});
	}
	$('.navigation.logo').click(() => {
		app.instanceViewer.showNavigation({});
	})
	$('.main-sidebar > .sidebar > .sidebar-menu > .menu-sub').click((e) => {
		if (!($(e.target).closest('.sub-menus').length)) {
			$(e.currentTarget).toggleClass('opened');
		}
	});
	$('.main-sidebar .create-new[data-kind="MENU"]').click(() => {
		createNewMenu();
	});
	$('.main-sidebar .create-new[data-kind="THEME"]').click(() => {
		addTheme();
	});
	$('.main-sidebar .create-new[data-kind="PERMISSION"]').click(() => {
		createNewPermission();
	});
	$('.main-sidebar .remove-menu').click((e) => {
		e.preventDefault();
		let id = $(e.currentTarget).closest('.sub-menu').data('id');
		let kind = $(e.currentTarget).closest('.sub-menu').data('kind');
		let constant, defaultId;
		if (kind == "MENU") {
			constant = Constants.ID_TYPE_ENTITY_MENU;
		} else if (kind == "THEME") {
			constant = Constants.ID_TYPE_CSS_THEME;
		} else if (kind == "PERMISSION") {
			constant = Constants.ID_TYPE_PERMISSION;
		}
		utils.confirmDelete([id], app.urls.dependencies(constant), app.urls.delete(constant), {}, () => {
			if (app.models && app.models.get(id)) {
				utils.redirectTo(app.urls.open(constant, app.menuId));
			} else {
				utils.reload();
			}
		});
	});

}

function checkLivezillaChatExist () {
		if ($('#lz_overlay_wm').length != 0) {
			addCrossToLivezillaChat()
		} else {
			setTimeout(checkLivezillaChatExist, 250)
		}
}

function addCrossToLivezillaChat () {
	let cross = $("<span style='margin-left:40px;color:#3091F2' class='fa fa-close'></span>")
	cross.click(() => {
		$('#lz_overlay_wm').css('display', 'none')
	})
	$('#lz_overlay_wm').prepend(cross)
}

function contentWrapperFullPageSize() {
  var height = $(window).height() -
    ($('.main-header').outerHeight() ? $('.main-header').outerHeight() : 0);
  $('.content-wrapper').css('min-height', height);
}

function saveMenuState () {
  $('body').on('collapsed.pushMenu', function () {
		addCookie({
			name: 'openedMenu',
			value: false,
			expiresTime: farFutureDate().toGMTString(),
			path: '/'
		});
  })
  $('body').on('expanded.pushMenu', function () {
		addCookie({
			name: 'openedMenu',
			value: true,
			expiresTime: farFutureDate().toGMTString(),
			path: '/'
		});
  })
}

function screenResolutionWarning() {
	if (!app.builderMode) {
		return;
	}
	if (window.innerWidth >= 1366) {
		return;
	}
	if (!(localStorage &&
			localStorage.getItem('screenResolutionWarningWasShown') === 'true'))
	{
		app.notificationManager.addWarning(app.getResource('screen.resolution.warning'));
		localStorage.setItem('screenResolutionWarningWasShown', 'true');
	}
}

function changePasswordInit(){
	if (!app.builderMode && app.currentUser && app.currentUser.userAccount.isLocal) {
		$('.change-pass').on('click',()=>{

			showChangePassModal({
				title: translate('change.password'),
				input1text: translate('input.old.password'),
				input2text: translate('input.new.password'),
				confirmButtonText: translate('confirm'),
				cancelButtonText: translate('close'),
				placeholder1text: translate('old.password'),
				placeholder2text: translate('new.password'),
				onConfirm: (input1data, input2data) => {
						utils.postRequest({oldPassword:input1data,newPassword:input2data}, app.urls.changePassword)
					}
			})
		})
	}
	else{
		$('.change-pass').css({"position": "absolute","visibility": "hidden"})
	}
}

function languageSwitcherInit() {
	$('.languageselector').on('click', function (e) {
		e.preventDefault();
		var noLangUrl = (location.pathname + location.search).substring(1 + app.currentLanguage.length);
		window.location.href = '/' + $(this).attr('lang') + noLangUrl
	})
}

$('input.search').keyup(function(e) {
	let value = e.currentTarget.value.toLowerCase();
	let dropdown = $($(e.currentTarget).parent().parent().find('.dropdown-menu-list'));
	let data = dropdown.find('li');
	_.each(data, el => {
		el = $(el);
		if (el.text().toLowerCase().indexOf(value) > -1) {
			el.removeClass('hidden')
		} else {
			el.addClass('hidden');
		}
	});
	if (data.length == $('.dropdown-menu-list li.hidden').length) {
		dropdown.append(
			$(`<li class='no-found'> ${app.getResource('select2.i18n.noResults')}</li>`)
		)
	} else {
		dropdown.find('li.no-found').remove();
	}
});

function checkLocalStorage() {
	if (!app.builderMode && app.checkLocalStorage) {
		app.checkLocalStorage = false
		let props = Object.keys(localStorage)
		props.forEach((prop) => {
			LocalStorageKeysService.parseKeyAndCheck(prop)
		})
	}
}
